import React, { useRef } from "react";
import { Button, Row, Col } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useNavigate } from 'react-router-dom';
import SaleRepOrderForm from "./form/index";

const SaleRepOrderCreatePage = () => {
  const navigate = useNavigate();
  const form = useRef();

  const onSaveSaleRepOrder = async () => {
    const formCurrent = await form.current.getPurchaseOrder();
    console.log('value to save', formCurrent);
    // const data = {
    //   supplierId: formCurrent.supplier,
    //   status: formCurrent.status,
    //   deliveryStatus: formCurrent.deliveryStatus,
    //   totalAmount: formCurrent.totalAmount,
    //   note: formCurrent.note,
    //   detail: formCurrent.detail
    // };
    // const response = await axios.post('api/sale-rep-order', data);
    // if (response.data.result) {
    //   console.log('> on Save Purchase Order', response.data.result);
    // }
    // TODO: Implement API
  }

  const onBack = () => {
    navigate({
      pathname: `/purchase-order/customer`
    });
  }

  return (
    <>
      <PageHeader
        className='site-page-header'
        title='Create Sale Rep Order'
        extra={
          <>
            <Button
              block
              onClick={() => onSubmitSaveAndPrint()}
              style={{ background: "#118060", color: "white" }}
            >
              រក្សាទុក & បោះពុម្ព
            </Button>
            <Button
              type="primary"
              block
              onClick={() => onSaveSaleRepOrder()}
            >
              រក្សាទុក
            </Button>
            <Button
              onClick={() => {onBack()}}
            >
              Back
            </Button>
          </>
        }
      />
      <SaleRepOrderForm
        ref={form}
      />
    </>
  )
}

export default SaleRepOrderCreatePage;