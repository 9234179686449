import React, { useState, useEffect } from 'react';
import { Form, message, Input, Select } from 'antd';
import { layoutConstant } from 'constants/config/form-layout.constant';
import { Loading } from 'utils';
import { PROVINCE_URL, URL } from './constant';
import axios from 'axios';
import { CreateModal } from "components/modals/index";

const CreateEditModal = ({
  value = false,
  item = null,
  isEdit = false,
  onSuccess,
  onCancel
}) => {
  const [form] = Form.useForm();
  const [provinces, setProvinces] = useState([]);

  const fetchProvinces = async () => {
    const response = await axios.get(PROVINCE_URL);
    const result = (response.data && response.data.result) || []
    setProvinces(result);
  }

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        provinceId: formValue.provinceId,
        name: formValue.name,
        nameEn: formValue.nameEn,
      }
      if (item && item.id) {
        await put(item.id, data);
      } else {
        await post(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const post = async (data) => {
    const response = await axios.post(URL, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {     
      message.info('Failed');
    }
  }

  const put = async (id, data) => {
    const response = await axios.put(`${URL}/${id}`, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {     
      message.info('Failed');
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  useEffect(() => {
    async function fetch () {
      await fetchProvinces();
    };
    fetch();
  }, []);

  useEffect(() => {
    if (item) {
      console.log(item);
      form.setFieldsValue({
        provinceId: item.provinceId.id,
        name: item.name,
        nameEn: item.nameEn,
      })
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={isEdit ? 'Edit District' : 'Create District'}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form
          form={form}
          {...layoutConstant}
        >
          <Form.Item
            name="provinceId"
            label="Province"
            disabled
            rules={[
              {
                required: true,
                message: 'Please input Status',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Province'
              // onChange={onSelectedProduct}
            >
              {provinces.map((option, index)=> (
                <Option key={index} value={option.id}>{option.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label='District'
            rules={[
              {
                required: true,
                message: 'Please input province!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="nameEn"
            label='District En'
            rules={[
              {
                required: true,
                message: 'Please input province en!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </CreateModal>
    </>
  )
};

export default CreateEditModal;