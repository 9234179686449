import React, { useState } from 'react';
import { message, Button, Space } from 'antd';
import AppTable from 'components/common/table/AppTable';
import SupplierCreateEditModal from './supplierCreateEditModal';
import Confirm from 'components/modals/Confirm';
import { Loading } from 'utils';


const SupplierList = ({
  data,
  onLoadData
}) => {
  const [supplierEditModal, setSupplierEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const headers = [
    {
      title: 'Company',
      dataIndex: 'company'
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName'
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber'
    },
    {
      title: 'Address',
      dataIndex: 'address'
    },
    {
      title: 'Country',
      dataIndex: 'country'
    },
    {
      title: 'Website',
      dataIndex: 'website'
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      fixed:"right",
      render:(text, record) => (
        <Space>
          <Button
            onClick={() => onEdit(record)}
          >
            Edit
          </Button>
          <Button
            disabled
            onClick={() => onDelete(record)}
          >
            Delete
          </Button>
        </Space>
      )
    }
  ]

  // const data = [
  //   {
  //     company: 'ABC',
  //     contactName: 'Mr. A',
  //     phoneNumber: '011 000 111',
  //     address: 'Phnom Penh, Cambodia',
  //     country: 'Cambodia',
  //     website: 'www.abc.org'
  //   }
  // ]

  const onEdit = (item) => {
    setCurrentItem(item);
    setSupplierEditModal(true);
  }

  const onDelete = (item) => {
    Confirm({
      title: "Confirm Delete",
      okType: 'warning',
      async onOk () {
        try {
          Loading.setLoading(true);
          // TODO: Implement delete method
        } catch (error) {
          message.error(error.message);
        } finally {
          Loading.setLoading(false);
        }
      },
      onCancel () {}
    })
  }

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setSupplierEditModal(false);
  }

  const onCloseModal = () => {
    setCurrentItem(null);
    setSupplierEditModal(false);
  }

  return (
    <>
      <AppTable
        items={data}
        headers={headers}
        disableAction
      />
      <SupplierCreateEditModal
        value={supplierEditModal}
        item={currentItem}
        isEdit
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  )
}

export default SupplierList;