import React from 'react';
import { Table, Form, Card, Row, Col } from 'antd';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import SummaryBox from 'components/cards/SummaryBox';
// import ChartFacebookPageForm from './chart/facebookPage';

const SaleSummaryForm = ({ data, facebookPages }) => {

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product'
    },
    {
      title: 'Sold',
      dataIndex: 'sold',
      key: 'sold',
    },
  ];

  return (
    <Form
      layout="vertical"
      name="advanced_search"
      className="ant-advanced-search-form"
    >
      <Card
        title={ `Monthly Summary (${moment(data.fromDate).format('YYYY-MM-DD')} - ${moment(data.toDate).format('YYYY-MM-DD')})` }
      >
        <Row gutter={[12, 12]}>
          <Col xs={12} sm={6} md={6}>
            <SummaryBox title='Sale Amount' color="#008080">
              <b>
                <NumericFormat value={data.totalAmount} displayType={'text'} thousandSeparator={true} suffix='KHR' />
              </b>
            </SummaryBox>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <SummaryBox title='Sale Income' color="green">
              <b>
                <NumericFormat value={data.income} displayType={'text'} thousandSeparator={true} suffix='KHR' />  
              </b>
            </SummaryBox>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <SummaryBox title='Total Sale' color="#00008B">
              <b>
                <NumericFormat value={data.totalSale} displayType={'text'} thousandSeparator={true} suffix='Total' />
              </b>
            </SummaryBox>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <SummaryBox title='Print To Be' color="#ff4700">
              <b>
                <NumericFormat value={data.totalSaleToBeReleased} displayType={'text'} thousandSeparator={true} suffix='Total' />
              </b>
            </SummaryBox>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <SummaryBox title='Total Sale Unpaid' color="#b96823">
              <b>
                <NumericFormat value={data.totalSaleUnpaid} displayType={'text'} thousandSeparator={true} suffix='Total' />
              </b>
            </SummaryBox>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginTop: 8 }}>
          <Col xs={24} sm={12} md={12}>
            {/* { cardProducts } */}
            <Table pagination={false} dataSource={data.products} columns={columns} />
          </Col>
          {/* <Col xs={24} sm={12} md={12}>
            <ChartFacebookPageForm data={facebookPages} />
          </Col> */}
        </Row>
      </Card>
    </Form>
  );
};

export default SaleSummaryForm;