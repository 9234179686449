import React from 'react';
import { Button, Spin } from 'antd';
import './overlay.css';

const Loading = () => {
  return (
    <div className="overlay">
      <Spin
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
        size="large"
      />
    </div>
  )
}

export default Loading;