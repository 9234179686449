import { useState } from "react";
import { Badge, Descriptions, Typography, InputNumber } from "antd";
import { Riel } from "components/special";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils";
import { ClosingBalanceStatus } from "constants/status";

const { Text, Title } = Typography;

const PostInfo = ({
  status,
  saleRep = "",
  totalSales = 0,
  totalSaleAmount = 0,
  totalWholesaleAmount = 0,
  datePosted = "",
  otherPrice = 0,
  grandTotal = 0,
  getData,
}) => {
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState(0);

  const onChangeOtherPrice = (value) => {
    const tmpOtherPrice = Number(value);
    const tmpGrandTotal = Number(totalWholesaleAmount) + tmpOtherPrice;
    setTotal(tmpGrandTotal);
    getData(tmpOtherPrice, tmpGrandTotal);
  };

  const items = [
    { key: "1", label: t("label.saleRep"), children: saleRep },
    {
      key: "2",
      label: t("label.status"),
      children: (
        <Badge
          status={status === "Drafting" ? "processing" : "success"}
          text={
            status === "Drafting" ? t("label.drafting") : t("label.approved")
          }
        />
      ),
    },
    {
      key: "3",
      label: t("label.date"),
      children: (
        <Title level={5} type="danger">
          {formatDate(datePosted)}
        </Title>
      ),
    },
    {
      key: "4",
      label: t("label.invoiceTotals"),
      children: (
        <Title level={5} type="danger">
          {totalSales}
        </Title>
      ),
    },
    {
      key: "5",
      label: t("label.totalSaleAmount"),
      children: (
        <Text strong>
          <Riel value={totalSaleAmount} />
        </Text>
      ),
    },
    {
      key: "6",
      label: t("label.totalWholesaleAmount"),
      children: (
        <Text strong>
          <Title level={5}>
            <Riel value={totalWholesaleAmount} />
          </Title>
        </Text>
      ),
    },
    {
      key: "7",
      label: "ចំណាយផ្សេងៗ",
      span: 2,
      children:
        status !== ClosingBalanceStatus.APPROVED ? (
          <InputNumber
            placeholder="Please other Price"
            onChange={onChangeOtherPrice}
            style={{ width: "100%" }}
          />
        ) : (
          <Text strong>
            <Title level={5}>
              <Riel value={otherPrice} />
            </Title>
          </Text>
        ),
    },
    {
      key: "8",
      label: "សរុប",
      children:
        status !== ClosingBalanceStatus.APPROVED ? (
          <Text strong>
            <Title level={5}>
              <Riel value={total === 0 ? totalWholesaleAmount : total} />
            </Title>
          </Text>
        ) : (
          <Title level={5} type="danger">
            <Riel value={grandTotal} />
          </Title>
        ),
    },
  ];

  return (
    <div>
      <Descriptions
        title=""
        bordered
        column={{
          xs: 1,
          sm: 2,
          md: 2,
        }}
        items={items}
      />
    </div>
  );
};

export default PostInfo;
