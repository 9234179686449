import { Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const { confirm, error } = Modal;

const Confirm = ({
  title = 'Confirm',
  content = '',
  okText = 'Yes',
  cancelText = 'Cancel',
  okType = 'Danger',
  icon = <DeleteOutlined />,
  onOk,
  onCancel,
  type = 'confirm'
}) => {
  const confirmModal = () => {
    confirm({
      title: title,
      zIndex: 2,
      icon: icon,
      content: content,
      okText: okText,
      okType: okType,
      cancelText: cancelText,
      onOk() {
        onOk();
      },
      onCancel() {
        onCancel();
      },
    })
  }
  const errorModal = () => {
    error({
      title: title,
      zIndex: 2,
      icon: icon,
      content: content,
      okText: okText,
      okType: okType,
      cancelText: cancelText,
      onOk() {
        onOk();
      },
      onCancel() {
        onCancel();
      },
    })
  }
  const getModal = () => {
    switch (type) {
      case 'confirm':
        return confirmModal();
      case 'error':
        return errorModal();
      default:
        return confirmModal();
    }
  }

  return (
    getModal()
  )
}

export default Confirm;