import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Select,
  Form,
  InputNumber,
  message,
  Divider,
  Modal,
  Row,
  Col,
  Button,
} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { Loading } from "utils";
import { URL } from "../constant";
import { useTranslation } from "react-i18next";
import { DisplayModal } from "components/modals/index";
import ProductBundleList from "./list";

const { confirm } = Modal;

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const ProductBundleModal = ({
  value = false,
  item = null,
  onSuccess,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const [parentId, setParentId] = useState({});
  const [currentBundles, setCurrentBundles] = useState([]);
  const [products, setProducts] = useState([]);

  const onCreate = async () => {
    try {
      Loading.setLoading(true);
      onSuccess();
    } catch (error) {
      console.log("Failed:", error);
    } finally {
      Loading.setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onSuccess();
  };

  useEffect(() => {
    if (item) {
      getProductForBundle();
      setParentId(item.id);
      if (item.bundles.length > 0) {
        setCurrentBundles(item.bundles);
      }
    }
  }, [item]);

  const getProductForBundle = async () => {
    const response = await axios.get(`api/products/product-bundle/find`);
    if (response.data.code === 200) {
      setProducts(response.data.result);
    }
  }

  const findProduct = async () => {
    try {
      const response = await axios.get(`api/products/${parentId}`);
      if (response.data.code === 200) {
        const result = response.data.result;
        setCurrentBundles(result.bundles);
      }
      form.resetFields();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onAddProductBundle = async () => {
    try {
      const formValue = await form.validateFields();
      console.log(formValue);
      const data = {
        productId: formValue.product.key,
        name: formValue.product.label,
        items: formValue.items
      }
      const response = await axios.post(`api/products/product-bundle/${parentId}`, data);
      if (response.data.code === 200) {
        findProduct();
      }
      form.resetFields();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  return (
    <>
      <DisplayModal
        open={value}
        onOk={() => onCreate()}
        onCancel={() => onCancelModal()}
        title={t("menu.productVariant")}
        width={980}
      >
        <Divider />
        <Form
          layout='vertical'
          form={form}
          initialValues={{
            items: 1
          }}
          validateMessages={validateMessages}
        >
          <Row gutter={8}>
            <Col xs={10} sm={7} md={7}>
              <Form.Item label={t('label.product')} name="product" rules={[{ required: true }]}>
                <Select
                  showSearch
                  labelInValue={true}
                  fieldNames={{ label: 'name', value: 'id'}}
                  filterOption={(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  }
                  options={products}
                />
              </Form.Item>
            </Col>
            <Col xs={10} sm={4} md={4}>
              <Form.Item label={t('label.items')} name="items" rules={[{ required: true }]}>
                <InputNumber min={1} max={1000} defaultValue={1} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={4} sm={3} md={3}>
              <Form.Item label=" ">
                <Button
                  block
                  icon={<PlusOutlined/>}
                  onClick={() => onAddProductBundle()}
                  style={{
                    background: "#808080",
                    color: "white",
                  }}
                  shape="round"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <ProductBundleList parentId={parentId} values={currentBundles} />
      </DisplayModal>
    </>
  );
};

export default ProductBundleModal;
