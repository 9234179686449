import { configureStore } from '@reduxjs/toolkit';
import loadingReducer from './loading';
import roleReducer from "./role";

//MIDDLEWARE
const localStorageMiddleware = ({ getState }) => {
  return next => action => {
    const result = next(action);
    localStorage.setItem('applicationState', JSON.stringify(getState()));
    return result;
  };
};

// const localStorageMiddleware = (store) => (next) => (action) => {
//   const result = next(action);
//   if (action.type?.startsWith('role') ) {
//     const authState = store.getState();
//     localStorage.setItem('applicationState', JSON.stringify(authState))
//   }
//   return result;
// };

const reHydrateStore = () => {
  if (localStorage.getItem('applicationState') !== null) {
    const state = JSON.parse(localStorage.getItem('applicationState'));
    return state; // re-hydrate the store
  }
};

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    role: roleReducer,
  },
  preloadedState: reHydrateStore(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(localStorageMiddleware),
})
