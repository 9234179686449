import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Card, Form, Button, Row, Col, DatePicker, Select, Input, message, Checkbox, Space, Grid, Table, Tag, Statistic, Divider } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { FilterOutlined, EyeOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { NumericFormat } from "react-number-format";
import moment from "moment";
import ViewAndUpdateProduct from "./modal/ViewAndUpdateProduct";
import PopupFilter from 'components/common/FilterPopup';
import ConfirmPostClosingBalance from './modal/ConfirmPostClosingBalance';
import { Loading } from 'utils';
import { API_URL, TablePagination } from './constant';
import { ADMIN_ROLE } from "constants/role.constant";
import { MoveSaleStatus } from '../components';
import { Riel } from 'components/special';
import './index.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const { useBreakpoint } = Grid;

const IndexForm = () => {

  const navigate = useNavigate();
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation();
  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);
	const [searchForm] = Form.useForm();

  const [confirmModel, setConfirmModel] = useState(false);
  const [confirmData, setConfirmData] = useState(null);
  const [sales, setSales] = useState([]);
  const [saleReps, setSaleReps] = useState([]);
  const [filterPopup, setFilterPopup] = useState(false);
  const [options, setOptions] = useState({
    grandTotal: 0,
    grandTotalWholesale: 0,
    totalAmount: 0,
    totalWholesale: 0,
  });

  useEffect(() => {
    async function fetchData () {
      Loading.setLoading(true);
      await fetchSaleRep();
      Loading.setLoading(false);
    }
    fetchData();
  }, []);

  const getSales = async () => {
    try {
      Loading.setLoading(true);
      const searchParam = {
        ...getSearchParam()
      };
      setSelectedRowKeys([]);
      const response = await axios.post(API_URL, searchParam);
      if (response && response.data) {
        setSales(response.data && response.data.result);
        setOptions({
          grandTotal: response.data.options.grandTotal,
          grandTotalWholesale: response.data.options.grandTotalWholesale,
          totalAmount: response.data.options.totalAmount,
          totalWholesale: response.data.options.totalWholesale,
        })
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      Loading.setLoading(false);
    }
  };

  const onReset = async () => {
    try {
      Loading.setLoading(true);
      searchForm.resetFields();
      setSelectedRowKeys([]);
      await getSales();
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const getSearchParam = () => {
    const data = searchForm.getFieldsValue(['saleRepId', 'phoneNumber', 'code', 'startDate', 'endDate', 'isPrint', 'fromCode', 'toCode']);
    return {
      saleRepId: data.saleRepId ?? '',
      phoneNumber: data.phoneNumber ?? '',
      code: data.code ?? '',
      startDate: data.startDate ?? '',
      endDate: data.endDate ?? '',
      fromCode: data.fromCode ?? '',
      toCode: data.toCode ?? '',
      isPrint: data.isPrint,
      page: 0,
    }
  }

  const fetchSaleRep = async () => {
    const response = await axios.get(`api/sale-reps`);
    if (response.data.code === 200) {
      setSaleReps(response.data.result);
    }
  };

  const onResetFilter = async () => {
    await getSales();
    setFilterPopup(false)
  }

  const onFilter = async () => {
    await getSales();
    setFilterPopup(false)
  }

  const onConfirm = async () => {
    if (sales.length > 0) {
      console.log('On Confirm select', selectedRowKeys);
      // const filterSales = sales.filter(item => {
      //   return selectedRowKeys.indexOf(item.id) !== -1;
      // });
      const filterSales = sales.filter(item => selectedRowKeys.includes(item.id));
      if (filterSales.length > 0) {
        const grandTotal = filterSales.reduce((acc, val) => acc + val.totalAmount, 0);
        const grandTotalWholesale = filterSales.reduce((acc, val) => acc + val.totalWholesale, 0);
        const obj = {
          sales: filterSales,
          saleRepName: filterSales[0].saleRepId.name,
          total: filterSales.length,
          grandTotal,
          grandTotalWholesale,
        }
        setConfirmData(obj);
        setConfirmModel(true);
      } else {
        message.error('Error');
      }
    }
  }

  const onCancelConfirm = () => {
    setConfirmModel(false);
    setConfirmData(null);
  }

  const onUpdateSuccess = async () => {
    navigate({
      pathname: `/move-sale`
    });
  }

  const [openViewAndUpdateProduct, setOpenViewAndUpdateProduct] =
    useState(false);
  const [currentItem, setCurrentItem] = useState(null);


  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };
  const hasSelected = selectedRowKeys.length > 0;

  // Product
  const onViewAndUpdate = (item) => {
    setCurrentItem(item);
    setOpenViewAndUpdateProduct(true);
  };

  const onCancelViewAndUpdateProduct = () => {
    setOpenViewAndUpdateProduct(false);
    setCurrentItem(null);
  };

  const columns = [
    {
      title: "№",
      dataIndex: "code",
    },
    {
      title: "កាលបរិច្ឆេទ",
      dataIndex: "date",
      responsive: ["xs", "sm"],
      render: (text, record) => (
        <div>{moment(record.date).format("YY-MM-DD")}</div>
      ),
    },
    ADMIN_ROLE.code == roleValue
      ? {
          title: "តំណាងចែងចាយ",
          dataIndex: "saleRepId",
          key: "saleRepId",
          render: (text, { saleRepId }) => (
            <div>{saleRepId && saleRepId.name}</div>
          ),
          responsive: ["sm"],
        }
      : null,
    {
      title: "លេខលំដាប់",
      dataIndex: "invoiceSequence",
    },
    {
      title: t("label.closingBalanceStatus"),
      dataIndex: "moveSaleStatus",
      responsive: ["sm"],
      render: (record) => (
        <>
          <MoveSaleStatus status={record} />
        </>
      ),
    },
    {
      title: t("label.printStatus"),
      dataIndex: "product",
      responsive: ["sm"],
      render: (sale, record) => (
        <div>
          {record.printCount > 0 ? (
            <Tag>{t("label.isPrinted")}</Tag>
          ) : (
            <Tag color="#f5222d">{t("label.isNotPrinted")}</Tag>
          )}
        </div>
      ),
    },
    {
      title: t("label.address"),
      dataIndex: "address",
      responsive: ["sm"],
    },
    {
      title: "តម្លៃក្រុមហ៊ុន",
      dataIndex: "totalWholesale",
      align: "right",
      responsive: ["xs", "sm"],
      render: (sale, record) => (
        <b>
          <Riel value={record.totalWholesale} />
        </b>
      ),
    },
    {
      title: "តម្លៃសរុបរួម",
      dataIndex: "totalAmount",
      responsive: ["xs", "sm"],
      align: "right",
      render: (sale, record) => (
        <b>
          <Riel value={record.totalAmount} />
        </b>
      ),
    },
    {
      title: t("action.operation"),
      key: "id",
      responsive: ["sm"],
      render: (sale, record) => (
        <Space size="small">
          <Button
            onClick={() => onViewAndUpdate(record)}
            icon={<EyeOutlined />}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        className='site-page-header'
        title={t('menu.saleList')}
        extra={
          <Space className="site-button-ghost-wrapper">
            <Button
              type="primary"
              onClick={() => onConfirm()}
              disabled={!hasSelected}
            >
              { t('action.postClosingBalance') }
            </Button>
          </Space>
        }
      />

        <Card size="small">
          <Form
            {...formItemLayout}
            style={{ marginBottom: 0 }}
            form={searchForm}
          >
            <Row gutter={[8, 8]}>
              {
                roleAdmin == roleValue &&
                <Col xs={12} sm={3} md={3}>
                  <Form.Item
                    name="saleRepId"
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      allowClear
                      placeholder="តំណាងចែងចាយ"
                    >
                      {saleReps.map((item, index) => (
                        <Option value={item.id} key={index}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              }
              <Col xs={12} sm={3} md={3}>
                <Form.Item
                  name="code"
                  style={{ marginBottom: 0 }}
                >
                  <Input placeholder={t('invoiceSequence')} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item  style={{ marginBottom: 0 }} label="">
                  <PopupFilter
                    visible={filterPopup}
                    placement="bottom"
                    resetText="ឡើងវិញ"
                    searchText={t('action.search')}
                    onClose={() => setFilterPopup(false)}
                    onReset={() => onResetFilter()}
                    onFilter={() => onFilter()}
                    button={
                      <Button
                        icon={<FilterOutlined />}
                        onClick={() => setFilterPopup(true)}
                      />
                    }
                    form={
                      <Form
                        style={{width: '250px'}}
                        form={searchForm}
                      >
                        <Row gutter={[8, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name="phoneNumber"
                              style={{ marginBottom: 0 }}
                            >
                              <Input placeholder={t('phoneNumber')} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name="startDate"
                              style={{ marginBottom: 0 }}
                            >
                              <DatePicker placeholder={t('label.fromDate')} style={{ display: 'block' }} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name="endDate"
                              style={{ marginBottom: 0 }}
                            >
                              <DatePicker placeholder={t('label.toDate')} style={{ display: 'block' }} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="fromCode"
                              style={{ marginBottom: 0 }}
                            >
                              <Input placeholder='From Code' />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="toCode"
                              style={{ marginBottom: 0 }}
                            >
                              <Input placeholder='To Code' />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item style={{ marginBottom: 0 }} valuePropName="checked" name="isPrint">
                              <Checkbox>{t('label.isNotPrinted')}</Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    }
                  >
                  </PopupFilter>
                  <Button
                    style={{ marginLeft: '4px' }}
                    type="primary"
                    onClick={() => getSales()}
                  >
                    { t('action.search') }
                  </Button>
                  <Button
                    style={{ marginLeft: '4px' }}
                    onClick={() => onReset()}
                  >
                    ឡើងវិញ
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Divider />

          <Table
            rowKey="id"
            size="small"
            columns={columns.filter((x) => x !== null)}
            dataSource={sales}
            rowSelection={rowSelection}
            summary={() => {
              const currentScreens = Object.entries(screens).filter(
                (screen) => !!screen[1]
              ).length;
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      colSpan={
                        ADMIN_ROLE.code === roleValue
                          ? currentScreens > 1
                            ? 8
                            : 2
                          : currentScreens > 1
                          ? 7
                          : 2
                      }
                      align="right"
                    >
                      សរុប
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <b>
                        <NumericFormat
                          value={options.totalWholesale}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" ៛"}
                        />
                      </b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <b>
                        <NumericFormat
                          value={options.totalAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" ៛"}
                        />
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      colSpan={
                        ADMIN_ROLE.code === roleValue
                          ? currentScreens > 1
                            ? 8
                            : 2
                          : currentScreens > 1
                          ? 7
                          : 2
                      }
                      align="right"
                    >
                      តំលៃ​បូក​សរុប
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <b>
                        <NumericFormat
                          value={options.grandTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" ៛"}
                        />
                      </b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <b>
                        <NumericFormat
                          value={options.grandTotalWholesale}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" ៛"}
                        />
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </Card>

      <ViewAndUpdateProduct
        item={currentItem}
        open={openViewAndUpdateProduct}
        onCancel={() => onCancelViewAndUpdateProduct()}
        onSuccess={() => onUpdateSuccess()}
      />

      <ConfirmPostClosingBalance
        item={confirmData}
        open={confirmModel}
        onCancel={onCancelConfirm}
        onSuccess={() => onUpdateSuccess()}
      />
    </>
  );
}

export default IndexForm;