import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { message, Divider, Row, Statistic, Col, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import CountUp from 'react-countup';
import CustomModal from 'components/modals/DisplayModal';
import moment from "moment";
import { Loading } from 'utils';

const formatter = (value) => <CountUp end={value} separator="," />;

const confirmPostClosingBalance = ({
  item = null,
  open = false,
  onCancel,
  onSuccess
}) => {

  const { t, i18n } = useTranslation();
  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);

  const [info, setInfo] = useState({
    saleRepName: '',
    sales: [],
    total: 0,
    totalAmount: 0,
    totalWholesaleAmount: 0,
  });

  useEffect(() => {
    if (item) {
      setInfo(item)
    }
  }, [item]);

  const renderClosingBalanceStatus = (value) => {
    switch(value) {
      case 'Pending':
        return <Tag color="#f5222d">{t("label.pending")}</Tag>;
      case 'OnHold':
        return t('label.onHold');
      case 'Processing':
        return t('label.processing');
      case 'Completed':
        return t('label.completed');
      default:
        return '';
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "code",
    },
    {
      title: "កាលបរិច្ឆេទ",
      dataIndex: "date",
      responsive: ["xs", "sm"],
      render: (text, record) => (
        <div>{moment(record.date).format("YY-MM-DD")}</div>
      ),
    },
    {
      title: "តំណាងចែងចាយ",
      dataIndex: "saleRepId",
      key: "saleRepId",
      render: (text, { saleRepId }) => (
        <div>{saleRepId && saleRepId.name}</div>
      ),
      responsive: ["sm"],
    },
    {
      title: "លេខលំដាប់",
      dataIndex: "invoiceSequence",
    },
    {
      title: t("label.closingBalanceStatus"),
      dataIndex: "moveSaleStatus",
      responsive: ["sm"],
      render: (record) => (
        <>
          { renderClosingBalanceStatus(record) }
        </>
      ),
    },
    {
      title: "តម្លៃក្រុមហ៊ុន",
      dataIndex: "totalWholesale",
      align: "right",
      responsive: ["xs", "sm"],
      render: (sale, record) => (
        <b>
          <NumericFormat
            value={record.totalWholesale}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" ៛"}
          />
        </b>
      ),
    },
    {
      title: "តម្លៃសរុបរួម",
      dataIndex: "totalAmount",
      responsive: ["xs", "sm"],
      align: "right",
      render: (sale, record) => (
        <b>
          <NumericFormat
            value={record.totalAmount}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" ៛"}
          />
        </b>
      ),
    },
  ];

  const canEdit = useMemo(() => {
    return roleAdmin == roleValue ? true : false
  }, []);

  const onConfirmMoveLine = async () => {
    try {
      Loading.setLoading(true);
      const saleIds = item.sales.map((ele) => ele.id);
      const obj = {
        description: item.saleRepName,
        ids: saleIds,
      };
      const response = await axios.post(`api/sales/move-sale`, obj);
      if (response.data.code === 200) {
        message.success(`${response.data.message.message}`);
        onSuccess();
        onCancel();
      }
      else {
        message.info('Failed to update');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  return (
    <>
      <CustomModal
        width={1200}
        visible={open}
        title={t('label.confirmPostClosingBalance')}
        saveButtonText='Confirm'
        onCancel={() => onCancel()}
        onOk={() => onConfirmMoveLine()}
        disableOk={!canEdit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Statistic title={t('label.saleRep')} value={info.saleRepName === '' ? t('action.all') : info.saleRepName} />
          </Col>
          <Col span={12}>
            <Statistic title={t('label.totalSale')} value={info.total} precision={2} formatter={formatter} />
          </Col>
          <Col span={12}>
            <Statistic title={t('label.totalWholesaleAmount')} value={info.grandTotalWholesale} precision={2} formatter={formatter} />
          </Col>
          <Col span={12}>
            <Statistic title={t('label.totalSaleAmount')} value={info.grandTotal} formatter={formatter} />
          </Col>
        </Row>
        <Divider />
        <Table
          rowKey="id"
          size="small"
          columns={columns.filter((x) => x !== null)}
          dataSource={info.sales}
        />
      </CustomModal>
    </>
  )
}

export default confirmPostClosingBalance;