import React, { useEffect } from 'react';
import axios from 'axios';
import { Form, message, Input } from 'antd';
import { layoutConstant } from 'constants/config/form-layout.constant';
import { Loading } from 'utils';
import { SUPPLIER_URL } from './supplier.constant';
import { CreateModal } from "components/modals/index";

const SupplierCreateEditModal = ({
  value = false,
  item = null,
  isEdit = false,
  onSuccess,
  onCancel
}) => {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        company: formValue.company,
        contactName: formValue.contactName,
        phoneNumber: formValue.phoneNumber,
        address: formValue.address, 
        country: formValue.country,
        website: formValue.website
      }
      if (item && item.id) {
        await put(item.id, data);
      } else {
        await post(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const post = async (data) => {
    console.log('data', data)
    const response = await axios.post(SUPPLIER_URL, data)
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {     
      message.info('Failed');
    }
  }

  const put = async (id, data) => {
    const response = await axios.put(`${SUPPLIER_URL}/${id}`, data)
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {     
      message.info('Failed');
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        company: item.company,
        contactName: item.contactName,
        phoneNumber: item.phoneNumber,
        address: item.address,
        country: item.country,
        website: item.website
      })
    }
  }, [item])

  return (
    <>
      <CreateModal
        title={isEdit ? 'Edit Supplier' : 'Create Supplier'}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form
          form={form}
          {...layoutConstant}
        >
          <Form.Item
            name="company"
            label='Company Name'
            rules={[
              {
                required: true,
                message: 'Please input Company Name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactName"
            label='Contact Name'
            rules={[
              {
                required: true,
                message: 'Please input Contact Name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label='Phone Number'
            rules={[
              {
                required: true,
                message: 'Please input Phone Number!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="address"
            label='Address'
            rules={[
              {
                required: true,
                message: 'Please input Address!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="country"
            label='Country'
            rules={[
              {
                required: true,
                message: 'Please input Country!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="website"
            label='Website'
            rules={[
              {
                required: true,
                message: 'Please input Website!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </CreateModal>
    </>
  )
};

export default SupplierCreateEditModal;