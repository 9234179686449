import React, { useState } from 'react';
import { message, Form, Card, Row, Col, Button, DatePicker, Tabs, Badge, Avatar } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
// import NumberFormat from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import SummaryBox from 'components/cards/SummaryBox';
import SaleListForm from 'components/sales/list/Index';
import ExpenseTable from 'pages/expenses/Table';
import RevenueTable from 'pages/revenues/Table';
// import ChartSale from './chartSale';
import { formatDate } from '../../../utils/index';
import { Loading } from 'utils';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const MonthlyForm = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [saleInfo, setSaleInfo] = useState('');
  const [chartSale, setChartSale] = useState([]);

  const onFinish = async (values) => {
    try {
      Loading.setLoading(true);
      await form.validateFields();
      const data = {
        fromDate: (typeof values.date[0] === 'undefined' || values.date[0] === null) ? '' : values.date[0].format('YYYY-MM-DD'),     
        toDate: (typeof values.date[1] === 'undefined' || values.date[1] === null) ? '' : values.date[1].format('YYYY-MM-DD')
      }
      const response = await axios.post(`api/closing-balance/net-income`, data)
      if (response.data.code === 200) {
        const result = response.data.result;
        const displayDate = `(${formatDate(data.fromDate)} - ${formatDate(data.toDate)})`;
        setSaleInfo(`${displayDate} (Sale Number from ${result.saleNumberFrom} to ${result.saleNumberTo})`);
        const badges = {
          ...result,
          badgeRevenue: result.revenues.length,
          badgeExpense: result.expenses.length,
          badgeSale: result.sales.length,
          badgeSaleReview: result.saleReviews.length,
          badgeSaleUnpaid: result.saleUnpaids.length
        }
        setData(badges);
        const prepareChartSale = [
          {
            type: 'Total Sale Amount',
            sales: result.totalSaleAmount,
          },
          {
            type: 'Total Sale Revenue',
            sales: result.totalSaleRevenue,
          },
          {
            type: 'Total Sale Expense',
            sales: result.totalSaleExpense,
          },
          {
            type: 'Total Sale Unpaid',
            sales: result.totalSaleUnpaid,
          },
        ];
        setChartSale(prepareChartSale);
      } else {
        setData([]);
        message.info('This sale not yet have in System.');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const onClear = () => {
    form.resetFields();
    setData([]);
    setChartSale([]);
    setSaleInfo('');
  };

  const onSaveMonthlySummary = async (fromDate, toDate) => {
    try {
      Loading.setLoading(true);
      const data = {
        fromDate: fromDate,     
        toDate:  toDate
      }
      const response = await axios.post(`api/closing-balance`, data)
      if (response.data.code === 200) {
        setData(response.data.result);
        message.success('Save Success.');
      } else {
        setData([]);
        message.info('This sale not yet have in System.');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const onLoadData = async () => {  
    setData([]);
  }

  const BadgeOnTab = ({ title, count, overflowCount = 100 }) => {
    return (
      <span>
        <Badge count={count} overflowCount={overflowCount} offset={[12, 0]}>
          {title}
        </Badge>
      </span>
    );
  }

  return (
    <>
      <Form
        style={{
          marginTop: '5px',
          marginBottom: '25px'
        }}
        form={form}
        layout="inline"
        name="advanced_search"
        onFinish={onFinish}
      >
        <Form.Item name="date" rules={[{ required: true }]}>
          <RangePicker />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            shape="round"
          >
            Calculate
          </Button>
          <Button
            style={{
              margin: '0 8px',
            }}
            shape="round"
            onClick={onClear}
          >
            Clear
          </Button>
        </Form.Item>
      </Form>
      <PageHeader
        className="site-page-header"
        title={ `Summary ${saleInfo}` }
        extra={
          <Button
            type="primary"
            onClick={() => onSaveMonthlySummary(data.fromDate, data.toDate)}   
            shape="round"
          >
            Save Summary
          </Button>
        }
      />
      <Row gutter={24}>
        <Col span={6}>
          <SummaryBox title="Total Revenue" color="#056c28">
            <b>
              <NumericFormat value={data.totalRevenue} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />
            </b>
          </SummaryBox>
        </Col>
        <Col span={6}>
          <SummaryBox title="Total Expense" color="red">
            <b>
              <NumericFormat value={data.totalExpense} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />
            </b>
          </SummaryBox>
        </Col>  
        <Col span={6}>
          <SummaryBox title="Income" color="#389e0d">
            <b>
              <NumericFormat value={data.income} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />
            </b>
          </SummaryBox>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs>
            {/* <TabPane tab='Chart' key="1">
              <Card>
                <Row gutter={24}>
                  <Col span={12}>
                    <ChartSale data={chartSale} />
                  </Col>
                </Row>
              </Card>
            </TabPane> */}
            <TabPane tab={<BadgeOnTab title="Revenues" count={data.badgeRevenue} />}  key="2">      
              <RevenueTable data={data.revenues} onLoadData={onLoadData} />
            </TabPane>
            <TabPane tab={<BadgeOnTab title="Expenses" count={data.badgeExpense} />} key="3">      
              <ExpenseTable data={data.expenses} onLoadData={onLoadData} />
            </TabPane>
            <TabPane tab={<BadgeOnTab title="Sale List" count={data.badgeSale} />} key="4">
              <SaleListForm
                data={data.sales}
                onLoadData={onLoadData}
              />
            </TabPane>
            <TabPane tab={<BadgeOnTab title="Sale Review" count={data.badgeSaleReview} />} key="5">
              <SaleListForm
                data={data.saleReviews}
                onLoadData={onLoadData}
              />
            </TabPane>
            <TabPane tab={<BadgeOnTab title="Sale Unpaid" count={data.badgeSaleUnpaid} />} key="6">
              {data.badgeSaleUnpaid}
              <SaleListForm
                data={data.saleUnpaids}
                onLoadData={onLoadData}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default MonthlyForm;