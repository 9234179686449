import React from 'react';
import axios from 'axios';
import { message, Form, Input, Select } from 'antd';
import { ExpenseType } from 'constants/expense-type.constant';
import AppModal from 'components/common/modal/AppModal';
import { Loading } from 'utils';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
}

const CreateModal = ({ visible, onCancel, onSuccess }) => {
  const [form] = Form.useForm();

  const submit = async () => {
    try {
      Loading.setLoading(true);
      const expenseType = {
        name: form.getFieldValue('name'),
        nameEn: form.getFieldValue('nameEn'),
        price: form.getFieldValue('price'),
        type: form.getFieldValue('type')
      }
      const response = await axios.post(`api/expense-type`, expenseType)
      if (response.data.code === 200) {
        onSuccess();
        form.resetFields();
        message.success('This expenseType added in System.');
      } else {
        message.info('This expenseType not yet have in System.');
      }
    } catch (error) {
      message.info(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }
  return (
    <>
      <AppModal
        title='Expense Type'
        visible={visible}
        onOk={submit}
        onCancel={onCancel}
      >
        <Form
          form={form}
          {...formItemLayout}
          initialValues={{
            type: 'Sale'
          }}
        >
          <Form.Item
            name="type"
            label='Type'
          >
            <Select>
              {ExpenseType.map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label='Name'
            rules={[{ required: true }]}
          >
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item
            name="nameEn"
            label='Name En'
            rules={[{ required: true }]}
          >
            <Input placeholder='Name En'/>
          </Form.Item>
          <Form.Item
            name="price"
            label='Price KHR'
            rules={[
              {
                required: true,
                message: 'Please input other phone!',
              },
            ]}
          >
            <Input
              placeholder='Price KHR'
            />
          </Form.Item>
        </Form>
      </AppModal>
    </>
  )
}

export default CreateModal;