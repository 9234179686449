import React, { useEffect, useState } from 'react';
import { Form, message, Input, InputNumber, Select, Checkbox } from 'antd';
import { layoutConstant } from 'constants/config/form-layout.constant';
import { Loading } from 'utils';
import { CATEGORY_URL, URL } from '../constant';
import axios from 'axios';
import { CreateModal } from "components/modals/index";

const CreateEditModal = ({
  value = false,
  item = null,
  isEdit = false,
  onSuccess,
  onCancel
}) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [productTypes, ] = useState([
    'Standard Product',
    'Product Bundle',
    // 'Configurable Product',
  ])

  useEffect(() => {
    async function fetch () {
      await fetchCategories();
    };
    fetch();
  }, []);

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        categoryId: item.categoryId.id,
        name: item.name,
        code: item.code,
        type: item.type,
        wholesalePrice: item.wholesalePrice,
        retailPrice: item.retailPrice,
        hide: item.isHide
      })
    }
  }, [item]);

  const fetchCategories = async () => {
    const response = await axios.get(CATEGORY_URL);
    const result = (response.data && response.data.result) || []
    setCategories(result);
  }

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        name: formValue.name,
        code: formValue.code,
        categoryId: formValue.categoryId,
        type: formValue.type,
        wholesalePrice: formValue.wholesalePrice,
        retailPrice: formValue.retailPrice,
        isHide: formValue.hide,
      }
      if (item && item.id) {
        await put(item.id, data);
      } else {
        await post(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const post = async (data) => {
    const response = await axios.post(URL, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {
      message.info('Failed');
    }
  }

  const put = async (id, data) => {
    const response = await axios.put(`${URL}/${id}`, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {
      message.info('Failed');
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  return (
    <>
      <CreateModal
        title={isEdit ? 'Edit Product' : 'Create Product'}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form
          form={form}
          {...layoutConstant}
        >
          <Form.Item
            name="categoryId"
            label="Category"
            disabled
            rules={[
              {
                required: true,
                message: 'Please input district',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Category'
            >
              {categories.map((option, index)=> (
                <Option key={index} value={option.id}>{option.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="type"
            label="Product Type"
            disabled
            rules={[
              {
                required: true,
                message: 'Please input district',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Type'
            >
              {productTypes.map((option, index)=> (
                <Option key={index} value={option}>{option}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="code"
            label='Code'
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label='Name'
            rules={[
              {
                required: true,
                message: 'Please input name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="wholesalePrice"
            label='Wholesale Price'
            rules={[
              {
                required: true,
                message: 'Please input wholesale price!',
              },
            ]}
          >
            <InputNumber
              placeholder='0'
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="retailPrice"
            label='Retail Price'
            rules={[
              {
                required: true,
                message: 'Please input retail price!',
              },
            ]}
          >
            <InputNumber
              placeholder='0'
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="hide"
            valuePropName="checked"
            label='Hide in Sell'
          >
            <Checkbox></Checkbox>
          </Form.Item>
        </Form>
      </CreateModal>
    </>
  )
};

export default CreateEditModal;