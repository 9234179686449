import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Form, message, Input, Select, Checkbox } from "antd";
import { useTranslation } from 'react-i18next';
import { layoutConstant } from "constants/config/form-layout.constant";
import { Loading } from "utils";
import { URL, TITLE } from "./constant";
import { CreateModal } from "components/modals/index";

const CreateEditModal = ({
  value = false,
  item = null,
  isEdit = false,
  onSuccess,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);
  const [form] = Form.useForm();
  const [deliveries, setDeliveries] = useState([]);

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        name: formValue.name,
        code: formValue.code,
        phoneNumber: formValue.phoneNumber,
        otherNumber: formValue.otherNumber,
        address: formValue.address,
        facebookLink: formValue.facebookLink,
        deliveryId: formValue.deliveryId && formValue.deliveryId.key,
        isShared: formValue.isShared,
      };
      if (item && item.id) {
        await put(item.id, data);
      } else {
        await post(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const post = async (data) => {
    const response = await axios.post(URL, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess(response.data);
      message.success("This data added in System.");
    } else {
      message.info("Failed");
    }
  };

  const put = async (id, data) => {
    const response = await axios.put(`${URL}/${id}`, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess(response.data);
      message.success("This data added in System.");
    } else {
      message.info("Failed");
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (!isEdit) {
      fetchDelivery();
    }
    if (item) {
      fetchDelivery();
      form.setFieldsValue({
        name: item.name,
        code: item.code,
        phoneNumber: item.phoneNumber,
        otherNumber: item.otherNumber,
        address: item.address,
        facebookLink: item.facebookLink,
        deliveryId: item.deliveryId != null ? item.deliveryId.id : null,
        isShared: item.isShared,
      });
    }
  }, [item]);

  const fetchDelivery = async () => {
    const response = await axios.get(`api/deliveries`);
    if (response.data.code === 200) {
      setDeliveries(response.data.result);
    }
  };

  return (
    <>
      <CreateModal
        title={isEdit ? `កែប្រែ ${TITLE}` : `បង្កើត ${TITLE}`}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form form={form} {...layoutConstant}>
          <Form.Item name="code" label={ t('label.code') }>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label={ t('label.name') }
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label={ t('label.phoneNumber') }
            rules={[
              {
                required: true,
                message: "Please input Phone Number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="otherNumber" label={ t('label.otherNumber') }>
            <Input />
          </Form.Item>
          <Form.Item name="address" label={ t('label.address') }>
            <Input />
          </Form.Item>
          <Form.Item name="facebookLink" label="Facebook Link">
            <Input />
          </Form.Item>
          <Form.Item name="deliveryId" label={ t('label.delivery') }>
            <Select
              showSearch
              labelInValue={true}
              allowClear
              fieldNames={{ label: "name", value: "id" }}
              filterOption={(input, option) => {
                return (option?.name ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              options={deliveries}
            />
          </Form.Item>
          {roleAdmin == roleValue && (
            <Form.Item
              name="isShared"
              valuePropName="checked"
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Checkbox>ប្រើរួមគ្នា</Checkbox>
            </Form.Item>
          )}
        </Form>
      </CreateModal>
    </>
  );
};

export default CreateEditModal;
