import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { message, Form, Input, DatePicker, Select, Tag } from 'antd';
import { expensePeriod, statuses } from './expense.constant'
import { Loading } from 'utils';
import { CreateModal } from "components/modals/index";

const CreateEditExpenseModal = ({
  value = false,
  item = null,
  isEdit = false,
  onLoadData,
  onCancel
}) => {

  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  // const [statuses] = useState([
  //   'Pending', 'Approved', 'Declined'
  // ]);

  useEffect(() => {
    async function fetch () {
      Loading.setLoading(true);
      await onLoadUsers();
      await onLoadExpense();
      Loading.setLoading(false);
    }
    fetch()
  }, []);

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        expenseType: item.expenseTypeId,
        date: moment(item.date).local(),
        status: item.status,
        // approval: item.approval && item.approval.map((apv) => apv.user && apv.user.id),
        expensePeriod: item.expensePeriod,
        amount: item.amount,
        note: item.note
      })
    }
  }, [item])

  const onLoadUsers = async () => {
    const response = await axios.get(`api/users`)
    if (response.data.code === 200) {
      setUsers(response.data.result);
    } else {
      message.info('This sale not yet have in System.');
    }
  };

  const onLoadExpense = async () => {
    const response = await axios.get(`api/expense-type?type=Expense`)
    if (response.data.code === 200) {
      setExpenseTypes(response.data.result);
    } else {
      message.info('This sale not yet have in System.');
    }
  }

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const onFinish = async () => {
    try {
      Loading.setLoading(true);
      const values = await form.validateFields();
      // const values = form.getFieldsValue(['expenseType', 'date', 'status', 'approval', 'expensePeriod', 'amount', 'note']);
      const findExpense = expenseTypes.find(tmp => tmp.id === values.expenseType)
      const data = {
        date: moment(values.date).local(),
        expenseTypeId: findExpense.id,
        expenseTypeName: findExpense.name,
        status: values.status,
        // approval: values.approval.map((item) => {
        //   let container = {};
        //   container['user'] = item;
        //   return container;
        // }),
        expensePeriod: values.expensePeriod,
        amount: values.amount,
        note: values.note,
      }
      if (item && item.id) {
        await put(item.id, data);
      } else {
        await post(data);
      }
      onCancel();
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const post = async (data) => {
    const response = await axios.post(`api/expense`, data)
    if (response.data.code === 200) {
      form.resetFields();
      onLoadData();
      message.success('This data added in System.');
    }
    else {     
      message.info('This data not yet have in System.');
    }
  }

  const put = async (id, data) => {
    const response = await axios.put(`api/expense/${id}`, data)
    if (response.data.code === 200) {
      form.resetFields();
      onLoadData();
      message.success('This data added in System.');
    }
    else {     
      message.info('This data not yet have in System.');
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  return (
    <>
      <CreateModal
        title="Expense"
        value={value}
        onOk={() => onFinish()}
        onCancel={() => onCancelModal()}
      >
        <Form
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          initialValues={{
            date: moment(),
          }}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            name="date"
            label='Date'
            rules={[
              {
                required: true,
                message: 'Please select date!',
              },
            ]}
          >        
            <DatePicker
              disabled={isEdit}
            />
          </Form.Item>

          <Form.Item
            name="expenseType"
            label='Expense Type'
            rules={[
              {
                required: true,
                message: 'Please input expense type!',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Expense Type'
              disabled={isEdit}
            >
              {expenseTypes.map((option, index)=> (
                <Option key={index} value={option.id}>{option.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="expensePeriod"
            label='Expense Period'
            initialValue='Daily'
            rules={[
              {
                required: true,
                message: 'Required',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Expense Type'
            >
              {expensePeriod.map(option => (
                <Option key={option.value}>{option.text}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            initialValue='Approved'
            rules={[
              {
                required: true,
                message: 'Please input status!',
              },
            ]}
          >
            <Select
              showArrow
              placeholder="Status"
            >
              {statuses.map((option)=> (
                <Option key={option.value}>{option.text}</Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item
            name="approval"
            label="Approval"
            rules={[
              {
                required: true,
                message: 'Please input status!',
              },
            ]}
          >
            <Select
              mode="multiple"
              showArrow
              disabled={isEdit}
              tagRender={tagRender}
              // defaultValue={statuses[1]}
              placeholder="Approval"
            >
              {users.map((option, index)=> (
                <Option key={index} value={option.id}>{option.username}</Option>
              ))}
            </Select>
          </Form.Item> */}

          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: 'Please input amount!',
              },
            ]}
          >
            <Input
              type="number"
              disabled={isEdit}
            />
          </Form.Item>

          <Form.Item
            name="note"
            label='Note'
            rules={[
              {
                required: true,
                message: 'Please input note!',
              },
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
        </Form>
      </CreateModal>
    </>
  )
}

export default CreateEditExpenseModal;