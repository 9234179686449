import axios from "axios";
import { useEffect, useState } from "react";

export const useSaleReps = () => {
  const [saleReps, setSaleReps] = useState([]);

  const getSaleReps = async () => {
    const response = await axios.get(`api/sale-reps`);
    if (response.data.code === 200) {
      setSaleReps(response.data.result);
    }
  };

  useEffect(() => {
    getSaleReps();
  }, []);

  return { saleReps };
};