import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  message,
  Button,
  Table,
  Checkbox,
  Space,
  Modal,
} from "antd";
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Loading } from "utils";
import { URL } from "../constant";
import { useTranslation } from "react-i18next";
const { confirm } = Modal;

const ProductVariantList = ({ parentId, values = [] }) => {
  const { t, i18n } = useTranslation();

  const [currentItem, setCurrentItem] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [bundles, setBundles] = useState([]);

  const onShowConfirmDelete = (value) => {
    confirm({
      title: "Are you sure delete this bundles?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          const data = { bundleId: value._id };
          return await new Promise((resolve, reject) => {
            axios
              .patch(`${URL}/product-bundle/${parentId}`, data)
              .then((response) => {
                if (response.data.code === 200) {
                  setBundles(response.data.result.bundles);
                  message.success("This data added in System.");
                  resolve(response.data);
                } else {
                  message.info("Failed");
                  reject(response.data.message);
                }
              });
          });
        } catch {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };

  const productTableColumns = [
    {
      title: t("label.name"),
      dataIndex: "name",
    },
    {
      title: t("label.items"),
      align: "center",
      dataIndex: "items",
    },
    {
      dataIndex: "action",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Space size="middle">
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => onShowConfirmDelete(record)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (values.length > 0) {
      setBundles(values);
    }
  }, [values]);

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
  };

  const onSuccess = () => {
    setCurrentItem(null);
    setEditModal(false);
  };

  const onCloseModal = () => {
    setCurrentItem(null);
    setEditModal(false);
  };

  return (
    <>
      <Table
        rowKey="_id"
        size="small"
        dataSource={bundles}
        pagination={false}
        bordered
        columns={productTableColumns}
        scroll={{ y: 350 }}
      />
    </>
  );
};

export default ProductVariantList;
