import React, { useState, useEffect } from 'react';
import { Modal, Button, Spin } from 'antd';
const CustomModal = ({
  title = '',
  icon = '',
  buttonDisabled = false,
  buttonName = '',
  footer = [],
  paramVisible = false,
  isLoading = false,
  children,
  width = 700
}) => {

  const [visible, setVisible] = useState(paramVisible);

  useEffect(() => {
    handleCancel();
  }, []);

  const handleCancel = e => {
    setVisible(false);
  };

  const handleClick = e => {
    setVisible(true);
  }

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={buttonDisabled}
        icon={icon}
      >
        { buttonName }
      </Button>
      <Modal
        title={title}
        width={width}
        zIndex={2}
        open={visible}
        onCancel={() => handleCancel()}
        footer={[
          footer,
          <Button key="back" onClick={() => handleCancel()}>
            Cancel
          </Button>
        ]}
      >
        <Spin spinning={isLoading}>
          { children }
        </Spin>
      </Modal>
    </>
  );
}

export default CustomModal;