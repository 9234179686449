import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, message, Select, Row, Col, Input, Button, Table, Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Loading } from 'utils';
import { REVENUE_API, STATUSES } from './constant';
import { useTranslation } from 'react-i18next';
import { CreateModal } from "components/modals/index";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const CreateEditModal = ({
  value = false,
  item = null,
  onSuccess,
  onCancel
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        status: formValue.status,
        remark: formValue.remark
      }
      const response = await axios.put(`${REVENUE_API}/confirm/${item.id}`, data);
      if (response.data.code === 200) {
        form.resetFields();
        onSuccess();
        message.success('This data added in System.');
      }
      else {
        message.info('Failed');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  useEffect(() => {
    if (item) {
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={t('action.confirm')}
        value={value}
        width={350}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form
          layout='vertical'
          form={form}
          validateMessages={validateMessages}
        >
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item label={t('label.status')} name="status" rules={[{ required: true }]}>
                <Select
                  showSearch
                  options={STATUSES}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item label={t('label.remark')} name="remark">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CreateModal>
    </>
  )
};

export default CreateEditModal;