import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, message, Form, Row, Col, Card, Select, Input } from 'antd';
import TableForm from './TableForm';
import CreateEditModal from './modal/createOrEdit';
import { Loading } from 'utils';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';
import { CATEGORY_URL, URL } from './constant';
import { TablePagination } from './constant';

const IndexPage = () => {

  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(TablePagination);
  const [createEditModal, setCreateEditModal] = useState(false);
  const [categories, setCategory] = useState([]);
  const [searchForm] = Form.useForm();

  useEffect(() => {
    async function fetch() {
      Loading.setLoading(true);
      await fetchProduct(pagination);
      await fetchCategory();
      Loading.setLoading(false);
    }
    fetch();
  }, []);

  const fetchCategory = async () => {
    try {
      const response = await axios.get(CATEGORY_URL)
      if (response && response.data) {
        setCategory(response.data.result || [])
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  const onTableChange = async (pagination) => {
    await fetchProduct(pagination);
  };

  const onReset = async () => {
    searchForm.resetFields();
    await fetchProduct(pagination);
  }

  const fetchProduct = async (paginationParm) => {
    try {
      Loading.setLoading(true);
      const searchParam = {
        ...getSearchParam(),
        page: paginationParm && paginationParm.current,
        pageSize: paginationParm && paginationParm.pageSize
      };
      const response = await axios.post(URL + '/search', searchParam);
      if (response && response.data) {
        setData(response.data.result);
        setPagination({
          ...paginationParm,
          total: response.data.options.total
        });
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      Loading.setLoading(false);
    }
  }

  const getSearchParam = () => {
    const data = searchForm.getFieldsValue(['name', 'categoryId']);
    return {
      name: data.name ?? '',
      categoryId: data.categoryId ?? ''
    }
  }

  const onCreate = () => {
    setCreateEditModal(true);
  }

  const onSuccess = async () => {
    setCreateEditModal(false);
    await fetchProduct(pagination);
  }


  const onCloseModal = () => {
    setCreateEditModal(false);
  }

  return (
    <>
      <PageHeader
        className='site-page-header'
        title={t('menu.product')}
        extra={
          <Button
            onClick={() => onCreate()}
          >
            {t('action.create')}
          </Button>
        }
      />
      <Card size="small">
        <Form
          form={searchForm}
        >
          <Row gutter={8}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item name="name" style={{ marginBottom: 0 }}>
                <Input placeholder="ឈ្មោះទំនិញ" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Form.Item
                name="categoryId"
                style={{ marginBottom: 0 }}
              >
                <Select
                  allowClear
                  placeholder="ប្រភេទ"
                  fieldNames={{ label: 'name', value: 'id'}}
                  filterOption={(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  }
                  options={categories}
                >
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Button
                style={{ marginLeft: '4px' }}
                type="primary"
                onClick={() => fetchProduct(pagination)}
              >
                { t('action.search')}
              </Button>
              <Button
                style={{ marginLeft: '4px' }}
                onClick={() => onReset()}
              >
                ឡើងវិញ
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card size='small'>
        <TableForm
          data={data}
          pagination={pagination}
          onTableChange={onTableChange}
          onLoadData={() => fetchProduct(pagination)}
        />
      </Card>
      <CreateEditModal
        value={createEditModal}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  )
}

export default IndexPage;