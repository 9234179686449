import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";

const CustomModalV2 = ({
  title = "",
  value = false,
  children,
  saveButtonText = "រក្សាទុក",
  cancelButtonText = "បោះបង់",
  width = 700,
  footer = null,
  onOk,
  onCancel,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(value);
  }, [value]);

  const handleCancel = (e) => {
    onCancel();
  };

  const handleOk = (e) => {
    onOk();
  };

  return (
    <>
      <Modal
        open={open}
        title={
          <div
            style={{
              width: "100%",
              cursor: "pointer",
            }}
          >
            {title}
          </div>
        }
        width={width}
        // centered
        zIndex={2}
        // maskClosable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          footer ?? (
            <>
              <Button onClick={() => handleCancel()}>
                {cancelButtonText}
              </Button>
              <Button onClick={() => handleOk()} type="primary">
                {saveButtonText}
              </Button>
            </>
          ),
        ]}
      >
        {children}
      </Modal>
    </>
  );
};

export default CustomModalV2;
