import React from "react";
import { Card, Badge } from 'antd';

const SummaryBox = ({ title, color = "green", children }) => {
  return (
    <>
      <Badge.Ribbon color={color}>
        <Card title={title} style={{ fontSize: 14, textAlign: 'center' }} size="small">
          { children }
        </Card>
      </Badge.Ribbon>
    </>
  );
};

export default SummaryBox;
