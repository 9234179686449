import React, { useEffect, useState } from 'react';
import { Statistic, Descriptions, Button, message, Form, Row, Col, Input, Card, Tabs, Typography, Divider } from 'antd';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListForm from './list';

const { Title } = Typography;

const StockDetailPage = ({}) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [stockDetail, setStockDetail] = useState({
    data: null,
    totalQty: 0,
    productName: '',
  });

  useEffect(() => {
    if (id) {
      getStockDetail(id)
    }
  }, [id])

  const getStockDetail = async (id) => {
    try {
      const response = await axios.get(`api/stocks/stock-transaction/${id}`);
      setStockDetail({
        data: response.data.result,
        totalQty: response.data.options.totalQty,
        productName: response.data.options.productName,
      });
    } catch (error) {
      setStockDetail({
        data: null,
        totalQty: 0
      });
    }
  }

  return (
    <>
      <Card>
        {/* <Title level={4}>{ t('menu.stockDetail') }</Title> */}
        <Row>
          <Col span={12}>
            <Statistic title="ឈ្មោះ" value={stockDetail.productName} />
          </Col>
          <Col span={12}>
            <Statistic title="ចំនួនស្ដុក" value={stockDetail.totalQty} />
          </Col>
        </Row>
        <Divider />
        { stockDetail ?
          <ListForm
            data={stockDetail.data}
          />
          :
          <></>
        }
      </Card>
    </>
  )
}

export default StockDetailPage;