import React, { useState, useEffect } from 'react';
import SupplierList from './list';
import SupplierCreateEditModal from './supplierCreateEditModal';
import { Loading } from 'utils';
import axios from 'axios';
import { Button, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { SUPPLIER_URL } from './supplier.constant';

const SupplierPage = () => {

  const [suppliers, setSuppliers] = useState([]);
  const [supplierCreateEditModal, setSupplierCreateEditModal] = useState(false);

  useEffect(() => {
    async function fetch() {
      Loading.setLoading(true);
      await fetchSuppliers();
      Loading.setLoading(false);
    }
    fetch();
  }, []);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(SUPPLIER_URL)
      if (response && response.data) {
        setSuppliers(response.data.result);
      }
    } catch (error) {
      message.error(error.message);
      setSuppliers([]);
    }
  }

  const onCreateSupplier = () => {
    setSupplierCreateEditModal(true);
  }

  const onCloseModal = () => {
    setSupplierCreateEditModal(false);
  }

  return (
    <>
      <PageHeader
        className='site-page-header'
        title='Supplier'
        extra={
          <Button
            onClick={() => onCreateSupplier()}
          >
            Create
          </Button>
        }
      />
      <SupplierList
        data={suppliers}
        onLoadData={() => fetchSuppliers()}
      />
      <SupplierCreateEditModal
        value={supplierCreateEditModal}
        onCancel={() => onCloseModal()}
      />
    </>
  )
}
    
export default SupplierPage;