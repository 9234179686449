import React, { forwardRef, useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import moment from "moment";
import style from "./index.module.css";
import { Riel } from "components/special";

const { Title } = Typography;

const PizzaInvoice = forwardRef((props, ref) => {
  const [printContent, setPrintContent] = useState(null);

  function setPageSize(cssPageSize) {
    const style = document.createElement("style");
    style.innerHTML = `
      @page {
        size: ${cssPageSize}
      }
      @media print {
        thead {display: table-header-group;}
        tfoot {display: table-footer-group;}
      }`;
    style.id = "page-orientation";
    document.head.appendChild(style);
  }

  useEffect(() => {
    // setPageSize('landscape');
    setPageSize("portrait");
    return () => {
      const child = document.getElementById("page-orientation");
      child.parentNode.removeChild(child);
    };
  }, []);

  useEffect(() => {
    if (props.item && Object.keys(props.item).length > 0) {
      setPrintContent(props.item);
    }
  }, [props.item]);

  const genBlankRow = (length = 0) => {
    var rows = [];
    for (let i = length; i < 24; i++) {
      var blank = (
        <tr>
          <td>{i + 1}</td>
          <td></td>
          <td style={{ textAlign: "center" }}></td>
          <td style={{ textAlign: "right" }}></td>
          <td style={{ textAlign: "right" }}></td>
        </tr>
      );
      rows.push(blank);
    }
    return rows;
  };

  const date = moment().format("DD/MM/YYYY hh:mm A");
  const createdAt =
    printContent === null
      ? date
      : moment(printContent.createdAt).format("DD/MM/YYYY hh:mm A");
  return (
    <div style={{ display: "none" }}>
      <div ref={ref}>
        {printContent ? (
          <>
            <div className={style.pizzainvoice}>
              <div className={style.pageHeader}>
                <div>
                  <Row gutter={0}>
                    <Col span={15}>
                      <Row gutter={0}>
                        <Col span={24}>
                          អ្នកលក់:
                          <b>
                            {printContent.saleRepId &&
                              printContent.saleRepId.phoneNumber}
                            (
                            {printContent.saleRepId &&
                              printContent.saleRepId.name}
                            )
                          </b>
                        </Col>
                        <Col span={24}>
                          អ្នកទិញ:<b> {printContent.phoneNumber}</b>
                        </Col>
                        <Col span={24}>
                          ដឹក:
                          <b>
                            {printContent.deliveryName} (
                            {printContent.deliveryMobilePhone})
                          </b>
                        </Col>
                        <Col span={24}>បោះពុម្ព: {date}</Col>
                        <Col span={24}>
                          <Title level={4}>
                            លេខវិក្កយបត្រ:<b> {printContent.code}</b> (
                            {printContent.invoiceSequence})
                          </Title>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={9}>
                      <Row gutter={0}>
                        <Col span={24}>{createdAt}</Col>
                        <Col span={24}>
                          <span>
                            បញ្ចុះតម្លៃ:
                            <b>
                              <Riel value={printContent.discount || 0} />
                            </b>
                          </span>
                        </Col>
                        <Col span={24}>
                          <span style={{ fontSize: 23 }}>
                            តម្លៃ​សរុប:
                            <b>
                              <Riel value={printContent.totalAmount || 0} />
                            </b>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>

              <table>
                <thead>
                  <tr>
                    <td>
                      <div className={style.pageHeaderSpace}></div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className={`${style.page} ${style.pizzaTable}`}>
                        <table className="table">
                          <colgroup>
                            <col style={{ width: "5%" }}></col>
                            <col style={{ width: "45%" }}></col>
                            <col style={{ width: "10%" }}></col>
                            <col style={{ width: "20%" }}></col>
                            <col style={{ width: "20%" }}></col>
                          </colgroup>
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>មុខទំនិញ</th>
                              <th>ចំនួន</th>
                              <th>តម្លៃរាយ</th>
                              <th>សរុប</th>
                            </tr>
                          </thead>
                          <tbody>
                            {printContent.detail &&
                              printContent.detail.map((item, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.productName}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.qty}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      <Riel value={item.price || 0} />
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      <Riel value={item.amount || 0} />
                                    </td>
                                  </tr>
                                );
                              })}
                            {printContent.detail &&
                            printContent.detail.length < 15 ? (
                              genBlankRow(printContent.detail.length)
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td
                                colSpan={2}
                                style={{
                                  textAlign: "left",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                TOTAL / សរុប
                              </td>
                              <td
                                colSpan={3}
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                <Riel value={printContent.subTotal || 0} />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                style={{
                                  textAlign: "left",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                DISCOUNT / បញ្ចុះតម្លៃ
                              </td>
                              <td
                                colSpan={3}
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                <Riel value={printContent.discount || 0} />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                style={{
                                  textAlign: "left",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                GRAND TOTAL / តម្លៃ​សរុប
                              </td>
                              <td
                                colSpan={3}
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                  fontSize: 23,
                                }}
                              >
                                <Riel value={printContent.totalAmount || 0} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={style.stickerPage}>
              <div className={style.firstSection}>
                <div>
                  <h3 className={style.alignCenter}>
                    ({printContent.invoiceSequence})
                  </h3>
                </div>
                <div>
                  <h5 className={style.alignCenter}>អ្នកទិញ</h5>
                  <h4 className={style.alignCenter}>
                    <b>{printContent.phoneNumber}</b>
                  </h4>
                </div>

                <div>
                  <h5 className={style.alignCenter}>អ្នកលក់</h5>
                  <h4 className={style.alignCenter}>
                    <b>
                      {printContent.saleRepId &&
                        printContent.saleRepId.phoneNumber}
                      ({printContent.saleRepId && printContent.saleRepId.name})
                    </b>
                  </h4>
                </div>

                <div>
                  <h5 className={style.alignCenter}>ទីតាំង</h5>
                  <h4 className={style.alignCenter}>
                    <b>{printContent.address}</b>
                  </h4>
                  {printContent.deliveryMobilePhone !== "" && (
                    <h5 className={style.alignCenter}>លេខឡាន</h5>
                  )}
                  {printContent.deliveryMobilePhone !== "" && (
                    <h4 className={style.alignCenter}>
                      {printContent.deliveryMobilePhone}
                    </h4>
                  )}
                  <h5 className={style.alignCenter}>
                    <b>{printContent.remark}</b>
                  </h5>
                  <h5 className={style.alignCenter}>
                    ដឹក: <b>{printContent.deliveryName}</b>
                  </h5>
                </div>

                <div>
                  <h4 className={style.alignCenter}>
                    តម្លៃ​សរុប:
                    <b>
                      <Riel value={printContent.totalAmount || 0} />
                    </b>
                  </h4>
                  <h4 className={style.alignCenter}>
                    ដឹក: <Riel value={printContent.deliveryPrice || 0} />
                  </h4>
                </div>
              </div>

              <div className={style.secondSection}>
                <div className={style.alignCenter}>{createdAt}</div>
                <div>
                  <h3 className={style.alignCenter}>
                    ({printContent.invoiceSequence})
                  </h3>
                </div>
                <div>
                  <h4 className={style.alignCenter}>
                    <b>{printContent.saleRepId.shopName}</b>
                  </h4>
                </div>
                <div>
                  <h5 className={style.alignCenter}>អ្នកលក់</h5>
                  <h4 className={style.alignCenter}>
                    {printContent.saleRepId &&
                      printContent.saleRepId.phoneNumber}
                    ({printContent.saleRepId && printContent.saleRepId.name})
                  </h4>
                </div>

                <div>
                  <h4 className={style.alignCenter}>
                    សរុប: <Riel value={printContent.totalAmount || 0} />
                  </h4>
                </div>

                <div>
                  <h4 className={style.alignCenter}>{printContent.address}</h4>
                  {printContent.deliveryMobilePhone !== "" && (
                    <h4 className={style.alignCenter}>
                      អ្នកដឹក: {printContent.deliveryMobilePhone}
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
});

export default PizzaInvoice;
