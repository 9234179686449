import { useEffect, useState } from 'react';
import { Card, Button, message, Table, Space } from 'antd';
import { defaultPagination } from 'constants/config/pagination';
import Toolbar from "components/common/Toolbar";
import CreateEdit from './createEdit';
import { AssetApi } from './asset.constant';
import Confirm from 'components/modals/Confirm';
import axios from 'axios';
import { Loading } from 'utils';

const AssetPage = () => {
  // Data
  const [pagination, setPagination] = useState(defaultPagination(10))
  const [createEditModal, setCreateEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [assets, setAsset] = useState([]);
  const columns = [
    {
      dataIndex: 'description',
      title: 'Description',
      key: 'description'
    },
    {
      dataIndex: 'assetType',
      title: 'Asset Type',
      key: 'assetType'
    },
    {
      dataIndex: 'amount',
      title: 'Amount',
      key: 'amount'
    },
    {
      dataIndex: '',
      title: 'Action',
      key: '',
      fixed: 'right',
      render: (item) => {
        return (
          <Space>
            <Button
              size="small"
              onClick={() => openCreateEditModal(item)}
            >
              Edit
            </Button>
            {/* <Button
              size="small"
              onClick={() => deleteAsset(item)}
            >
              Delete
            </Button> */}
          </Space>
        )
      }
    },
  ]

  // Method
  const getAsset = async () => {
    try {
      const response = await axios.get(AssetApi)
      if (response && response.data) {
        setAsset(response.data && response.data.result);
      } else {
        setAsset([])
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  const deleteAsset = async (item) => {
    Confirm({
      title: "Delete",
      content: item.description,
      okType: 'warning',
      async onOk () {
        // TODO: Delete asset
      },
      onCancel () {}
    })
  }
  const handleTableChange = (pagination) => {
    setPagination(pagination)
  }
  const openCreateEditModal = (item) => {
    if (item) {
      setCurrentItem(item);
    }
    setCreateEditModal(true);
  }
  const onSuccess = async () => {
    setCreateEditModal(false);
    setCurrentItem(null);
    await getAsset();
  }
  const onCancel = () => {
    setCreateEditModal(false);
    setCurrentItem(null);
  }

  // Fetch
  useEffect(() => {
    async function fetch () {
      Loading.setLoading(true);
      await getAsset();
      Loading.setLoading(false);
    }

    fetch();
  }, [])

  return (
    <>
      <Card>
        <Toolbar
          title="Asset"
          extra={
            <Button onClick={() => openCreateEditModal()}>Create</Button>
          }
        />
        <Table
          rowKey='id' 
          size="middle"
          pagination={pagination}     
          onChange={handleTableChange}
          dataSource={assets}
          columns={columns}
        />
        <CreateEdit
          visible={createEditModal}
          item={currentItem}
          onCancel={() => onCancel()}
          onSuccess={() => onSuccess()}
        />
      </Card>
    </>
  )
}

export default AssetPage;