import React, { useState, useEffect } from 'react';
import TableForm from './TableForm';
import CreateEditModal from './createEditModal';
import { Loading } from 'utils';
import axios from 'axios';
import { Button, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { URL } from './constant';
import { useTranslation } from 'react-i18next';

const IndexPage = () => {

  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [createEditModal, setCreateEditModal] = useState(false);

  useEffect(() => {
    async function fetch() {
      Loading.setLoading(true);
      await fetchData();
      Loading.setLoading(false);
    }
    fetch();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(URL)
      if (response && response.data) {
        setData(response.data.result);
      }
    } catch (error) {
      message.error(error.message);
      setData([]);
    }
  }

  const onCreate = () => {
    setCreateEditModal(true);
  }

  const onSuccess = async () => {
    setCreateEditModal(false);
    await fetchData();
  }


  const onCloseModal = () => {
    setCreateEditModal(false);
  }

  return (
    <>
      <PageHeader
        className='site-page-header'
        title={t('menu.category')}
        extra={
          <Button
            onClick={() => onCreate()}
          >
            {t('action.create')}
          </Button>
        }
      />
      <TableForm
        data={data}
        onLoadData={() => fetchData()}
      />
      <CreateEditModal
        value={createEditModal}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  )
}
    
export default IndexPage;