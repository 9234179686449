import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Card, Form, Button, Row, Col, DatePicker, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Loading } from 'utils';
import DailySummaryPage from './dailySummary';
// import DailyPage from './daily';
import './style.css';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const IndexPage = () => {

  const [searchForm] = Form.useForm();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [facebookPages, setFacebookPages] = useState([]);
  // const [products, setProducts] = useState([]);

  useEffect(() => {
    onLoadData();
    onLoadFacebookPage();
  }, []);

  const onLoadData = async () => {
    Loading.setLoading(true);
    const data = searchForm.getFieldsValue(['fromDate', 'toDate']);
    let query = '';
    if (data.fromDate && data.toDate) {
      query = `/${data.fromDate.format('YYYY-MM-DD')}/${data.toDate.format('YYYY-MM-DD')}`;
    }
    const response = await axios.get(`api/sales/summary${query}`);
    if (response.data.code === 200) {
      setData(response.data.result);
      // setProducts(response.data.result.products)
    } else {
      setData([]);
      message.info('This sale not yet have in System.');
    }
    Loading.setLoading(false);
  };

  const onLoadFacebookPage = async () => {
    Loading.setLoading(true);
    const response = await axios.get(`api/sales/facebook-pages`)
    if (response.data.code === 200) {
      setFacebookPages(response.data.result);
      // setProducts(response.data.result.products)
    } else {
      setFacebookPages([]);
      message.info('This sale not yet have in System.');
    }
    Loading.setLoading(false);
  };

  return (
    <>
      <Card size="small">
        <Form
          {...formItemLayout}
          style={{ marginBottom: 0 }}
          form={searchForm}
        >
          <Row gutter={[8, 8]}>
            <Col xs={12} sm={3} md={3}>
              <Form.Item
                name="fromDate"
                style={{ marginBottom: 0 }}
              >
                <DatePicker placeholder={t('label.fromDate')} style={{ display: 'block' }} />
              </Form.Item>
            </Col>
            <Col xs={12} sm={3} md={3}>
              <Form.Item
                name="toDate"
                style={{ marginBottom: 0 }}
              >
                <DatePicker placeholder={t('label.toDate')} style={{ display: 'block' }} />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Form.Item  style={{ marginBottom: 0 }} label="">
                <Button
                  style={{ marginLeft: '4px' }}
                  type="primary"
                  onClick={() => onLoadData()}
                >
                  { t('action.search')}
                </Button>
                <Button
                  style={{ marginLeft: '4px' }}
                  onClick={() => onReset()}
                >
                  ឡើងវិញ
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <DailySummaryPage data={data} facebookPages={facebookPages} />
    </>
  );
}

export default IndexPage;