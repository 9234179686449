import React from 'react';
import { Layout, version } from 'antd';

const package_json = require('../../../package.json');

const { Footer  } = Layout;

const FooterLayout = () => {

  return (
    <>
      <Footer style={{ textAlign: 'center' }}>Phnom Daily ©2021 Created by Weekend <b>Version { package_json.version }, { version }</b></Footer>
    </>
  )
}

export default FooterLayout;