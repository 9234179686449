import React, { useState, useEffect } from "react";
import { Form, message, Input, Select } from "antd";
import { layoutConstant } from "constants/config/form-layout.constant";
import { Loading } from "utils";
import { Roles, USER_ROLE } from "constants/role.constant";
import axios from "axios";
import { CreateModal } from "components/modals/index";

const CreateEditModal = ({
  value = false,
  item = null,
  isEdit = false,
  onSuccess,
  onCancel,
  companyId = ''
}) => {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        username: formValue.username,
        password: formValue.password,
        email: formValue.email,
        role: formValue.role,
        companyId,
      };
      if (item && item.id) {
        await put(item.id, data);
      } else {
        await post(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const post = async (data) => {
    const response = await axios.post(`api/company/users`, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success("This data added in System.");
    } else {
      message.info("Failed");
    }
  };

  const put = async (id, data) => {
    const response = await axios.put(`api/users/${id}`, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success("This data added in System.");
    } else {
      message.error(response.data.message);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        username: item.username,
        password: item.password,
        email: item.email,
        role: item.role.name
      });
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={isEdit ? "Edit User" : "Create User"}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form
          form={form}
          initialValues={{
            role: USER_ROLE.value,
          }}
          {...layoutConstant}
        >
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[A-Za-z0-9]*$/i),
                message: "Please input only letters and numbers",
              },
              { required: true, message: "Please input your username!" },
              { min: 5, message: "Username must be minimum 5 characters." },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>

          <Form.Item label="តួនាទី" name="role" rules={[{ required: true }]}>
            <Select
              showSearch
              fieldNames={{ label: "name", value: "value" }}
              filterOption={(input, option) => {
                return (option?.name ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              options={Roles}
            />
          </Form.Item>

          {!isEdit && (
            <>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )}
        </Form>
      </CreateModal>
    </>
  );
};

export default CreateEditModal;
