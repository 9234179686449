import React from "react";
import "antd/dist/reset.css";
import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { Root } from "routes/index";

const router = createBrowserRouter([{ path: "*", Component: Root }]);

export default function App() {
  return <RouterProvider router={router} />;
}
