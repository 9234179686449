import React, { useState } from "react";
import { message, Button, Space, Table, Divider, Checkbox } from "antd";
import {
  DeleteOutlined,
  ClusterOutlined,
  EditOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Confirm from "components/modals/Confirm";
import { Loading } from "utils";
import CreateEditModal from "./modal/createOrEdit";
import VariantModal from "./variants/index";
import BundleModel from "./bundles/index";
import { useTranslation } from "react-i18next";
import { URL } from "./constant";
import { joinString } from "utils";

const TableForm = ({ data, pagination, onLoadData, onTableChange }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [variantModal, setVariantModal] = useState(false);
  const [bundleModel, setBundleModel] = useState(false);

  const headers = [
    {
      title: "#",
      dataIndex: "key",
      render: (value, item, index) => (pagination.current - 1) * 10 + index + 1,
    },
    {
      title: t("menu.category"),
      render: (text, record) => (
        <>{record.categoryId ? record.categoryId.name : ""}</>
      ),
    },
    {
      title: t("label.name"),
      dataIndex: "name",
      render: (_, record) => <>{joinString([record.code, record.name])}</>,
    },
    {
      title: t("label.productType"),
      dataIndex: "type",
    },
    {
      title: t("label.wholesalePrice"),
      dataIndex: "wholesalePrice",
    },
    {
      title: t("label.retailPrice"),
      dataIndex: "retailPrice",
    },
    {
      title: t("label.hideInSell"),
      dataIndex: "isHide",
      align: "center",
      render: (text, record) => (
        <>
          <Checkbox checked={record.isHide}></Checkbox>
        </>
      ),
    },
    {
      title: t("action.action"),
      key: "action",
      fixed: "right",
      render: (record) => (
        <Space>
          <Button
            icon={<ClusterOutlined />}
            onClick={() => onVariant(record)}
            disabled={record.type !== "Standard Product" ? true : false}
          ></Button>
          <Button
            icon={<AppstoreAddOutlined />}
            onClick={() => onBundle(record)}
            disabled={record.type !== "Product Bundle" ? true : false}
          ></Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          ></Button>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record)}
          ></Button>
        </Space>
      ),
    },
  ];

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
  };

  const onDelete = (item) => {
    Confirm({
      title: "បញ្ជាក់ការលុប",
      okType: "warning",
      async onOk() {
        try {
          Loading.setLoading(true);
          findOneAndDelete(item.id);
        } catch (error) {
          message.error(error.message);
        } finally {
          Loading.setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  const findOneAndDelete = async (id) => {
    const response = await axios.delete(`${URL}/${id}`);
    if (response.data.code === 200) {
      onSuccess();
      message.success("This data added in System.");
    } else {
      message.info("Failed");
    }
  };

  const onVariant = (item) => {
    setCurrentItem(item);
    setVariantModal(true);
  };

  const onBundle = (item) => {
    setCurrentItem(item);
    setBundleModel(true);
  };

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setVariantModal(false);
    setBundleModel(false);
    setEditModal(false);
  };

  const onCloseModal = () => {
    setCurrentItem(null);
    setEditModal(false);
    setVariantModal(false);
    setBundleModel(false);
  };

  return (
    <>
      <Table
        rowKey="id"
        size="small"
        dataSource={data}
        columns={headers}
        pagination={pagination}
        onChange={onTableChange}
      />
      <CreateEditModal
        value={editModal}
        item={currentItem}
        isEdit
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
      <VariantModal
        value={variantModal}
        item={currentItem}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
      <BundleModel
        value={bundleModel}
        item={currentItem}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  );
};

export default TableForm;
