import { useState, useRef } from 'react';
import { Row, Col, Button, Form, message, Input, Select, Card } from 'antd';
import PopupFilter from 'components/common/FilterPopup';
import { FilterOutlined } from '@ant-design/icons';
import PizzaInvoice from 'pages/report/pizzaInvoiceReport/index'

import { useReactToPrint } from "react-to-print";

const { Option } = Select;

const UiPage = () => {
  const componentRef = useRef(null);
  const options = [
    { text: 'Item 1', value: 'Item 1' },
    { text: 'Item 2', value: 'Item 2' },
    { text: 'Item 3', value: 'Item 3' },
    { text: 'Item 4', value: 'Item 4' },
    { text: 'Item 5', value: 'Item 5' },
    { text: 'Item 6', value: 'Item 6' },
    { text: 'Item 7', value: 'Item 7' },
    { text: 'Item 8', value: 'Item 8' },
    { text: 'Item 9', value: 'Item 9' }
  ];
  const [filterPopup, setFilterPopup] = useState(false);

  function handleChange(value) {
    console.log(`Selected: ${value}`);
  }

  const openFilter = () => {
    setFilterPopup(true)
  }

  const onCloseFilter = () => {
    // Close Filter
    message.info('Close filter popup')
    setFilterPopup(false)
  }

  const onResetFilter = () => {
    // Do reset
    message.info('Reset filter popup')
    setFilterPopup(false)
  }

  const onFilter = () => {
    // Do Filter
    message.info('Do filter')
    setFilterPopup(false)
  }

  const onPrint = () => {
    console.log('print')
    print();
  }

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Card>
        <Row gutter={8}>
          <Col span={24}>
            <PizzaInvoice ref={componentRef}></PizzaInvoice>
            <Button onClick={() => onPrint()}>
              Print Invoice
            </Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row gutter={8}>
          <Col span={8}>
            <PopupFilter
              visible={filterPopup}
              onClose={() => onCloseFilter()}
              onReset={() => onResetFilter()}
              onFilter={() => onFilter()}
              button={
                <Button
                  icon={<FilterOutlined />}
                  onClick={() => openFilter()}
                />
              }
              form={
                <Form>
                  <Form.Item>
                    <Input
                      placeholder="Search"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Select
                      mode="multiple"
                      placeholder="Please select"
                      onChange={handleChange}
                      style={{ maxWidth: '300px' }}
                    >
                      {options.map((item, index) => (
                        <Option value={item.value} key={index}>{item.text}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              }
            >
            </PopupFilter>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default UiPage;