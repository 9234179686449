import { Row, Col, Typography } from 'antd'
const { Title } = Typography;

const Toolbar = ({ title, extra }) => {
  return (
    <>
      <Row gutter={4} justify="space-between">
        <Col>
          <Title level={3}>{title}</Title>  
        </Col>
        <Col>{extra}</Col>
      </Row>
    </>
  )
}

export default Toolbar;