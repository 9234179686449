import React from 'react';
import { Tabs, Card, Typography } from 'antd';
import PostClosingPage from './postClosing';
import DailyPage from './daily';

const { Title } = Typography;

const { TabPane } = Tabs;

const DailyClosingBalance = () => {
  return (
    <>
      <Title level={3}>Daily Closing Balance</Title>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Post-Closing Trial Balance' key="1">
          <PostClosingPage />
        </TabPane>
        <TabPane tab='Overview Daily' key="2">
          <DailyPage />
        </TabPane>
      </Tabs>
    </>
  )
}

export default DailyClosingBalance;