import React, { useEffect, useRef, useState } from "react";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  Row,
  Select,
  Flex,
  Typography,
  Button,
  Checkbox,
  Input,
  Form,
  Modal,
  InputNumber,
  Table,
  Tag,
  Space,
  DatePicker,
  Radio,
} from "antd";
import { useTranslation } from "react-i18next";
const { Meta } = Card;
const { Text } = Typography;

const onChange = (value) => {
  console.log(`selected ${value}`);
};
const onSearch = (value) => {
  console.log("search:", value);
};

// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const data = [
  {
    key: "1",
    name: "John Brown",
    goals: 24,
    assists: 10,
  },
  {
    key: "2",
    name: "Jim Green",
    goals: 24,
    assists: 10,
  },
  {
    key: "3",
    name: "Joe Black",
    goals: 24,
    assists: 10,
  },
];
const POSPage = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const showUserModal = () => {
    setOpen(true);
  };
  const hideUserModal = () => {
    setOpen(false);
  };

  const [tableData, setTableData] = useState(data);
  useEffect(() => {
    // Set totals on initial render
    const newData = [...tableData];
    for (let index = 0; index < tableData.length; index++) {
      setTotal(newData, index);
    }
    setTableData(newData);
  }, []);

  const onInputChange = (key, index) => (e) => {
    const newData = [...tableData];
    newData[index][key] = Number(e.target.value);
    setTotal(newData, index);
    setTableData(newData);
  };

  const setTotal = (data, index) => {
    // Set total
    data[index]["totalCount"] = Number(
      data[index]["goals"] + data[index]["assists"]
    );
  };

  const onChangeDiscountType = (value) => (e) => {
    console.log(e);
    console.log(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (text) => <a>{text}</a>,
    },
    {
      dataIndex: "price",
      title: "Price ($)",
      align: "center",
      width: "15%",
      render: (text, record, index) => (
        <Input value={text} onChange={onInputChange("price", index)} />
      ),
    },
    {
      dataIndex: "qty",
      title: "Qty",
      align: "center",
      width: "10%",
      render: (text, record, index) => (
        <Input value={text} onChange={onInputChange("qty", index)} />
      ),
    },
    {
      dataIndex: "discount",
      title: "Dis",
      align: "center",
      width: "15%",
      render: (text, record, index) => (
        <Input value={text} onChange={onInputChange("discount", index)} />
      ),
    },
    {
      dataIndex: "discountType",
      title: "Dis Type",
      align: "center",
      width: "11%",
      render: (text, record) => (
        <Select
          showSearch
          placeholder="Dis Type"
          style={{ width: "100%" }}
          optionFilterProp="children"
          // onChange={(e) => onChangeDiscountType(e, record)}
          onChange={onChangeDiscountType(record)}
          filterOption={filterOption}
          options={[
            {
              value: "jack",
              label: "%",
            },
            {
              value: "lucy",
              label: "Fixed",
            },
          ]}
        />
      ),
    },
    {
      dataIndex: "totalCount",
      title: "Sub Total",
      align: "right",
      width: "20%",
      render: (text, record, index) => <h4>{text}</h4>,
    },
    {
      title: "Action",
      key: "action",
      width: "4%",
      render: (_, record) => (
        <Space size="middle">
          <Button danger icon={<DeleteOutlined />} />
        </Space>
      ),
    },
  ];

  const [value2, setValue2] = useState("Apple");
  const optionsWithDisabled = [
    {
      label: "Apple",
      value: "Apple",
    },
    {
      label: "Pear",
      value: "Pear",
    },
    {
      label: "Orange",
      value: "Orange",
      disabled: true,
    },
  ];
  const onChange2 = ({ target: { value } }) => {
    console.log("radio2 checked", value);
    setValue2(value);
  };
  return (
    <Row>
      <Col span={12}>
        <Card>
          <Row gutter={[16, 24]}>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={4}>
                  <Card title="Card title">Card content</Card>
                </Col>
                <Col span={4}>
                  <Card title="Card title">Card content</Card>
                </Col>
                <Col span={4}>
                  <Card title="Card title">Card content</Card>
                </Col>
                <Col span={4}>
                  <Card title="Card title">Card content</Card>
                </Col>
                <Col span={4}>
                  <Card title="Card title">Card content</Card>
                </Col>
                <Col span={4}>
                  <Card title="Card title">Card content</Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={12}>
        <Card>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Typography.Title level={5}>Customer Name</Typography.Title>
            </Col>
            <Col span={12}>
              <Flex vertical align="flex-end" justify="space-between">
                <Checkbox onChange={onChange}>Walk-in Customer</Checkbox>
              </Flex>
            </Col>
            <Col span={24}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Customer Name</Typography.Title>
            </Col>
            <Col span={24}>
              <Input placeholder="phone number" />
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Phone Number</Typography.Title>
            </Col>
            <Col span={24}>
              <Input placeholder="phone number" />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Shipping Info</Typography.Title>
            </Col>
            <Col span={12}>
              <Flex vertical align="flex-end" justify="space-between">
                <Button
                  htmlType="button"
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={showUserModal}
                >
                  Add User
                </Button>
              </Flex>
            </Col>
            <Col span={24}>
              <DatePicker
                placeholder={t("label.fromDate")}
                style={{ display: "block" }}
              />
            </Col>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                size="small"
                summary={(pageData) => {
                  let totalBorrow = 0;
                  let totalRepayment = 0;
                  // pageData.forEach(({ borrow, repayment }) => {
                  //   totalBorrow += borrow;
                  //   totalRepayment += repayment;
                  // });
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4}>
                          Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2} align="right">
                          <Text type="danger">{totalBorrow}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          Discount
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2}>
                          <Input placeholder="enter a mount" />
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Dis Type"
                            optionFilterProp="children"
                            // onChange={(e) => onChangeDiscountType(e, record)}
                            onChange={onChangeDiscountType()}
                            filterOption={filterOption}
                            options={[
                              {
                                value: "jack",
                                label: "%",
                              },
                              {
                                value: "lucy",
                                label: "Fixed",
                              },
                            ]}
                          />
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} colSpan={2} align="right">
                          <Text type="danger">
                            {totalBorrow - totalRepayment}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4}>
                          Total Discount
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2} align="right">
                          <Text type="danger">
                            {totalBorrow - totalRepayment}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4}>
                          Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2} align="right">
                          <Text type="danger">
                            {totalBorrow - totalRepayment}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Payment</Typography.Title>
            </Col>
            <Col span={24}>
              <Radio.Group
                options={optionsWithDisabled}
                onChange={onChange2}
                value={value2}
              />
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Total Paid</Typography.Title>
            </Col>
            <Col span={24}>
              <Input placeholder="Payment" />
            </Col>
            <Col span={24}>
              <Flex align="center" justify="flex-end" gap="small">
                <Button type="primary">Primary</Button>
                <Button type="primary">Primary</Button>
              </Flex>
            </Col>
          </Row>
        </Card>
      </Col>
      <ModalForm open={open} onCancel={hideUserModal} />
    </Row>
  );
};

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};

const ModalForm = ({ open, onCancel }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({
    form,
    open,
  });
  const onOk = () => {
    form.submit();
  };
  return (
    <Modal title="Basic Drawer" open={open} onOk={onOk} onCancel={onCancel}>
      <Form form={form} layout="vertical" name="userForm">
        <Form.Item
          name="name"
          label="User Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="age"
          label="User Age"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default POSPage;
