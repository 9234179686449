import React from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const EditButton = ({
  onClick,
  disabled = false,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Tooltip placement="topLeft" color='geekblue' title={ t('action.edit') }>
      <Button
        icon={<EditOutlined />}
        onClick={() => onClick()}
        disabled={disabled}
      />
    </Tooltip>
  )
}

export default EditButton;