export const PurchaseOrderStatus = Object.freeze([
  { text: 'New', value: 'New' },
  { text: 'Partially Received', value: 'Partially Received' },
  { text: 'Received', value: 'Received' },
  { text: 'Cancelled', value: 'Cancelled' }
])

export const DeliveryStatus = Object.freeze([
  { text: 'Processing', value: 'Processing' },
  { text: 'Shipped', value: 'Shipped' },
  { text: 'In Transit', value: 'In Transit' },
  { text: 'Delivered', value: 'Delivered' }
])