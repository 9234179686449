import React, { useState, useEffect } from "react";
import { Form, Table, Alert } from "antd";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import { Loading, formatDate } from "utils";
import { Riel } from "components/special/Currency";
import { CreateModal } from "components/modals/index";

const ViewPaymentModal = ({
  value = false,
  item = null,
  onSuccess,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const headers = [
    {
      title: "№",
      dataIndex: "id",
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Date",
      dataIndex: "createAt",
      responsive: ["xs", "sm"],
      align: "center",
      render: (text, record) => <div>{formatDate(record.datePosted)}</div>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      render: (text, record) => (
        <>
          <Riel value={record.amount} />
        </>
      ),
    },
    {
      title: "Remark",
      dataIndex: "remark",
      align: "right",
    },
  ];

  useEffect(() => {
    if (item) {
    }
  }, [item]);

  const onSubmit = async () => {
    onCancel();
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <>
      <CreateModal
        title={t("label.viewHistory")}
        value={value}
        width={450}
        saveButtonText="Ok"
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Alert
          banner
          message={
            <Marquee pauseOnHover gradient={false}>
              Remainder: .
            </Marquee>
          }
        />
        <br />
        <Table
          rowKey="id"
          size="small"
          columns={headers}
          dataSource={item?.payments}
        />
      </CreateModal>
    </>
  );
};

export default ViewPaymentModal;
