import React, { useState } from "react";
import { message, Table, Space, Tag, Button } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Confirm from "components/modals/Confirm";
import { Loading } from "utils";
import { useTranslation } from "react-i18next";
import CreateUserModal from "./modal/createUser";

const TableForm = ({ data, onLoadData }) => {

  const { t, i18n } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const headers = [
    {
      title: "Username",
      dataIndex: "username",
      editable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => {
        return (
          <Space size="0">
            <Tag  color="#108ee9">{role}</Tag>
          </Space>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          ></Button>
          <Button
            danger
            disabled
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record)}
          ></Button>
        </Space>
      ),
    },
  ];

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
  };

  const onDelete = (item) => {
    Confirm({
      title: "Confirm Delete",
      okType: "warning",
      async onOk() {
        try {
          Loading.setLoading(true);
          // TODO: Implement delete method
        } catch (error) {
          message.error(error.message);
        } finally {
          Loading.setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setEditModal(false);
  };

  const onCloseModal = () => {
    setCurrentItem(null);
    setEditModal(false);
  };

  return (
    <>
      <Table rowKey="id" columns={headers} dataSource={data} />
      <CreateUserModal
        value={editModal}
        item={currentItem}
        isEdit={true}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  );
};

export default TableForm;
