import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, message, Select, Row, Col, Button, Table, Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Loading } from 'utils';
import { SALE_REP_URL, CATEGORY_URL } from 'constants/api';
import { useTranslation } from 'react-i18next';
import { CreateModal } from "components/modals/index";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const CreateEditModal = ({
  value = false,
  item = null,
  onSuccess,
  onCancel
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const [categories, setCategories] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);

  const productTableColumns = [
    {
      title: t('label.category'),
      dataIndex: 'name',
    },
    {
      dataIndex: 'action',
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (product, record) => (
        <Space size="middle">
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => onRemoveCategory(record)}
          />
        </Space>
      )
    }
  ];

  const fetchCategory = async () => {
    const response = await axios.get(CATEGORY_URL);
    if (response.data.code === 200) {
      setCategories(response.data.result);
    }
  }

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const data = {
        categories: currentCategories.map(x => x.id),
      }
      const response = await axios.put(`${SALE_REP_URL}/organize-categories/${item.id}`, data);
      if (response.data.code === 200) {
        form.resetFields();
        onSuccess();
        message.success('This data added in System.');
      }
      else {
        message.info('Failed');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  const onAddCategory = async () => {
    try {
      const values = await form.validateFields();
      const findCategory = categories.find(item => item.id === values.category.key);
      const categoryExist = currentCategories.find(item => item.id === values.category.key);
      if (!categoryExist){
        const selectedCategory = {
          id: findCategory.id,
          name: findCategory.name,
        }
        setCurrentCategories(currentCategories => [...currentCategories, selectedCategory]);
      }
      form.resetFields();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onRemoveCategory = (value) => {
    const updateCurrentCategories = currentCategories.filter(item => item.id !== value.id);
    setCurrentCategories(updateCurrentCategories);
  };

  useEffect(() => {
    if (item) {
      fetchCategory();
      setCurrentCategories([]);
      if (item.categories.length > 0) {
        setCurrentCategories(item.categories);
      }
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={t('menu.category')}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form
          layout='vertical'
          form={form}
          validateMessages={validateMessages}
        >
          <Row gutter={8}>
            <Col xs={10} sm={7} md={7}>
              <Form.Item label={t('label.category')} name="category" rules={[{ required: true }]}>
                <Select
                  showSearch
                  labelInValue={true}
                  fieldNames={{ label: 'name', value: 'id'}}
                  filterOption={(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  }
                  options={categories}
                  // onChange={onSelectedProduct}
                />
              </Form.Item>
            </Col>
            <Col xs={7} sm={3} md={3}>
              <Form.Item label=" ">
                <Button
                  block
                  icon={<PlusOutlined/>}
                  onClick={() => onAddCategory()}
                  style={{
                    background: "#808080",
                    color: "white",
                  }}
                  shape="round"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          rowKey="productId"
          size='small'
          dataSource={currentCategories}
          pagination={false}
          bordered
          columns={productTableColumns}
          scroll={{ y: 200 }}
        />
      </CreateModal>
    </>
  )
};

export default CreateEditModal;