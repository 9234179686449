import React, { useState } from 'react';
import axios from 'axios';
import { message, Button, Form, Input } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import CustomModal from 'components/modals/DisplayModal';
import UpdatePage from './update';
import { Loading } from 'utils';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 23,
    },
  },
};

const IndexPage = () => {

  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const values = await form.validateFields();
      const data = {
        name: values.name,
        nameEn: values.nameEn,
      }
      const response = await axios.post(`api/pages`, data)
      if (response.data.code === 200) { 
        setRefresh(true);
        setVisible(false);
        form.resetFields();
        message.success('This Page added in System.');    
      }
      else {     
        message.info('This Page not yet have in System.');
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    } finally {
      Loading.setLoading(false);
    }
  };


  return (
    <>
      <CustomModal
        visible={visible}
        title='Create Facebook Page'
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => onSubmit()}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="basic"
        >
          <Form.Item
            name="name"
            label='Name'
            rules={[
              {
                required: true,
                message: 'Please input name!',
              },
            ]}
          >
            <Input placeholder='Name'/>
          </Form.Item>
          <Form.Item
            name="nameEn"
            label='Name En'
            rules={[
              {
                required: true,
                message: 'Please input name en!',
              },
            ]}
          >
            <Input placeholder='Name En' />
          </Form.Item>
        </Form>
      </CustomModal>
      <PageHeader
        className="site-page-header"
        title='Facebook Page'
        extra={
          <Button
            onClick={() => {
              setVisible(true);
            }}
          >
            Create
          </Button>
        }
      />
      
      <UpdatePage onRefresh={refresh} />
    </>
  );
}
export default IndexPage;