import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Card, Form, Row, Col, DatePicker, Button, message, Select, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import CreateForm from './create';
import TableForm from './Table';
import CustomModal from 'components/modals/CustomModal';
import Toolbar from 'components/common/Toolbar';
import { paymentStatusList } from 'constants/status';
import { PAYMENT_METHOD_API_URL } from 'constants/config/api-url.constant';
import { defaultSearchParam, REVENUE_API, revenueTablePagination } from './constant';
import { Loading } from 'utils';

const { Option } = Select;

const IndexPage = () => {
  const { t, i18n } = useTranslation();
	const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [pagination, setPagination] = useState(revenueTablePagination);
  const modalFunc = useRef(null);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const getPayments = async (paginationParm) => {
    try {
      Loading.setLoading(true);
      const searchParam = {
        ...getSearchParam(),
        page: paginationParm && paginationParm.current
      };
      const response = await axios.post(`${REVENUE_API}/search`, searchParam);
      if (response && response.data) {
        setData(response.data && response.data.result);
        setTotalRevenue(response.data.options.totalRevenue);
        setPagination({
          ...paginationParm,
          total: response.data.options.total
        })
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      Loading.setLoading(false);
    }
  };

  const getSearchParam = () => {
    const data = searchForm.getFieldsValue(['paymentMethodId', 'status', 'date']);
    return {
      paymentMethodId: data.paymentMethodId ?? '',
      status: data.status ?? '',
      date: data.date ?? ''
    }
  }

  const getPaymentMethods = async () => {
    try {
      const response = await axios.get(PAYMENT_METHOD_API_URL);
      if (response && response.data) {
        setPaymentMethods(response.data && response.data.result);
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  const handleTableChange = async (pagination) => {
    setPagination(pagination);
    await getPayments(pagination);
  }

  const onReset = () => {
    searchForm.setFieldsValue(defaultSearchParam);
    getPayments(revenueTablePagination);
  }

  useEffect(() => {
    async function fetchData () {
      Loading.setLoading(true);
      await getPaymentMethods();
      await getPayments(pagination);
      Loading.setLoading(false);
    }
    fetchData()
  }, [])

  return (
    <>
      <PageHeader
        className='site-page-header'
        title={t('menu.revenue')}
      />
      <Card size="small">
        <Form
          form={searchForm}
        >
          <Row gutter={8}>
            <Col span={4}>
              <Form.Item
                name="date"
                style={{ margin: 0 }}
              >
                <DatePicker style={{ display: 'block' }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="paymentMethodId"
                style={{ margin: 0 }}
              >
                <Select
                  allowClear
                  placeholder="Payment Method"
                >
                  {paymentMethods.map((item, index) => (
                    <Option value={item.id} key={index}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="status"
                style={{ margin: 0 }}
              >
                <Select
                  allowClear
                  placeholder="Status"
                >
                  {paymentStatusList.map((item, index) => (
                    <Option value={item} key={index}>{item}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => getPayments(pagination)}
              >
                Search
              </Button>
              <Button
                style={{ marginLeft: '4px', marginRight: '4px' }}
                onClick={() => onReset()}
              >
                Reset
              </Button>
              <CustomModal
                title='Revenue'
                buttonName='Create'
                onCancel={modalFunc}
              >
                <CreateForm onLoadData={() => getPayments(pagination)} />
              </CustomModal>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card>
        <TableForm
          data={data}
          totalRevenue={totalRevenue}
          pagination={pagination}
          handleTableChange={handleTableChange}
          onLoadData={() => getPayments()}
        />
      </Card>
    </>
  );
}

export default IndexPage;