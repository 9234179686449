import React, { useState, useEffect } from "react";
import { Card, Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { Loading } from 'utils';
import CreateUserModal from "./components/modal/createUser";
import TableForm from './components/TableForm';

const UserPage = () => {
  const { t, i18n } = useTranslation();
  const [createUserModal, setCreateUserModal] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function fetch() {
      Loading.setLoading(true);
      await fetchData();
      Loading.setLoading(false);
    }
    fetch();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('api/users');
      if (response && response.data) {
        setUsers(response.data.result);
      }
    } catch (error) {
      console.log(error);
      setUsers([]);
    }
  }

  const onCreate = () => {
    setCreateUserModal(true);
  }

  const onSuccess = async () => {
    setCreateUserModal(false);
    await fetchData();
  }

  const onCloseModal = () => {
    setCreateUserModal(false);
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="User"
        extra={<Button onClick={() => onCreate()}>{t("action.create")}</Button>}
      />
      <TableForm
        data={users}
        onLoadData={() => fetchData()}
      />
      <CreateUserModal
        value={createUserModal}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  );
};

export default UserPage;
