export const defaultSearchParam = Object.freeze({
  date: null,
  paymentMethodId: null,
  status: null
});

export const STATUSES = [
  { key: 0, value: 'Paid', },
  { key: 1, value: 'PartlyPaid', },
  { key: 2, value: 'Unpaid', },
];

export const API = 'api/move-sale';
