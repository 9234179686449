import axios from "axios";
import { useEffect, useState } from "react";
import { PRODUCT_URL } from "constants/api";

export const useProducts = () => {
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    const response = await axios.get(`${PRODUCT_URL}/sale`);
    if (response.data.code === 200) {
      setProducts(response.data.result);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return { products };
};