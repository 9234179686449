import React, { useEffect, useState } from "react";
import { Button, Table, Space, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import AppTable from 'components/common/table/AppTable';
// import NumberFormat from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { ClosingBalanceStatus } from 'constants/status';
import { Loading } from 'utils';

const { Column } = Table;

const MonthlyClosingBalanceList = ({
  data,
  onView
}) => {
  const headers = [
    {
      title: 'code',
      dataIndex: 'code'
    },
    {
      title: 'codeFrom',
      dataIndex: 'codeFrom'
    },
    {
      title: 'codeTo',
      dataIndex: 'codeTo'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Total Revenue',
      dataIndex: 'revenue',
      align: 'right',
      render: (text, record) => (
        <b>
          <NumericFormat value={record.totalRevenue} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
        </b>
      )
    },
    {
      title: 'Expense',
      dataIndex: 'expense',
      align: 'right',
      render: (text, record) => (
        <b>
          <NumericFormat value={record.totalExpense} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
        </b>
      )
    },
    {
      title: 'Income',
      dataIndex: 'income',
      align: 'right',
      render: (text, record) => (
        <b>
          <NumericFormat value={record.income} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
        </b>
      )
    }
  ]

  const navigate = useNavigate();
  
  const [monthlyClosingBalanceList, setMonthlyClosingBalanceList] = useState([])
  const [enableStart, setEnableStart] = useState(null)

  const onViewClosingBalance = (item) => {
    // onView(item)
    navigate({
      pathname: `/closing-balance/monthly/${item.id}`
    });
  }

  useEffect(() => {
    if (data) {
      setMonthlyClosingBalanceList(data);
      findByStarted();
    }
  }, [data])

  const startClosingBalanceMonthly = async () => {
    try {
      Loading.setLoading(true);
      const response = await axios.post('/api/closing-balance-monthly');
      if (response && response.data && response.data.code === 200) {
        const result = response.data.result;
        if (result) {
          onViewClosingBalance(result);
        } else {
          message.error('Failed to calculate');
        }
      } else {
        message.error('Failed to calculate');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const findByStarted = async () => {
    try {
      const response = await axios.get(`api/closing-balance-monthly/find/by-started`);
      // Status code 400 = no drafting
      if (response && response.data && response.data.code === 400) {
        setEnableStart(true)
      } else {
        setEnableStart(false)
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <>
      <Button
        style={{
          margin: '0 8px 8px 0'
        }}
        type="primary"
        disabled={!enableStart}
        onClick={() => startClosingBalanceMonthly()}
      >
        Start
      </Button>
      <AppTable
        items={monthlyClosingBalanceList}
        headers={headers}
      >
        {{
          actions: 
          <Column
            title='Action'
            fixed="right"
            render={(item, record) => (
              <>
                <Space size="small">
                  <Button onClick={() => onViewClosingBalance(item)}>
                    {item.status === ClosingBalanceStatus.APPROVED ? 'View' : 'Drafting'}
                  </Button>
                </Space>
              </>
            )}
          ></Column>
        }}
      </AppTable>
    </>
  )
}

export default MonthlyClosingBalanceList;