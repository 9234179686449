import React, { useState } from 'react';
import { Table } from 'antd';
import { formatDate } from 'utils/index';
// import NumberFormat from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { defaultPagination } from 'constants/config/pagination';

const columns = [
  {
    title: 'Code',
    dataIndex: 'code'
  },
  {
    title: 'From Date',
    dataIndex: 'fromDate',
    render: (value) => {
      return formatDate(value)
    }
  },
  {
    title: 'To Date',
    dataIndex: 'toDate',
    render: (value) => {
      return formatDate(value)
    }
  },
  {
    title: 'Total Sale',
    dataIndex: 'totalSaleAmount',
    align: 'right',
    render: (text, record) => (
      <b>
        <NumericFormat value={record.totalSaleAmount} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
      </b>
    )
  },
  {
    title: 'Total Expense',
    dataIndex: 'totalExpense',
    align: 'right',
    render: (text, record) => (
      <b>
        <NumericFormat value={record.totalExpense} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
      </b>
    )
  },
  {
    title: 'Sale Unpaid',
    dataIndex: 'totalSaleUnpaid',
    align: 'right',
    render: (text, record) => (
      <b>
        <NumericFormat value={record.totalSaleUnpaid} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
      </b>
    )
  },
  {
    title: 'Sale Profit',
    dataIndex: 'totalSaleProfit',
    align: 'right',
    render: (text, record) => (
      <b>
        <NumericFormat value={record.totalSaleProfit} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
      </b>
    )
  },
  {
    title: 'Sale Expense',
    dataIndex: 'totalExpenseSale',
    align: 'right',
    render: (text, record) => (
      <b>
        <NumericFormat value={record.totalExpense} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
      </b>
    )
  },
  {
    title: 'Income',
    dataIndex: 'income',
    align: 'right',
    render: (text, record) => (
      <b>
        <NumericFormat value={record.income} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
      </b>
    )
  },
];

const MonthlyIncomePage = ({ data = [] }) => {
  const [currentPagination, setPagination] = useState(defaultPagination)
  const handleTableChange = (value) => {
    setPagination(value)
  }
  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={currentPagination}
        onChange={(e) => handleTableChange(e)}
      >
      </Table>
    </>
  )
}

export default MonthlyIncomePage;