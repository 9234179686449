import { Link } from "react-router-dom";
import i18next from 'i18next';

const BaseMenu = [
  {
    key: 'DASHBOARD',
    label: (<Link to='/'>{ i18next.t('menu.dashboard') }</Link>),
    icon: '',
  },
  {
    key: 'SALE_LIST',
    path: '/sale',
    label: (<Link to='/sale'>{ i18next.t('menu.sale') }</Link>),
    icon: ''
  },
  {
    key: 'SALE_LIST_ALL',
    path: '/sale-list',
    label: (<Link to='/sale-list'>{ i18next.t('menu.saleList') }</Link>),
    icon: ''
  },
  {
    key: 'REVENUE',
    path: '/revenue',
    label: (<Link to='/revenue'>{ i18next.t('menu.revenue') }</Link>),
    icon: ''
  },
  {
    key: 'MOVE_SALE',
    path: '/move-sale',
    label: (<Link to='/move-sale'>{ i18next.t('menu.moveSale') }</Link>),
    icon: ''
  },
  {
    key: 'SALEREP',
    path: '/sale-reps',
    label: (<Link to='/sale-reps'>{ i18next.t('menu.saleRep') }</Link>),
    icon: '',
  },
  // {
  //   key: 'EXPENSE',
  //   label: (<Link to='/expense'>{ i18next.t('menu.expense') }</Link>),
  //   icon: ''
  // },
];

const SettingMenu = [
  {
    key: 'PRODUCT',
    path: '/settings/product',
    label: (<Link to='/settings/product'>{ i18next.t('menu.product') }</Link>),
    icon: '',
  },  {
    key: 'CATEGORY',
    path: '/settings/category',
    label: (<Link to='/settings/category'>{ i18next.t('menu.category') }</Link>),
    icon: '',
  },
  {
    key: 'DELIVERY',
    path: '/settings/delivery',
    label: (<Link to='/settings/delivery'>{ i18next.t('menu.delivery') }</Link>),
    icon: '',
  },
  {
    key: 'CUSTOMER',
    path: '/settings/customers',
    label: (<Link to='/settings/customers'>{ i18next.t('menu.customer') }</Link>),
    icon: '',
  },
  // {
  //   key: 'FACEBOOK_PAGE',
  //   path: '/settings/facebookpages',
  //   label: (<Link to='/settings/facebookpages'>Facebook Page</Link>),
  //   icon: '',
  // },
  {
    key: 'EXPENSE_TYPE',
    path: '/settings/expensetype',
    label: (<Link to='/settings/expensetype'>{ i18next.t('menu.expenseType') }</Link>),
    icon: '',
  },
  {
    key: 'PAYMENT_METHOD',
    path: '/settings/payment-method',
    label: (<Link to='/settings/payment-method'>{ i18next.t('menu.paymentMethod') }</Link>),
    icon: '',
  },
  {
    key: 'PROVINCE',
    path: '/settings/province',
    label: (<Link to='/settings/province'>{ i18next.t('menu.province') }</Link>),
    icon: '',
  },
  {
    key: 'DISTRICT',
    path: '/settings/district',
    label: (<Link to='/settings/district'>{ i18next.t('menu.district') }</Link>),
    icon: '',
  },
  // {
  //   key: 'ASSET',
  //   path: '/settings/asset',
  //   label: (<Link to='/settings/asset'>Asset</Link>),
  //   icon: '',
  // },
  // {
  //   key: 'REPORT',
  //   path: '/organization-report',
  //   label: (<Link to='/organization-report'>Report</Link>),
  //   icon: ''
  // },
];

const InventoryMenu = [
  {
    key: 'STOCK',
    path: '/inventory/stock',
    label: (<Link to='/inventory/stock'>{i18next.t('menu.stock')}</Link>),
    icon: '',
  },
  {
    key: 'PURCHASE_ORDER',
    path: '/inventory/purchase-order',
    label: (<Link to='/inventory/sale-rep-order'>Sale Rep Order</Link>),
    icon: '',
  },
  // {
  //   key: 'INVENTORY_AJUSTMENT',
  //   path: '/inventory/adjustment',
  //   label: (<Link to='/inventory/adjustment'>Inventory Adjustment</Link>),
  //   icon: '',
  // },
  // {
  //   key: 'SUPPLIER',
  //   path: '/inventory/supplier',
  //   label: (<Link to='/inventory/supplier'>Supplier</Link>),
  //   icon: '',
  // }
];

const InventoryMainMenu = {
  key: 'Inventory',
  path: '/inventory',
  label: (<Link to='/inventory/stock'>{ i18next.t('menu.inventory') }</Link>),
  icon: ''
}

const MobileInventory = {
  key: 'Inventory',
  path: '/inventory',
  label: (<Link to='/inventory/stock'>{ i18next.t('menu.inventory') }</Link>),
  icon: '',
  children: InventoryMenu
}

const PurchaseOrderMainMenu = {
  key: 'PURCHASE_ORDER',
  path: '/purchase-order',
  label: (<Link to='/purchase-order/customer'>Purchase Order</Link>),
  icon: '',
};

const PurchaseOrderMenu = [
  {
    key: 'PURCHASE_ORDER',
    path: '/purchase-order',
    label: (<Link to='/purchase-order/customer'>Customer</Link>),
    icon: '',
  },
];

const MobileConfigurationMenu = {
  key: 'CONFIGURATION',
  path: '/settings',
  label: (<Link to='/settings/product'>{ i18next.t('menu.setting') }</Link>),
  icon: '',
  children: SettingMenu
};

const ConfigurationMenu = {
  key: 'CONFIGURATION',
  path: '/settings',
  label: (<Link to='/settings/product'>{ i18next.t('menu.setting') }</Link>),
  icon: '',
};

// const AccountSettingsMenu = {
//   key: 'ACCOUNT_SETTING',
//   label: `${ i18next.t('menu.accountSetting') }`,
//   icon: '',
//   children: [
//     {
//       key: 'USER',
//       path: '/user',
//       label: (<Link to='/user'>User</Link>),
//       icon: '',
//     },
//     {
//       key: 'CHANGE_PASSWORD',
//       path: '/changePassword',
//       label: (<Link to='/changePassword'>Change Password</Link>),
//       icon: '',
//     }
//   ]
// };

const AccountSettingsMenu = {
  key: 'CHANGE_PASSWORD',
  path: '/change-password',
  label: (<Link to='/change-password'>{i18next.t('menu.changePassword')}</Link>),
  icon: '',
}

const UserBaseMenu = [
  {
    key: 'DASHBOARD',
    label: (<Link to='/'>{ i18next.t('menu.dashboard') }</Link>),
    icon: '',
  },
  {
    key: 'SALE_LIST',
    path: '/sale',
    label: (<Link to='/sale'>{ i18next.t('menu.sale') }</Link>),
    icon: ''
  },
  {
    key: 'SALE_LIST_ALL',
    path: '/sale-list',
    label: (<Link to='/sale-list'>{ i18next.t('menu.saleList') }</Link>),
    icon: ''
  },
  {
    key: 'CUSTOMER',
    path: '/customers',
    label: (<Link to='/customers'>{ i18next.t('menu.customer') }</Link>),
    icon: '',
  },
  {
    key: 'DELIVERY',
    path: '/delivery',
    label: (<Link to='/delivery'>{ i18next.t('menu.delivery') }</Link>),
    icon: '',
  },
];

const UserAccountMenu = {
  key: 'USER',
  path: '/user',
  label: (<Link to='/user'>{ i18next.t('menu.user') }</Link>),
  icon: ''
};


const LogoutMenu = {
  key: 'LOGOUT',
  path: '/login',
  label: (<Link to='/login'>{ i18next.t('menu.logout') }</Link>),
  icon: ''
};

// User menu
const StandardUserMenu = [
  ...UserBaseMenu,
  AccountSettingsMenu,
  LogoutMenu
];

const MobileUserMenu = [
  ...UserBaseMenu,
  AccountSettingsMenu,
  LogoutMenu
];

// Print only menu
const PrintOnlyUserMenu = [
  ...UserBaseMenu.filter(f => ['SALE_LIST_ALL'].some(s => f.key === s)),
  AccountSettingsMenu,
  LogoutMenu
];

const PrintOnlyMobileMenu = [
  ...UserBaseMenu.filter(f => ['SALE_LIST_ALL'].some(s => f.key === s)),
  AccountSettingsMenu,
  LogoutMenu
];


// Admin menu
const AdminMenu = [
  ...BaseMenu,
  ConfigurationMenu,
  InventoryMainMenu,
  // PurchaseOrderMainMenu,
  UserAccountMenu,
  AccountSettingsMenu,
  LogoutMenu
];

const MobileMenu = [
  ...BaseMenu,
  MobileConfigurationMenu,
  MobileInventory,
  AccountSettingsMenu,
  LogoutMenu
];

export {
  AdminMenu,
  StandardUserMenu,
  MobileMenu,
  MobileUserMenu,
  InventoryMenu,
  PurchaseOrderMenu,
  SettingMenu,
  PrintOnlyUserMenu,
  PrintOnlyMobileMenu
}