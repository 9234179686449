import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Card,
  Divider,
  Table,
  Tag,
  Space,
  Button,
  Modal,
  Statistic,
  Row,
  Col,
} from "antd";
import { ExclamationCircleFilled, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PostInfo, MoveSaleStatus } from "../components/index";
import { Riel } from "components/special";
import { Loading } from "utils";
import CountUp from "react-countup";
import { ClosingBalanceStatus } from "constants/status";
import AddSaleModal from "./modal/AddSale";
import ViewSaleDetail from "./modal/ViewSaleDetail";

const { confirm } = Modal;
const formatter = (value) => <CountUp end={value} separator="," />;

const MoveSaleDetailPage = ({}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [confirmModel, setConfirmModel] = useState(false);
  const [moveSale, setMoveSale] = useState({});
  const [moveSaleDetail, setMoveSaleDetail] = useState([]);
  const [newSaleIds, setNewSaleIds] = useState([]);
  const [options, setOptions] = useState({
    records: 0,
    totalAmount: 0,
    totalWholesaleAmount: 0,
  });

  const [otherPrice, setOtherPrice] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const columns = [
    {
      title: "№",
      dataIndex: "code",
    },
    {
      title: "កាលបរិច្ឆេទ",
      dataIndex: "date",
      responsive: ["xs", "sm"],
      render: (text, record) => (
        <div>{moment(record.date).format("YY-MM-DD")}</div>
      ),
    },
    {
      title: "តំណាងចែងចាយ",
      dataIndex: "saleRepId",
      key: "saleRepId",
      render: (text, { saleRepId }) => <div>{saleRepId && saleRepId.name}</div>,
      responsive: ["sm"],
    },
    {
      title: "លេខលំដាប់",
      dataIndex: "invoiceSequence",
    },
    {
      title: t("label.moveSaleStatus"),
      dataIndex: "moveSaleStatus",
      render: (sale, record) => (
        <>
          <MoveSaleStatus status={record.moveSaleStatus} />
        </>
      ),
    },
    {
      title: t("label.status"),
      dataIndex: "product",
      responsive: ["sm"],
      render: (sale, record) => (
        <div>
          {record.printCount > 0 ? (
            <Tag>{t("label.isPrinted")}</Tag>
          ) : (
            <Tag color="#f5222d">{t("label.isNotPrinted")}</Tag>
          )}
        </div>
      ),
    },
    {
      title: t("label.address"),
      dataIndex: "address",
      responsive: ["sm"],
    },
    {
      title: "តម្លៃក្រុមហ៊ុន",
      dataIndex: "totalWholesale",
      align: "right",
      responsive: ["xs", "sm"],
      render: (sale, record) => (
        <b>
          <Riel value={record.totalWholesale} />
        </b>
      ),
    },
    {
      title: "តម្លៃសរុបរួម",
      dataIndex: "totalAmount",
      responsive: ["xs", "sm"],
      align: "right",
      render: (sale, record) => (
        <b>
          <Riel value={record.totalAmount} />
        </b>
      ),
    },
    moveSale.status !== ClosingBalanceStatus.APPROVED
      ? {
          title: t("label.action"),
          align: "right",
          render: (text, record) => (
            <>
              <Space size="small">
                <Button
                  onClick={() => onViewAndUpdate(record)}
                  icon={<EyeOutlined />}
                />
                <Button onClick={() => onDelete(record)} danger>
                  {t("action.delete")}
                </Button>{" "}
              </Space>
            </>
          ),
        }
      : {
          title: t("action.operation"),
          key: "_id",
          fixed: "right",
          responsive: ["sm"],
          render: (sale, record) => (
            <Space size="small">
              <Button
                onClick={() => onViewAndUpdate(record)}
                icon={<EyeOutlined />}
              />
            </Space>
          ),
        },
    ,
  ];

  useEffect(() => {
    if (id) {
      getMoveSaleDetail(id);
    }
  }, [id]);

  const getData = (otherPrice, total) => {
    setOtherPrice(otherPrice);
    setGrandTotal(total);
  };

  const getMoveSaleDetail = async (id) => {
    try {
      const response = await axios.get(`api/sales/move-sale/detail/${id}`);
      const data = response.data;
      setMoveSale(data.result.moveSale);
      setMoveSaleDetail(data.result.moveSaleDetail);
      setOptions({
        records: data.result.moveSaleDetail.length,
        totalAmount: data.options.totalAmount,
        totalWholesaleAmount: data.options.totalWholesale,
      });
    } catch (error) {
      setMoveSaleDetail(null);
    }
  };

  const onDelete = async (value) => {
    Loading.setLoading(true);
    const obj = {
      saleId: value.id,
    };
    const response = await axios.put(`api/sales/move-sale/delete/${id}`, obj);
    const data = response.data;
    if (data.code === 200) {
      await getMoveSaleDetail(id);
      Loading.setLoading(false);
    }
  };

  const onClosingInvoices = async () => {
    confirm({
      title: "តើអ្នកចង់បិទបញ្ចី?",
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          <Divider />
          <Row gutter={16}>
            <Col span={24}>
              <Statistic
                title={t("label.totalSale")}
                value={options.records}
                precision={2}
                formatter={formatter}
              />
            </Col>
            <Col span={24}>
              <Statistic
                title={t("label.totalSaleAmount")}
                value={options.totalAmount}
                formatter={formatter}
              />
            </Col>
            <Col span={24}>
              <Statistic
                title={t("label.totalWholesaleAmount")}
                value={options.totalWholesaleAmount}
                precision={2}
                valueStyle={{ color: "#3f8600", fontWeight: "bold" }}
                formatter={formatter}
              />
            </Col>
            <Col span={24}>
              <Statistic
                title="សរុប"
                value={grandTotal}
                precision={2}
                valueStyle={{ color: "#3f8600", fontWeight: "bold" }}
                formatter={formatter}
              />
            </Col>
          </Row>
        </>
      ),
      async onOk() {
        if (moveSaleDetail.length > 0) {
          const obj = {
            saleIds: moveSaleDetail.map((ele) => ele.id),
            newSaleIds,
            otherPrice,
            grandTotal,
          };
          const response = await axios.put(
            `api/sales/move-sale/closing/${id}`,
            obj
          );
          const data = response.data;
          if (data.code === 200) {
            navigate({
              pathname: `/move-sale`,
            });
          }
        }
      },
      onCancel() {},
    });
  };

  const onAddSales = async () => {
    setConfirmModel(true);
  };

  const onUpdateSuccess = async (values) => {
    console.log("On Success", values);
    Loading.setLoading(true);
    const obj = {
      saleIds: values,
    };
    const response = await axios.put(`api/sales/move-sale/update/${id}`, obj);
    const data = response.data;
    if (data.code === 200) {
      await getMoveSaleDetail(id);
      Loading.setLoading(false);
      setConfirmModel(false);
    }
  };

  const onCancelAddSale = () => {
    setConfirmModel(false);
  };

  const [openViewAndUpdateProduct, setOpenViewAndUpdateProduct] =
    useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const onViewAndUpdate = (item) => {
    setCurrentItem(item);
    setOpenViewAndUpdateProduct(true);
  };

  const onCancelViewSaleDetail = () => {
    setOpenViewAndUpdateProduct(false);
    setCurrentItem(null);
  };

  return (
    <>
      <Card
        title={<Link to="/move-sale">{t("menu.moveSale")}</Link>}
        extra={
          moveSale.status !== ClosingBalanceStatus.APPROVED && (
            <Space>
              <Button onClick={() => onAddSales()}>{t("action.add")}</Button>
              <Button type="primary" onClick={() => onClosingInvoices()}>
                {t("action.closingInvoice")}
              </Button>
            </Space>
          )
        }
      >
        <PostInfo
          status={moveSale.status}
          saleRep={moveSale.description}
          totalSales={options.records}
          totalSaleAmount={options.totalAmount}
          totalWholesaleAmount={options.totalWholesaleAmount}
          datePosted={moveSale.datePosted}
          otherPrice={moveSale.otherPrice}
          grandTotal={moveSale.grandTotal}
          getData={getData}
        />
        <Divider />

        <Table
          rowKey="_id"
          size="small"
          dataSource={moveSaleDetail}
          columns={columns.filter((x) => x !== null)}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={7} align="right">
                    ប្រាក់សរុប
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <b>
                      <Riel value={options.totalWholesaleAmount} />
                    </b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <b>
                      <Riel value={options.totalAmount} />
                    </b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />

        <AddSaleModal
          open={confirmModel}
          onSuccess={onUpdateSuccess}
          onCancel={onCancelAddSale}
        />

        <ViewSaleDetail
          item={currentItem}
          open={openViewAndUpdateProduct}
          onCancel={() => onCancelViewSaleDetail()}
          onSuccess={() => onUpdateSuccess()}
        />
      </Card>
    </>
  );
};

export default MoveSaleDetailPage;
