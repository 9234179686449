import React, { useEffect } from 'react'
import AppModal from 'components/common/modal/AppModal';
import { Form, Input, message } from 'antd';
import axios from 'axios';
import { Loading } from 'utils';

const CreateEditPaymentMethod = ({
  visible = false,
  currentItem = null,
  onSuccess,
  onCancel
}) => {

  const [form] = Form.useForm();

  const handleSubmit = () => {
    if (currentItem) {
      updatePaymentMethod(currentItem.id);
    } else {
      createPaymentMethod();
    }
  }

  const createPaymentMethod = async () => {
    try {
      Loading.setLoading(true);
      const data = {
        name: form.getFieldValue('name'),
        nameEn: form.getFieldValue('nameEn')
      }
      const response = await axios.post('api/payment-method', data)
      if (response.data.code === 200) {
        form.resetFields();
        onSuccess();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const updatePaymentMethod = async (id) => {
    try {
      Loading.setLoading(true);
      const data = {
        name: form.getFieldValue('name'),
        nameEn: form.getFieldValue('nameEn')
      }
      const response = await axios.put(`api/payment-method/${id}`, data)
      if (response.data.code === 200) {
        form.resetFields();
        onSuccess();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const close = () => {
    onCancel();
    form.resetFields();
  }

  useEffect(() => {
    if (currentItem) {
      form.setFieldsValue({
        name: currentItem.name,
        nameEn: currentItem.nameEn
      })
    }
  }, [currentItem]);
  
  return (
    <>
      <AppModal
        visible={visible}
        onOk={() => handleSubmit()}
        onCancel={close}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          form={form}
        >
          <Form.Item
            label='Description'
            name="name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Description En'
            name="nameEn"
          >
            <Input />
          </Form.Item>
        </Form>
      </AppModal>
    </>
  )
}

export default CreateEditPaymentMethod;