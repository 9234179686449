import React, { useState } from 'react';
import { message, Button, Space } from 'antd';
import AppTable from 'components/common/table/AppTable';
import Confirm from 'components/modals/Confirm';
import { Loading } from 'utils';
import CreateEditModal from './createEditModal';
import { useTranslation } from 'react-i18next';

const TableForm = ({
  data,
  onLoadData
}) => {
  const { t, i18n } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const headers = [
    {
      title: t('label.name'),
      dataIndex: 'name',
    },
    {
      title: t('action.action'),
      dataIndex: 'action',
      key: 'action',
      fixed:"right",
      render:(text, record) => (
        <Space>
          <Button
            onClick={() => onEdit(record)}
          >
            Edit
          </Button>
          <Button
            disabled
            onClick={() => onDelete(record)}
          >
            Delete
          </Button>
        </Space>
      )
    }
  ]

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
  }

  const onDelete = (item) => {
    Confirm({
      title: "Confirm Delete",
      okType: 'warning',
      async onOk () {
        try {
          Loading.setLoading(true);
          // TODO: Implement delete method
        } catch (error) {
          message.error(error.message);
        } finally {
          Loading.setLoading(false);
        }
      },
      onCancel () {}
    })
  }

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setEditModal(false);
  }

  const onCloseModal = () => {
    setCurrentItem(null);
    setEditModal(false);
  }

  return (
    <>
      <AppTable
        items={data}
        headers={headers}
        disableAction
      />
      <CreateEditModal
        value={editModal}
        item={currentItem}
        isEdit
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  )
}

export default TableForm;