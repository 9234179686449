import React from 'react';
import { Modal, Button } from 'antd';

const AppModal = ({
  visible = false,
  closable = false,
  title = '',
  onOk,
  onCancel,
  footer,
  saveButtonText = 'Save',
  cancelButtonText = 'Cancel',
  children
}) => {

  const handleOk = () => {
    onOk()
  }

  const handleCancel = () => {
    onCancel()
  }

  return (
    <>
      <Modal
        title={title ?? ''}
        closable={closable}
        open={visible}
        footer={
          footer ?? (
            <>
              <Button onClick={() => handleCancel()}>{cancelButtonText}</Button>
              <Button type='primary' onClick={() => handleOk()}>{saveButtonText}</Button>
            </>
          )
        }
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {children}
      </Modal>
    </>
  )
}

export default AppModal;