import React, { useState } from 'react';
import { Modal, Button, Spin } from 'antd';

const CustomModal = ({
  visible = false,
  isLoading = false,
  title = '',
  children,
  saveButtonText = 'Save',
  cancelButtonText = 'Cancel',
  width = 700,
  footer,
  onCancel,
  onOk,
  disableOk = false
}) => {

  const handleCancel = e => {
    onCancel();
  };

  const handleOk = e => {
    onOk();
  };

  return (
    <Modal
      getContainer={false}
      forceRender
      width={width}
      zIndex={2}
      title={
        <div>
          { title }
        </div>
      }
      open={visible}
      cancelText="Cancel"
      onCancel={onCancel}
      footer={[
        footer ?? (
          <>
            <Button onClick={() => handleCancel()}>{cancelButtonText}</Button>
            <Button disabled={disableOk} type='primary' onClick={() => handleOk()}>{saveButtonText}</Button>
          </>
        )
      ]}
    >
      <Spin spinning={isLoading}>
        { children }
      </Spin>
    </Modal>
  );
}

export default CustomModal;