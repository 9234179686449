import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, message, Input, Row, Col, InputNumber, Table, Checkbox  } from 'antd';
import { Loading, formatDate } from 'utils';
import { SALE_REP_URL } from 'constants/api';
import { useTranslation } from 'react-i18next';
import { CreateModal } from "components/modals/index";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const ResetSeqModel = ({
  value = false,
  item = null,
  onSuccess,
  onCancel
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const [sequences, setSequences] = useState([]);

  const tableColumns = [
    {
      title: t('label.date'),
      dataIndex: 'date',
      align: "center",
      render: (value) => {
        return formatDate(value)
      }
    },
    {
      title: t('label.sequence'),
      align: "center",
      dataIndex: 'seq',
    },
    {
      title: t('label.active'),
      dataIndex: 'isActive',
      align: "center",
      render: (value) => {
        return <Checkbox checked={value}></Checkbox>
      }
    },
  ];

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        saleRepId: item.id,
        seq: formValue.seq,
      }
      const response = await axios.post(`${SALE_REP_URL}/reset/seq`, data);
      if (response.data.code === 200) {
        form.resetFields();
        onSuccess();
        message.success('This data added in System.');
      }
      else {
        message.info('Failed');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        seq: item.seq
      });
      setSequences(item.sequences);
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={t('label.resetSequence')}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <br />
        <Form
          layout='horizontal'
          form={form}
          validateMessages={validateMessages}
        >
          <Row gutter={8}>
            <Col xs={12} sm={12} md={12}>
              <Form.Item label='លេខលំដាប់' name="seq" rules={[{ required: true }]}>
                <InputNumber
                  placeholder='0'
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Table
            rowKey="productId"
            size='small'
            dataSource={sequences}
            pagination={false}
            bordered
            columns={tableColumns}
            scroll={{ y: 200 }}
          />
        </Form>
      </CreateModal>
    </>
  )
};

export default ResetSeqModel;