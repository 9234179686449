const TEXT = {
  text: 'Text',
  value: 'Text'
}

const IMAGE = {
  text: 'Image',
  value: 'Image'
}

export const ReportSettingType = Object.freeze([
  TEXT,
  IMAGE
])