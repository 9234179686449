import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Table, Space } from 'antd';
import AppTable from 'components/common/table/AppTable';
import axios from 'axios';
import Edit from './edit';

const OrganizationReport = () => {
  const defaultPagination = {
    current: 1,
    pageSize: 10,
  }
  const tableHeaders = [
    { 
      title: 'Report Name',
      render: (item, record) => (
        <>
          { record.reportId.name }
        </>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: "right",
      render: (item, record) => (
        <>
          <Space size="small">
            <Button
              onClick={() => onEditOrganizationReport(record)}
            >
              Edit
            </Button>
          </Space>
        </>
      )
    }
  ]

  const [organizationReports, setOrganizationReport] = useState([])
  const [pagination, setPagination] = useState(defaultPagination)
  const [editModal, setEditModal] = useState(false)
  const [selectedOrganizationReport, setSelectedOrganizationReport] = useState(null)

  const tableChange = (value) => {
    const pagination = {
      current: value.current,
      pageSize: value.pageSize
    }
    setPagination(pagination)
  }

  const getData = async () => {
    // Get by user/company
    const companyId = JSON.parse(localStorage.getItem('user')).companyId;
    const response = (await axios.get('api/users/organization-report', { companyId })).data
    if (response.result) {
      // avoid console props key error
      const result = response.result.map((item, index) => {
        item.key = index
        return item
      })
      setOrganizationReport(result)
    }
  } 

  const onEditOrganizationReport = (value) => {
    setSelectedOrganizationReport(value)
    setEditModal(true)
  }

  const onEditSuccess = async () => {
    await getData()
    setEditModal(false)
    setSelectedOrganizationReport(null)
  }

  const onEditCancel = () => {
    setEditModal(false)
    setSelectedOrganizationReport(null)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Card>
        <Row>
          <Col span={24}>
            <AppTable
              headers={tableHeaders}
              items={organizationReports}
              pagination={pagination}
              onChange={tableChange}
              disableAction
            />
          </Col>
        </Row>
        <Edit
          visible={editModal}
          item={selectedOrganizationReport}
          onOk={() => onEditSuccess()}
          onCancel={() => onEditCancel()}
        />
      </Card>
    </>
  )
}

export default OrganizationReport;