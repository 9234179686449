import React, { useState, useEffect, useCallback } from 'react';
import { Button, Row, Col, Card, Table, Space } from 'antd';
import {
  // useLocation,
  useParams
} from "react-router-dom";
import axios from 'axios';
import AppTable from 'components/common/table/AppTable';
import CreateEditOrganization from './createEdit';

const { Column } = Table;

const OrganizationPage = ({ company, onCancel }) => {
  const defaultPagination = {
    current: 1,
    pageSize: 10,
  }
  const tableHeaders = [
    { title: 'Code', dataIndex: 'code' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Address', dataIndex: 'address' }
  ]
  const [pagination, setPagination] = useState(defaultPagination)
  const [organizations, setOrganization] = useState([])
  const [dialog, setDialog] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState(null)

  // const location = useLocation();
  const { id } = useParams();

  const tableChange = (value) => {
    const pagination = {
      current: value.current,
      pageSize: value.pageSize
    }
    setPagination(pagination)
  }

  const onOpen = () => {
    setDialog(true)
  }

  const onClose = () => {
    setDialog(false)
    setIsEdit(false)
  }

  const onOk = async () => {
    setDialog(false)
    await getOrganization(id)
  }

  const handleEditOrganization = async (item) => {
    setSelectedOrganization(item)
    await setIsEdit(true)
    setDialog(true)
  }

  // const create = async (data) => {
  //   try {
  //     const response = await axios.post('api/organization', data)
  //     if (response.data.code === 200) {
  //       message.success('Added Successful')
  //       await getOrganization()
  //     } else {
  //       message.error(response.data.message.message)
  //     }
  //   } catch (error) {
  //     message.error('Error')
  //   }
  // }

  // const update = async (data) => {
  //   try {
  //     const response = await axios.put(`api/organization/${data.id}`, data)
  //     if (response.data.code === 200) {
  //       message.success('Update Successful')
  //       await getOrganization()
  //     } else {
  //       message.error(response.data.message.message)
  //     }
  //   } catch (error) {
  //     message.error('Error')
  //   }
  // }

  const getOrganization = useCallback(async (id) => {
    if (id) {
      const response = (await axios.get(`api/organization/by-company/${id}`)).data
      if (response.result) {
        // avoid console props key error
        const result = response.result.map((item, index) => {
          item.key = index
          return item
        })
        setOrganization(result)
      }
    }
  }, [])

  useEffect(() => {
    if (id) {
      getOrganization(id)
    }
  }, [id, getOrganization])

  return (
    <>
      <Card
        title='Organization'
        extra={<Button onClick={onOpen}>Create</Button>}
      >
        <Row>
          <Col span={24}>
            <CreateEditOrganization
              id={id}
              item={selectedOrganization}
              visible={dialog}
              isEdit={isEdit}
              onCancel={onClose}
              onOk={onOk}
            />
            <AppTable
              bordered="true"
              scroll={{ x: 1000, y: 400 }}
              headers={tableHeaders}
              items={organizations}
              pagination={pagination}
              onChange={tableChange}
            >
              {{
                actions: 
                <Column
                  title='Action'
                  fixed="right"
                  render={(item, record) => (
                    <>
                      <Space size="small">
                        <Button onClick={() => handleEditOrganization(item)}>Edit</Button>
                      </Space>
                    </>
                  )}
                ></Column>
              }}
            </AppTable>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default OrganizationPage;