import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { message, Button, Form, Input, DatePicker, Select, Tag } from 'antd';
import { Loading } from 'utils';

const { Option } = Select;

const config = {
  rules: [
    {
      required: true,
      message: 'Please select time!',
    },
  ],
};

const ExpensePage = ({ onLoadData }) => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  // const [statuses] = useState([
  //   'Pending', 'Approved', 'Declined'
  // ]);

  useEffect(() => {
    async function fetchData () {
      Loading.setLoading(true);
      await onLoadUsers();
      await onLoadExpense();
      Loading.setLoading(false);
    }
    fetchData
  }, []);

  const onLoadUsers = async () => {
    const response = await axios.get(`api/users`)
    if (response.data.code === 200) {
      setUsers(response.data.result);
    } else {
      message.info('This sale not yet have in System.');
    }
  };

  const onLoadExpense = async () => {
    const response = await axios.get(`api/expense-type?type=Expense`)
    if (response.data.code === 200) {
      setExpenseTypes(response.data.result);
    } else {
      message.info('This sale not yet have in System.');
    }
  }

  const tagRender = (props) => {
    console.log(props);
    const { label, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const onFinish = async (values) => {
    try {
      Loading.setLoading(true);
      const tmpExpense = expenseTypes.find(tmp => tmp.id === values.expenseType)
      console.log('value', values);
      const data = {
        date: moment(values.date).local(),
        expenseTypeId: tmpExpense.id,
        expenseTypeName: tmpExpense.name,
        // status: values.status,
        status: 'Approved',
        approvals: values.approvals.map((item) => {
          let container = {};
          container['user'] = item;
          return container;
        }),
        amount: values.amount,
        note: values.note,
      }
      const response = await axios.post(`api/expense`, data)
      if (response.data.code === 200) {
        form.resetFields();
        onLoadData();
        message.success('This data added in System.');
      }
      else {     
        message.info('This data not yet have in System.');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
      initialValues={{
        date: moment(),
      }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
        <Form.Item name="date" label='Date' {...config}>        
          <DatePicker />
        </Form.Item>

          <Form.Item
            name="expenseType"
            label='Expense Type'
            rules={[
              {
                required: true,
                message: 'Please input delivery!',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Expense Type'
            >
              {expenseTypes.map(option=> (
                <Option key={option.id}>{option.description}</Option>
              ))}
            </Select>
          </Form.Item>

          {/* Auto approved, Hide input for now */}
          {/* <Form.Item
            name="status"
            label="Status"
            initialValue={statuses[1]}
            rules={[
              {
                required: true,
                message: 'Please input status!',
              },
            ]}
          >
            <Select
              defaultValue={statuses[1]}
              showSearch
              defaultActiveFirstOption={true}
              placeholder={i18n.t('menu.expenseType')}
            >
              {statuses.map(option=> (
                <Option key={option}>{option}</Option>
              ))}
            </Select>
          </Form.Item> */}

          <Form.Item
            name="approvals"
            label="Approval"
            rules={[
              {
                required: true,
                message: 'Please input status!',
              },
            ]}
          >
            <Select
              mode="multiple"
              showArrow
              tagRender={tagRender}
              // defaultValue={statuses[1]}
              placeholder="Approval"
            >
              {users.map(option=> (
                <Option key={option.id}>{option.username}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: 'Please input other phone!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="note"
            label='Note'
            rules={[
              {
                required: true
              },
            ]}
          >
            <Input />
          </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ExpensePage
