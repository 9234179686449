import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Form, message, Select, Row, Col, DatePicker } from 'antd';
import axios from 'axios';
import TableForm from './Table';
import Toolbar from 'components/common/Toolbar';
import CreateEditExpenseModal from './createEdit';
import { expenseStatusList } from 'constants/status';
import { EXPENSE_TYPE } from 'constants/config/api-url.constant'
import { expenseTablePagination, defaultSearchParam, EXPENSE_API_URL, expensePeriod} from './expense.constant';
import { Loading } from 'utils';

const { Option } = Select;

const IndexPage = () => {
	const [searchForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [pagination, setPagination] = useState(expenseTablePagination);
  const [createModal, setCreateModal] = useState(false);
  const modalFunc = useRef(null);
  const [expenseTypes, setExpenseType] = useState([]);

  const getExpenseList = async (paginationParm) => {
    try {
      Loading.setLoading(true);
      const searchParam = {
        ...getSearchParam(),
        page: paginationParm && paginationParm.current
      };
      const response = await axios.post(`${EXPENSE_API_URL}/search`, searchParam);
      if (response && response.data) {
        setData(response.data && response.data.result);
        setTotalExpense(response.data.options.totalExpense);
        setPagination({
          ...paginationParm,
          total: response.data.options.total
        })
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      Loading.setLoading(false);
    }
  }

  const getSearchParam = () => {
    const data = searchForm.getFieldsValue(['expensePeriod', 'expenseTypeId', 'status', 'date'])
    return {
      expensePeriod: data.expensePeriod ?? '',
      expenseTypeId: data.expenseTypeId ?? '',
      status: data.status ?? '',
      date: data.date ?? ''
    }
  }

  const getExpenseType = async () => {
    try {
      const response = await axios.get(EXPENSE_TYPE)
      if (response && response.data) {
        setExpenseType(response.data && response.data.result)
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  const onCreate = () => {
    setCreateModal(true);
  }

  const handleTableChange = async (pagination) => {
    setPagination(pagination);
    await getExpenseList(pagination);
  }

  const onReset = async () => {
    searchForm.setFieldsValue(defaultSearchParam)
    getExpenseList(expenseTablePagination);
  }

  const onCloseModal = () => {
    setCreateModal(false)
  }

  useEffect(() => {
    async function fetch () {
      Loading.setLoading(true);
      await getExpenseType();
      await getExpenseList(pagination);
      Loading.setLoading(false);
    }
    fetch()
  }, [])

  return (
    <>
      <Card>
        <Form
          form={searchForm}
        >
          <Row gutter={4}>
            <Col span={4}>
              <Form.Item
                name="date"
                style={{ margin: 0 }}
              >
                <DatePicker style={{ display: 'block' }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="expenseTypeId"
                style={{ margin: 0 }}
              >
                <Select
                  allowClear
                  placeholder="Expense Type"
                >
                  {expenseTypes.map((item, index) => (
                    <Option value={item.id} key={index}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="expensePeriod"
                style={{ margin: 0 }}
              >
                <Select
                  allowClear
                  placeholder="Expense Period"
                >
                  {expensePeriod.map((item, index) => (
                    <Option value={item.value} key={index}>{item.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="status"
                style={{ margin: 0 }}
              >
                <Select
                  allowClear
                  placeholder="Status"
                >
                  {expenseStatusList.map((item, index) => (
                    <Option value={item} key={index}>{item}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Button
              type="primary"
              onClick={() => getExpenseList(pagination)}
            >
              Search
            </Button>
            <Button
              style={{ marginLeft: '2px' }}
              onClick={() => onReset()}
            >
              Reset
            </Button>
          </Row>
        </Form>
      </Card>
      <Card>
        <Toolbar
          title="Expense"
          extra={
            <Button onClick={() => onCreate()}>Create</Button>
            // <CustomModal
            //   title='Expense'
            //   buttonName='Create'
            //   onCancel={modalFunc}
            // >
            //   <CreateForm onLoadData={() => getExpenseList(pagination)} />
            // </CustomModal>
          }
        />
        <TableForm
          data={data}
          totalExpense={totalExpense}
          pagination={pagination}
          handleTableChange={handleTableChange}
          onLoadData={() => getExpenseList()}
        />
        <CreateEditExpenseModal
          value={createModal}
          onLoadData={() => getExpenseList()}
          onCancel={() => onCloseModal()}
        />
      </Card>
    </>
  );
}

export default IndexPage;