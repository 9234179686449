import React, { useState } from 'react';
import { Table, Tag, Grid } from 'antd';
import { NumericFormat } from 'react-number-format';
import { formatDate } from 'utils';
import { useTranslation } from 'react-i18next';
import { defaultPagination } from 'constants/config/pagination';

const { useBreakpoint } = Grid;

const TablePage = ({ data = []}) => {

  const { t, i18n } = useTranslation();
  const screens = useBreakpoint();
  const [currentPagination, setPagination] = useState(defaultPagination);

  const columns = [
    {
      title: t('label.last_update'),
      render: (item, record) => (
        <div>{formatDate(record.createdAt)}</div>
      )
    },
    {
      title: t('label.reason'),
      dataIndex: 'reason',
    },
    {
      title: t('label.qty'),
      responsive: ['sm'],
      render: (item, record) => (
        <div>
          {
            item.baseQty < item.changeQty ? <div style={{ color: "green" }}>{ item.qty }</div> : <div style={{ color: "#f5222d" }}>-{ item.qty }</div>
          }
        </div>
      )
    },
  ];

  const handleTableChange = (value) => {
    setPagination(value);
  }

  return (
    <>
      <Table
        rowKey="id"
        size="small"
        dataSource={data}
        columns={columns}
        onChange={(e) => handleTableChange(e)}
      />
    </>
  );
};

export default TablePage;