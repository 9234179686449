import React from "react";
import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';

const IndexPage = ({ status }) => {

  const { t, i18n } = useTranslation();

  const renderSwitch = (param) => {
    switch(param) {
      case 'Pending':
        return <Badge status='default' text={t('label.pending')} />;
      case 'On-Hold':
        return <Badge status='success' text={t('label.onHold')} />;
      case 'Processing':
        return <Badge status='processing' text={t('label.processing')} />;
      case 'Completed':
        return <Badge status='success' text={t('label.completed')} />;
      default:
        return <></>;
    }
  }

  return (
    <>
      { renderSwitch(status) }
    </>
  )
}

export default IndexPage;