import i18next from 'i18next';

export const URL = 'api/customers';

export const TITLE = i18next.t('menu.customer');

export const TablePagination = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [10]
}