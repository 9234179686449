import { useState, useEffect } from 'react'
import { Table } from 'antd';
import { v4 as uuid } from 'uuid';
const { Column } = Table;

const AppTable = ({
  headers = [],
  items = [],
  bordered = true,
  disableAction = false,
  pagination = {
    current: 1,
    pageSize: 10,
  },
  size = 'default',
  children,
  onChange
}) => {

  const [internalPagination, setInternalPagination] = useState(pagination)
  const [internalHeaders, setInternalHeaders] = useState([]);
  const [internalItems, setInternalItems] = useState([]);

  const onTableChange = (value) => {
    const pagination = {
      current: value.current,
      pageSize: value.pageSize
    }
    setInternalPagination(pagination)
  }

  useEffect(() => {
    if (headers && headers.length > 0) {
      if (disableAction) {
        setInternalHeaders(headers);
      } else {
        let actionColumn = {
          title: 'Actions',
          dataIndex: 'actions'
        }
        setInternalHeaders([...headers, actionColumn]);
      }
    }
  }, [headers, disableAction]);

  useEffect(() => {
    if (items && items.length > 0) {
      items.map((item) => {
        item._keyId = uuid();
      })
      setInternalItems(items);
    } else {
      setInternalItems([])
    }
  }, [items]);

  return (
    <Table
      rowKey="_keyId"
      scroll={scroll}
      size={size}
      bordered={bordered}
      dataSource={internalItems}
      pagination={internalPagination}
      onChange={onTableChange}
      // columns={headers}
    >
      {internalHeaders.map((header) => (
        <>
          {
            (children && children[header.dataIndex]) ? children[header.dataIndex] : (
              <Column
                title={header.title}
                dataIndex={header.dataIndex}
                align={header.align}
                key={header.key}
                render={header.render}
              />
            )
          }
        </>
      ))}
    </Table>
  )
}

export default AppTable;