import React from 'react'
import { Card, Space, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import TableForm from './Table';

const MoveSalePage = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const onViewClosingBalance = () => {
    navigate({
      pathname: `/move-sale/closing-balance`
    });
  }

  return (
    <>
      <PageHeader
        className='site-page-header'
        title={t('menu.moveSale')}
        extra={
          <Space>
            <Button
              onClick={() => onViewClosingBalance()}
              type="primary"
            >
              { t('action.payment') }
            </Button>
          </Space>
        }
      />
      <Card size='small'>
        <TableForm />
      </Card>
    </>
  )
}

export default MoveSalePage;