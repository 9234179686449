import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, message, Row, Col, Input, Button, Alert, Descriptions, Divider } from 'antd';
import Marquee from 'react-fast-marquee';
import { CreateModal } from "components/modals/index";
import { Riel } from 'components/special/Currency';
import { Loading } from 'utils';
import { API } from './constant';
import { useTranslation } from 'react-i18next';

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
    range: "${label} must be between ${min} and ${max}",
  },
};

const CreateEditModal = ({
  value = false,
  item = null,
  onSuccess,
  onCancel
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [remainder, setRemainder] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        amount: formValue.amount,
        remark: formValue.remark
      }
      const response = await axios.post(`${API}/confirm/${item.id}`, data);
      if (response.data.code === 200) {
        form.resetFields();
        onSuccess();
        message.success('This data added in System.');
      }
      else {
        message.info('Failed');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  const onCalculate = (item) => {
    const calPaidAmount = item.payments.reduce((p, c) => p + c.amount, 0);
    const calculate = calPaidAmount - item.totalWholesaleAmount;
    setRemainder(calculate);
    setPaidAmount(calPaidAmount);
  }

  useEffect(() => {
    if (item) {
      onCalculate(item);
    }
  }, [item]);

  const renderRemainder = (value) => {
    if (value < 0) {
      return <div style={{ color: '#F32131'}}><Riel value={remainder} /></div>;
    } else if (value === 0) {
      return <Riel value={remainder} />;
    } else {
      return <div style={{ color: '#527f5e' }}><Riel value={remainder} /></div>;
    }
  }

  return (
    <>
      <CreateModal
        title={t('action.confirm')}
        value={value}
        width={450}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Divider />
        {
          remainder > 0 ?
          <>
            <Alert
              banner
              message={
                <Marquee pauseOnHover gradient={false}>
                  <span>Remainder <Riel value={remainder} /></span>
                </Marquee>
              }
            />
            <br />
          </>
          : <></>
        }
        <Descriptions title="">
          <Descriptions.Item label={t('label.paymentAmount')} span={3}>
            <b>
              <Riel value={ item?.totalWholesaleAmount } />
            </b>
          </Descriptions.Item>
          <Descriptions.Item label={t('label.paidAmount')} span={3}>
            <b>
              <Riel value={ paidAmount } />
            </b>
          </Descriptions.Item>
          <Descriptions.Item label={t('label.remainder')} span={3}>
            {
              renderRemainder(remainder)
            }
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Form
          layout='vertical'
          form={form}
          validateMessages={validateMessages}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label={ t('label.amount')}
                name="amount"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^[0-9 ]*$/i
                    ),
                    message: "Please input only amount"
                  },
                  { required: true, message: 'Please input amount!' }
                ]}
              >
                <Input placeholder='Amount'/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item label={t('label.remark')} name="remark">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CreateModal>
    </>
  )
};

export default CreateEditModal;