import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Table, Button, Space } from 'antd';
import AppTable from 'components/common/table/AppTable';
import CreateEditReportModal from './createEdit';
import axios from 'axios';

const { Column } = Table;

const ReportPage = () => {
  const defaultPagination = {
    current: 1,
    pageSize: 10,
  }

  const tableHeaders = [
    { title: 'Code', dataIndex: 'code' },
    { title: 'Name', dataIndex: 'name' },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: "right",
      render: (item, record) => (
        <>
          <Space size="small">
            <Button
              onClick={() => onEditReport(record)}
            >
              Edit
            </Button>
          </Space>
        </>
      )
    }
  ]

  const [pagination, setPagination] = useState(defaultPagination)
  const [reportList, setReportList] = useState([])
  const [dialog, setDialog] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedReport, setSelectedReport] = useState(null)

  const onOpen = () => {
    setDialog(true)
  }
  const onOk = async () => {
    setDialog(false)
    await getData()
  }
  const onClose = () => {
    setSelectedReport(null)
    setDialog(false)
    setIsEdit(false)
  }

  const tableChange = (value) => {
    const pagination = {
      current: value.current,
      pageSize: value.pageSize
    }
    setPagination(pagination)
  }

  const onEditReport = async (item) => {
    setSelectedReport(item)
    await setIsEdit(true)
    setDialog(true)
  }

  const getData = async () => {
    const response = (await axios.get('api/report')).data
    if (response.result) {
      // avoid console props key error
      const result = response.result.map((item, index) => {
        item.key = index
        return item
      })
      setReportList(result)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Card
        title='Report'
        extra={<Button onClick={onOpen}>Create</Button>}
      >
        <Row>
          <Col span={24}>
            <CreateEditReportModal
              visible={dialog}
              item={selectedReport}
              isEdit={isEdit}
              onCancel={onClose}
              onOk={onOk}
            />
            <AppTable
              headers={tableHeaders}
              items={reportList}
              pagination={pagination}
              onChange={tableChange}
            />
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ReportPage;