import React, { useEffect } from 'react';
import { Form, message, Input } from 'antd';
import { layoutConstant } from 'constants/config/form-layout.constant';
import { Loading } from 'utils';
import { URL } from './constant';
import axios from 'axios';
import { CreateModal } from "components/modals/index";

const CreateEditModal = ({
  value = false,
  item = null,
  isEdit = false,
  onSuccess,
  onCancel
}) => {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        name: formValue.name,
        nameEn: formValue.nameEn,
      }
      if (item && item.id) {
        await put(item.id, data);
      } else {
        await post(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const post = async (data) => {
    const response = await axios.post(URL, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {     
      message.info('Failed');
    }
  }

  const put = async (id, data) => {
    const response = await axios.put(`${URL}/${id}`, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {     
      message.info('Failed');
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        name: item.name,
        nameEn: item.nameEn,
      })
    }
  }, [item])

  return (
    <>
      <CreateModal
        title={isEdit ? 'Edit Province' : 'Create Province'}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form
          form={form}
          {...layoutConstant}
        >
          <Form.Item
            name="name"
            label='Province'
            rules={[
              {
                required: true,
                message: 'Please input province!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="nameEn"
            label='Province En'
            rules={[
              {
                required: true,
                message: 'Please input province en!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </CreateModal>
    </>
  )
};

export default CreateEditModal;