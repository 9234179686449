import React, { useState, useEffect } from 'react';
import { Card, Button, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
import CreatePage from './create';
import UpdatePage from './update';
import { Loading } from 'utils';
import { useTranslation } from 'react-i18next';

const ExpenseTypePage = () => {

  const { t } = useTranslation();
  const [data, setData] = useState([]);

  async function fetchExpenseTypes() {
    try {
      Loading.setLoading(true);
      const response = await axios.get(`api/expense-type`)
      if (response.data.code === 200) {   
        const deliveries = response.data.result;
        setData(deliveries);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  useEffect(() => {
    fetchExpenseTypes();
  }, []);

  const handleClick = async () => {
    setDialogCreate(true)
  }

  const handleCancel = () => {
    setDialogCreate(false)
  }

  const handleSuccess = () => {
    fetchExpenseTypes();
  }

  const [dialogCreate, setDialogCreate] = useState(false)

  return (
    <>
      <PageHeader
        className='site-page-header'
        title={t('menu.expenseType')}
        extra={
          <Button
            onClick={handleClick}
          >
            {t('action.create')}
          </Button>
        }
      />
      <Card size='small'>
        <CreatePage
          visible={dialogCreate}
          onCancel={handleCancel}
          onSuccess={handleSuccess}
        />
        <UpdatePage
          data={data}
          onSuccess={handleSuccess}
        />
      </Card>
    </>
  )
}

export default ExpenseTypePage;