export const defaultSearchParam = Object.freeze({
  expensePeriod: null,
  date: null,
  expenseTypeId: null,
  status: null
})

export const expenseTablePagination = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [10]
}

export const EXPENSE_API_URL = 'api/expense'

export const expensePeriod = [
  { value: 'Daily', text: 'Daily' },
  { value: 'Monthly', text: 'Monthly' }
]

export const statuses = [
  { value: 'Pending', text: 'Pending' },
  { value: 'Approved', text: 'Approved' }
]