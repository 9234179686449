import React, { useEffect, useState, useMemo } from 'react';
import { Form, Input, Select, InputNumber, message } from 'antd';
import { layoutConstant } from 'constants/config/form-layout.constant';
import { PRODUCT_STOCK } from 'constants/config/api-url.constant';
import { StockAdjustmentMode } from 'constants/inventory/stock.constant';
import axios from 'axios';
import { URL_STOCK_IN, URL_STOCK_OUT } from './stock.constant';
import { Loading } from 'utils';
import { useTranslation } from 'react-i18next';
import { CreateModal } from "components/modals/index";

const StockAdjustmentModal = ({
  value = false,
  mode = null,
  onCancel,
  onSuccess
}) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [form] = Form.useForm();

  const fetchProducts = async () => {
    try {
      const response = await axios.get(PRODUCT_STOCK);
      const result = (response.data && response.data.result) || []
      setProducts(result);
    } catch (error) {
      message.error(error.message);
    }
  }

  useEffect(() => {
    async function fetch () {
      await fetchProducts();
    };
    fetch();
  }, []);

  const modalTitle = useMemo(() => {
    if (mode) {
      if (mode == StockAdjustmentMode.ADD) { return t('label.stock_in'); }
      if (mode == StockAdjustmentMode.MINUS) { return t('label.stock_out'); }
    }
    return ""
  }, [mode]);

  const stockAdjustmentUrl = useMemo(() => {
    if (mode) {
      if (mode == StockAdjustmentMode.ADD) { return URL_STOCK_IN; }
      if (mode == StockAdjustmentMode.MINUS) { return URL_STOCK_OUT; }
    }
    return null
  }, [mode])


  const onSubmit = async () => {
    const formValue = await form.validateFields();
    const stockInOutUrl = stockAdjustmentUrl;
    try {
      Loading.setLoading(true);
      if (stockInOutUrl) {
        const data = {
          productId: formValue.product && formValue.product.value,
          qty: formValue.qty,
          note: formValue.note
        }
        const response = await axios.post(stockInOutUrl, data);
        if (response.data.code === 200) {
          form.resetFields();
          onSuccess();
          message.success('Successful.');
        }
        else {
          message.info('Failed.');
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const onCloseModal = () => {
    onCancel();
    form.resetFields();
  }

  return (
    <>
      <CreateModal
        title={modalTitle}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCloseModal()}
      >
        <Form
          form={form}
          {...layoutConstant}
        >
          <Form.Item
            name="product"
            label={t('label.product')}
            disabled
            rules={[
              {
                required: true,
                message: 'Please input Product',
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Product'
              labelInValue={true}
              fieldNames={{ label: 'name', value: 'id'}}
              filterOption={(input, option) => {
                return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
              }
              options={products}
            />
          </Form.Item>
          <Form.Item
            name="qty"
            label={t('label.qty')}
            rules={[{ required: true, message: 'Please input Qty' }]}
          >
            <InputNumber
              // placeholder='0'
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="note"
            label={t('label.note')}
          >
            <Input />
          </Form.Item>
        </Form>
      </CreateModal>
    </>
  )
};

export default StockAdjustmentModal;