import React, { useState, useEffect } from 'react';
import { message, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
// import NumberFormat from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { Loading } from 'utils';

const DailyPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetch () {
      Loading.setLoading(true);
      await onLoadData();
      Loading.setLoading(false);
    }
    fetch()
  }, []);

  const onLoadData = async () => {
    try {
      Loading.setLoading(true);
      const response = await axios.get(`api/closing-balance`);
      if (response.data.code === 200) {
        setData(response.data.result);
      } else {
        setData([]);
        message.info('This sale not yet have in System.');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => (
        <div>{record.code}</div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <div>{moment(record.date).format("YYYY-MM-DD")}</div>
      )
    },
    {
      title: 'From Date',
      dataIndex: 'fromDate',
      key: 'fromDate',
      render: (text, record) => (
        <div>{moment(record.fromDate).format("YYYY-MM-DD")}</div>
      )
    },
    {
      title: 'To Date',
      dataIndex: 'toDate',
      key: 'toDate',
      render: (text, record) => (
        <div>{moment(record.toDate).format("YYYY-MM-DD")}</div>
      )
    },
    {
      title: 'Total Sale Amount',
      dataIndex: 'totalSaleAmount',
      key: 'totalSaleAmount',
      align: 'right',
      render: (text, record) => (
        <b>
          <NumericFormat value={record.totalSaleAmount} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
        </b>
      )
    },
    {
      title: 'Total Revenue',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      align: 'right',
      render: (text, record) => (
        <b>
          <NumericFormat value={record.totalRevenue} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
        </b>
      )
    },
    {
      title: 'Total Expense',
      dataIndex: 'totalExpense',
      key: 'totalExpense',
      align: 'right',
      render: (text, record) => (
        <b>
          <NumericFormat value={record.totalExpense} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
        </b>
      )
    },
    {
      title: 'Income',
      dataIndex: 'income',
      key: 'income',
      align: 'right',
      render: (text, record) => (
        <b>
          <NumericFormat value={record.income} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
        </b>
      )
    },
    {
      title: 'Sale Unpaid',
      dataIndex: 'saleUnpaid',
      key: 'saleUnpaid',
      align: 'right',
      render: (text, record) => (
        <b>
          <NumericFormat value={record.totalSaleUnpaid} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
        </b>
      )
    },
    {
      title: 'Monthly Code',
      dataIndex: 'monthlyCode',
      key: 'monthlyCode',
    }
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      bordered={true}
      pagination={{ pageSize:7 }}
      scroll={{ x: 1500 }}
      columns={columns}
    />
  );
};

export default DailyPage;