import moment from 'moment';

export * from './common.js';

export const dateFormat = () => {
  return 'YYYY-MM-DD'
}

export const tablePagination = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [10]
}

export const getNestedRouteKeys = (pathname) => {
  if (pathname) {
    const arrPath = pathname.split('/').slice(1)
    const path = []
    for (let i = 0; i < arrPath.length; i++) {
      path.push(`/${arrPath.slice(0, arrPath.length - i).join('/')}`)
    }
    return [...path]
  }
  return [pathname]
}

export function formatDate(dateTime) {
  return moment(dateTime).format(dateFormat());
}

export function formatDateTime(dateTime) {
  return moment(dateTime).format(dateFormat());
}