import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { layoutConstant } from 'constants/config/form-layout.constant';
import { Loading } from 'utils';
import { USER_ROLE } from 'constants/role.constant';
import { CreateModal } from "components/modals/index";

const CreateEditModal = ({
  value = false,
  item = null,
  isEdit = false,
  onCreate,
  onCancel
}) => {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      const formValue = await form.validateFields();
      if (item && item.id) {
        const data = {
          id: item.id,
          username: formValue.username,
          password: formValue.password,
        }
        form.resetFields();
        onCreate(data);
      }
    } catch (error) {
      console.log(error);
      // message.error(error.message);
    } finally {
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  useEffect(() => {
    if (item) {
      console.log('item', item)
      form.setFieldsValue({
        username: item.username,
        password: item.password,
      })
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={isEdit ? 'Edit User' : 'Create User'}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form
          form={form}
          initialValues={{
            role: USER_ROLE
          }}
          {...layoutConstant}
        >
          <Form.Item
            name="username"
            label='Username'
            rules={[
              {
                required: true,
                pattern: new RegExp(
                  /^[A-Za-z0-9]*$/i
                ),
                message: "Please input only letters and numbers"
              },
              { required: true, message: 'Please input your username!' },
              { min: 5, message: 'Username must be minimum 5 characters.' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </CreateModal>
    </>
  )
};

export default CreateEditModal;