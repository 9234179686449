import React from 'react';
import { Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const DeleteButton = ({
  onClick
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Tooltip placement="topLeft" color='geekblue' title={ t('action.delete') }>
      <Button
        danger
        icon={<DeleteOutlined />}
        onClick={() => onClick()}
      />
    </Tooltip>
  )
}

export default DeleteButton;