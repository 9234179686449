const EQUIPMENT = { text: 'Equipment', value: 'Equipment' }
const FURNITURE = { text: 'Furniture', value: 'Furniture'}
const VEHICLES = { text: 'Vehicles', value: 'Vehicles'}
const FIXTURE_AND_FITTING = { text: 'Fixtures & Fittings', value: 'Fixtures & Fittings'}
const LAND = { text: 'Land', value: 'Land'}
const MACHINERY = { text: 'Machinery', value: 'Machinery'}
const CASH_IN_HAND = { text: 'Cash In Hand', value: 'Cash In Hand'}
const CASH_IN_BANK = { text: 'Cash In Bank', value: 'Cash In Bank'}
const INVENTORIES = { text: 'Inventories', value: 'Inventories'}

export const assetTypes = Object.freeze([
  EQUIPMENT,
  FURNITURE,
  VEHICLES,
  FIXTURE_AND_FITTING,
  LAND,
  MACHINERY,
  CASH_IN_HAND,
  CASH_IN_BANK,
  INVENTORIES
])