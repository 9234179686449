import React from "react";
import { Layout, Row, Col } from "antd";
import { PurchaseOrderMenu } from "layout/menu/index.constant.js";
import SliderLayout from "./ui/sider";
import FooterLayout from "./ui/footer";
import HeaderLayout from "./ui/header";

import { Outlet } from "react-router-dom";

const { Content } = Layout;

const InventoryLayout = () => {
  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <HeaderLayout />
        <Layout>
          <SliderLayout items={PurchaseOrderMenu} />
          <Layout>
            <Content
              style={{
                padding: 16,
                margin: 0,
                // background: "blue",
                height: "calc(80vh - 55px)",
                minHeight: "80vh",
              }}
            >
              <Row justify="end">
                <Col span={24}>
                  <Outlet />
                </Col>
              </Row>
            </Content>
            <FooterLayout />
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default InventoryLayout;
