import React from "react";
import { Layout } from "antd";
import FooterLayout from "./ui/footer";
import HeaderLayout from "./ui/header";

import { Outlet } from "react-router-dom";

const { Content } = Layout;

const DesktopLayout = () => {
  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <HeaderLayout />
        <Content
          style={{
            // padding: 8,
            // margin: 0,
            // background: "blue",
            // height: "calc(80vh - 55px)",
            margin: "8px 16px 0",
            // overflow: 'initial',
            // minHeight: "10vh",
          }}
        >
          {/* <Row justify="end">
            <Col span={24}>
              <Outlet />
            </Col>
          </Row> */}

          <Outlet />
        </Content>
        <FooterLayout />
      </Layout>
    </>
  );
};

export default DesktopLayout;
