import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  message,
  Row,
  Col,
  Input,
  InputNumber,
  Checkbox,
  Modal,
} from "antd";
import { Loading } from "utils";
import { URL } from "../../constant";
import { useTranslation } from "react-i18next";
import { CreateModal } from "components/modals/index";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const UpdateProductVariantModal = ({
  value = false,
  item = null,
  onSuccess,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const onChange = (key) => {
    console.log(key);
  };

  const onUpdate = async () => {
    try {
      Loading.setLoading(true);
      const formValues = await form.validateFields();
      const data = {
        code: formValues.code,
        name: formValues.name,
        wholesalePrice: formValues.wholesalePrice,
        retailPrice: formValues.retailPrice,
        items: formValues.items,
        isHide: formValues.isHide
      };
      const response = await axios.put(`${URL}/product-variant/${item.productId}`, data);
      if (response.data.code === 200) {
        form.resetFields();
        onSuccess();
        message.success("This data added in System.");
      } else {
        message.info("Failed");
      }
    } catch (error) {
      console.log("Failed:", error);
    } finally {
      Loading.setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        code: item.code,
        name: item.name,
        wholesalePrice: item.wholesalePrice,
        retailPrice: item.wholesalePrice,
        items: item.items,
        isHide: item.isHide
      });
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={t("menu.productVariant")}
        value={value}
        width={980}
        onOk={() => onUpdate()}
        onCancel={() => onCancelModal()}
      >
        <Form {...layout} form={form} validateMessages={validateMessages}>
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name="code" label={t("label.code")}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="name"
                label={t("label.name")}
                rules={[
                  {
                    required: true,
                    message: "Please input name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="wholesalePrice"
                label={t("label.wholesalePrice")}
                rules={[
                  {
                    required: true,
                    message: "Please input wholesale price!",
                  },
                ]}
              >
                <InputNumber placeholder="0" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="retailPrice"
                label={t("label.retailPrice")}
                rules={[
                  {
                    required: true,
                    message: "Please input retail price!",
                  },
                ]}
              >
                <InputNumber placeholder="0" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="items"
                label={t("label.items")}
                rules={[
                  {
                    required: true,
                    message: "Please input items!",
                  },
                ]}
              >
                <InputNumber placeholder="0" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="isHide"
                valuePropName="checked"
                {...tailFormItemLayout}
              >
                <Checkbox>Hide</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CreateModal>
    </>
  );
};

export default UpdateProductVariantModal;
