import React, { useEffect, useState, forwardRef, useImperativeHandle, useMemo  } from 'react';
import { Card, Form, Input, Select, Row, Col, message, Space, Button, InputNumber } from 'antd';
import { layoutConstant } from 'constants/config/form-layout.constant';
import { PurchaseOrderStatus, DeliveryStatus } from 'constants/inventory/sale-rep-order.constant';
import { PRODUCT } from 'constants/config/api-url.constant';
import { Loading } from 'utils';
import axios from 'axios';

import AppTable from 'components/common/table/AppTable';
import Confirm from 'components/modals/Confirm';
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};


const PurchaseOrderForm = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getPurchaseOrder: async () => {
      const formValue = await form.validateFields();
      return {
        ...formValue,
        detail: selectedProducts
        // totalAmount: totalAmount
      }
    }
  }));

  // Data
  // const [suppliers, setSuppliers] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [editProductItem, setEditProductItem] = useState(null);

  const [form] = Form.useForm();
  const [productForm] = Form.useForm();

  
  // const totalExpense = useMemo(() => {
  //   if (item) {
  //     return item.totalExpense;
  //   }
  //   return 0;
  // }, [item]);

  const productHeaders = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'id',
      render: (item) => (
        <div>{item.label}</div>
      )
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'id'
    },
    {
      title: 'Price',
      dataIndex: 'productPrice',
      key: 'id'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'id'
    },
    {
      title: 'Actions',
      dataIndex: '',
      render: (item) => {
        return (
          <Space>
            <Button
              danger
              size="small"
              onClick={() => removeProduct(item)}
            >
              Remove
            </Button>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    async function fetch () {
      try {
        Loading.setLoading(true);
        // await fetchSuppliers();
        await fetchProducts();
      } catch (error) {
        message.error(error.message);
      } finally {
        Loading.setLoading(false);
      }
    }
    fetch();
  }, []);
  
  const totalAmount = useMemo(() => {
    // TODO: To implement
    return 0
  }, []);

  const productList =  useMemo(() => {
    return products.filter(x => {
      if (selectedProducts.length > 0) {
        const some = selectedProducts.some(s => {
          return s.product && s.product.value !== x.id
        })
        return some
      }
      return true
    })
  }, [selectedProducts, products]);

  // const fetchSuppliers = async () => {
  //   const response = await axios.get(SUPPLIER_URL);
  //   const result = (response.data && response.data.result) || []
  //   console.log('suppliers', result)
  //   setSuppliers(result);
  // };

  const fetchProducts = async () => {
    const response = await axios.get(PRODUCT);
    const result = (response.data && response.data.result) || []
    console.log('products', result)
    setProducts(result);
  };

  const onCreateProduct = async () => {
    try {
      const values = await productForm.validateFields();
      if (values.qty === 0) {
        message.error("ចំនួនទំនិញមិនទាន់មានទេ.");
        return;
      }
      console.log('onCreateProduct', values);
      setSelectedProducts([...selectedProducts, values]);
      productForm.resetFields();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const removeProduct = (e) => {
    const productId = e.product && e.product.value
    const result = selectedProducts.filter(item => item.product.value !== productId)
    setSelectedProducts([...result]);
  };

  return (
    <>
      <Card>
        <Form
          form={productForm}
          initialValues={{
            status: 'New',
            deliveryStatus: 'Processing'
          }}
          {...layoutConstant}
        >
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24} lg={7}>
              <Form.Item
                label="ទំនិញ"
                name="product"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Product"
                  labelInValue={true}
                  // onChange={onSelectedProduct}
                  // onKeyDown={onKeyDown}
                  fieldNames={{ label: "name", value: "id" }}
                  filterOption={(input, option) => {
                    return (option?.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  options={productList}
                />
              </Form.Item>
            </Col>
            <Col xs={5} sm={5} md={5} lg={4}>
              <Form.Item
                label="ចំនួន"
                name="qty"
                rules={[{ required: true }]}
              >
                <InputNumber
                  controls={false}
                  // onChange={onChangeProductQty}
                  // onKeyDown={onKeyDown}
                  placeholder="0"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={4} sm={3} md={3} lg={3}>
              <Button
                icon={<PlusOutlined />}
                onClick={() => onCreateProduct()}
                shape="round"
              />
            </Col>
          </Row>
          <AppTable
            items={selectedProducts}
            headers={productHeaders}
            size="small"
            disableAction
          />
          <br />
          <Row>
            <Col span={5} offset={13}>
              <Space style={{width: '100%', justifyContent: 'right'}} >
                តម្លៃសរុប
              </Space>
            </Col>
            <Col span={5} offset={1}>
              <Input
                value={totalAmount}
                placeholder="Total Amount"
                readOnly
                addonAfter="រៀល"
              />
            </Col>
          </Row>

          <Row>
            {/* <Col span={24} sm={12}>
              <Form.Item
                name="status"
                label="Status"
                disabled
                rules={[
                  {
                    required: true,
                    message: 'Please input Status',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Status'
                >
                  {PurchaseOrderStatus.map((option, index)=> (
                    <Option key={index} value={option.value}>{option.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={24} sm={12}>
              <Form.Item
                name="note"
                label="Note"
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col span={24} sm={12}>
              <Form.Item
                name="deliveryStatus"
                label="Delivery Status"
                disabled
                rules={[
                  {
                    required: true,
                    message: 'Please input Delivery Status',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Delivery Status'
                >
                  {DeliveryStatus.map((option, index)=> (
                    <Option key={index} value={option.value}>{option.text}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Card>
    </>
  )
});

export default PurchaseOrderForm;