import React, { useState, useEffect } from 'react'
import AppModal from 'components/common/modal/AppModal';
import { Form, Input, message, Select } from 'antd';
import axios from 'axios';

const { Option } = Select;

const CreateEditExpense = ({
  visible = false,
  monthlyClosingBalanceId = undefined,
  currentItem = null,
  onSuccess,
  onCancel
}) => {
  const [form] = Form.useForm();
  const [expenseTypes, setExpenseTypes] = useState([])

  const onLoadExpense = async () => {
    const response = await axios.get(`api/expense-type`)
    if (response.data.code === 200) {
      setExpenseTypes(response.data.result);
    } else {
      message.info('This sale not yet have in System.');
    }
  }

  const close = () => {
    onCancel()
    form.resetFields();
  }

  const sumbitExpense = async () => {
    try {
      if (monthlyClosingBalanceId) {
        const data = {
          expenseTypeId: form.getFieldValue('expenseTypeId'),
          expenseTypeName: expenseTypes.find(item => item.id === form.getFieldValue('expenseTypeId')).name,
          amount: form.getFieldValue('amount'),
          note: form.getFieldValue('note')
        }
        const response = await axios.post(`api/closing-balance-monthly/expense/${monthlyClosingBalanceId}`, data)
        if (response.data.code === 200) {
          form.resetFields();
          onSuccess();
        } else {
          message.error(response.message)
        }
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  const updateExpense = async (id) => {
    try {
      if (monthlyClosingBalanceId) {
        const data = {
          id: id,
          expenseTypeId: form.getFieldValue('expenseTypeId'),
          expenseTypeName: expenseTypes.find(item => item.id === form.getFieldValue('expenseTypeId')).description,
          amount: form.getFieldValue('amount'),
          note: form.getFieldValue('note')
        }
        const response = await axios.put(`api/closing-balance-monthly/expense/${monthlyClosingBalanceId}`, data)
        if (response.data.code === 200) {
          form.resetFields();
          onSuccess();
        } else {
          message.error(response.message)
        }
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  const handleSubmit = () => {
    if (currentItem) {
      updateExpense(currentItem._id)
    } else {
      sumbitExpense()
    }
  }

  const onExpenseTypeChange = (item) => {
    const amount = expenseTypes.find(i => i.id === item).price
    form.setFieldsValue({
      amount
    })
  }

  useEffect(() => {
    onLoadExpense();
  }, [])

  useEffect(() => {
    if (currentItem) {
      form.setFieldsValue({
        expenseTypeId: currentItem.expenseTypeId,
        amount: currentItem.amount,
        note: currentItem.note
      })
    }
  }, [currentItem])
  return (
    <>
      <AppModal
        visible={visible}
        onOk={() => handleSubmit()}
        onCancel={close}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          form={form}
        >
          <Form.Item
            label='Expense'
            name="expenseTypeId"
          >
            <Select
              showSearch
              onChange={onExpenseTypeChange}
            >
              {expenseTypes.map((item) => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='Amount'
            name="amount"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label='Note'
            name="note"
          >
            <Input type="textarea" />
          </Form.Item>
        </Form>
      </AppModal>
    </>
  )
}

export default CreateEditExpense;