import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
  Form,
  Grid,
  Table,
  Tooltip,
  Descriptions,
} from "antd";
import DisplayModal from "components/modals/v2/Display";
import { Riel } from "components/special";

const { useBreakpoint } = Grid;

const ViewSaleDetail = ({ item = null, open = false, onCancel, onSuccess }) => {
  const [form] = Form.useForm();

  const [products, setProducts] = useState([]);
  const [currentProducts, setCurrentProducts] = useState([]);

  const screens = useBreakpoint();

  const title = useMemo(() => {
    if (item) {
      return item && item.code;
    }
  }, [item]);

  const productTableColumns = [
    {
      title: "ឈ្មោះទំនិញ",
      responsive: ["xs"],
      render: (record) => <div>{record.productName}</div>,
    },
    {
      title: "តម្លៃរាយ",
      align: "right",
      responsive: ["xs"],
      render: (record) => (
        <div>
          {record.qty} * {record.price}
        </div>
      ),
    },
    {
      title: "តម្លៃសរុប",
      dataIndex: "amount",
      align: "right",
      responsive: ["xs"],
      ellipsis: {
        showTitle: false,
      },
    },
    // Web
    {
      title: "ឈ្មោះទំនិញ",
      dataIndex: "productName",
      ellipsis: {
        showTitle: false,
      },
      responsive: ["sm"],
      render: (productName) => (
        <Tooltip placement="topLeft" title={productName}>
          {productName}
        </Tooltip>
      ),
    },
    {
      title: "ចំនួន",
      dataIndex: "qty",
      align: "right",
      responsive: ["sm"],
    },
    {
      title: "តម្លៃរាយ",
      dataIndex: "price",
      align: "right",
      responsive: ["sm"],
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "តម្លៃសរុប",
      dataIndex: "amount",
      align: "right",
      responsive: ["sm"],
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  const saleInfo = [
    {
      key: "1",
      label: "លេខ​វិ​ក័​យ​ប័ត្រ",
      children: item && item.invoiceSequence,
    },
    {
      key: "2",
      label: "តំណាងចែងចាយ",
      children: item && item.saleRepName,
    },
    {
      key: "3",
      label: "ដឹកជញ្ជូន",
      children: item && item.deliveryName,
    },
    {
      key: "4",
      label: "លេខអ្នកដឹកជញ្ជូន",
      children: item && item.deliveryMobilePhone,
    },
    {
      key: "5",
      label: "តម្លៃដឹកជញ្ជូន",
      children: item && item.deliveryPrice,
    },
    {
      key: "6",
      label: "អតិថិជន",
      children: item && item.customerName,
    },
    {
      key: "7",
      label: "លេខអតិថិជន",
      children: item && item.phoneNumber,
    },
    {
      key: "8",
      label: "ទីតាំង",
      children: item && item.address,
    },
    {
      key: "9",
      label: "ចំណាំ",
      children: item && item.remark,
    },
    {
      key: "10",
      label: "បញ្ចុះតម្លៃ",
      children: item && item.discount,
    },
  ];

  const onLoadProduct = async () => {
    const response = await axios.get(`api/products`);
    if (response.data.code === 200) {
      setProducts(response.data.result);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await onLoadProduct();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
      if (item.detail) {
        setCurrentProducts(item.detail);
      }
    }
  }, [item]);

  return (
    <>
      <DisplayModal
        open={open}
        width={1000}
        title={`Detail: ${title}`}
        onCancel={() => onCancel()}
        onOk={() => onCancel()}
      >
        <Descriptions size="middle" title="" items={saleInfo} />

        <Table
          rowKey="productId"
          size="middle"
          dataSource={currentProducts}
          pagination={false}
          bordered
          columns={productTableColumns}
          summary={() => {
            const currentScreens = Object.entries(screens).filter(
              (screen) => !!screen[1]
            ).length;
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={currentScreens > 1 ? 3 : 2}
                    align="right"
                  >
                    តម្លៃសរុប
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right">
                    <Riel value={item ? item.subTotal : 0} />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={currentScreens > 1 ? 3 : 2}
                    align="right"
                  >
                    តម្លៃដឹកជញ្ជូន
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Riel value={item ? item.deliveryPrice : 0} />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={currentScreens > 1 ? 3 : 2}
                    align="right"
                  >
                    បញ្ចុះតម្លៃ
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Riel value={item ? item.discount : 0} />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={currentScreens > 1 ? 3 : 2}
                    align="right"
                  >
                    តម្លៃសរុបរួម
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Riel value={item ? item.totalAmount : 0} />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </DisplayModal>
    </>
  );
};

export default ViewSaleDetail;
