import { Popover, Divider, Button, Row, Col } from 'antd';

const PopupFilter = ({
  title = '',
  placement = 'rightTop',
  visible = false,
  button = null,
  form = null,
  resetText = 'Reset',
  searchText = 'Search',
  onReset,
  onClose,
  onFilter
}) => {
  const onOpenChange = (value) => {
    if (!value) {
      onClose()
    }
  }
  return (
    <>
      <Popover
        title={title}
        trigger="click"
        placement={placement}
        content={
          <>
            {form}
            <Divider style={{ margin: '10px 0px' }}></Divider>
            <Row gutter={8} justify="end">
              <Col>
                <Button
                  size="small"
                  onClick={() => onReset()}
                >
                  {resetText}
                </Button>
              </Col>
              <Col>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => onFilter()}
                >
                  {searchText}
                </Button>
              </Col>
            </Row>
          </>
        }
        open={visible}
        onOpenChange={onOpenChange}
      >
        {button}
      </Popover>
    </>
  )
}

export default PopupFilter;