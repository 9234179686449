import React from 'react';
import SaleListForm from '../../components/sales/list/Index';


const SaleList = () => {

  return (
    <SaleListForm/>
  );
}

export default SaleList;
