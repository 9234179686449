import React, { useEffect, useState, useRef, useCallback, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import {
  message,
  Card,
  Button,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Space,
  Tooltip,
  Grid,
  Divider,
} from "antd";
import axios from "axios";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import PizzaInvoice from "pages/report/pizzaInvoiceReport/index";
import DeliveryReport from "pages/report/deliveryReport";
import { useReactToPrint } from "react-to-print";
// import NumberFormat from 'react-number-format';
import { NumericFormat } from "react-number-format";

import { UserReport } from "constants/report/user-report-constant";
import CustomerCreateEditModal from "pages/setting/customer/createEditModal";
import moment from "moment";
import { Loading } from "utils";
import { useTranslation } from "react-i18next";
import { useProducts } from "./hooks/useProducts";
import { useDeliveries } from "./hooks/useDeliveries";

const { useBreakpoint } = Grid;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const SaleFormPage = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getPurchaseOrder: async () => {
      const values = await prepareSale();
      console.log('Values:', values);
      return values;
    }
  }));

  const { t } = useTranslation();
  const screens = useBreakpoint();
  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);
  const inputQtyRef = useRef(null);
  // Form
  const [formCustomer] = Form.useForm();
  const [formProduct] = Form.useForm();

  const discount = Form.useWatch("discount", formCustomer);
  const deliveryPrice = Form.useWatch("deliveryPrice", formCustomer);

  // pdfRef = React.createRef();
  const [visible, setVisible] = useState(false);
  const [visibleSavedModal, setVisibleSavedModal] = useState(false);
  const [defaultDate] = useState(moment().format("YYYY-MM-DD HH:mm"));

  const [subTotal, setSubTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  // Customer
  const [selectedDelivery, setSelectedDelivery] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [customerCreateEditModal, setCustomerCreateEditModal] = useState(false);

  // Product
  // const [products, setProducts] = useState([]);
  // const [deliveries, setDeliveries] = useState([]);
  const { products } = useProducts();
  const { deliveries } = useDeliveries();
  const [saleReps, setSaleReps] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [saleDetail, setSaleDetail] = useState([]);

  // Settings
  const [setting, setSetting] = useState({});
  const [sales, setSales] = useState([]);
  const [savedSale, setSavedSale] = useState({});
  const [isPrint, setIsPrint] = useState(false);

  const columnsForMobile = [
    {
      title: "ឈ្មោះទំនិញ",
      render: (record) => (
        <>
          <Row gutter={8} justify="space-around" align="middle">
            <Col span={13} align="left">
              <b>{record.qty}x</b> {record.productName}
            </Col>
            <Col span={11} align="right">
              <NumericFormat
                value={record.amount}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" ៛ "}
                renderText={(value, props) => <b {...props}>{value}</b>}
              />
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => onDeleteProduct(record)}
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const columns = [
    {
      title: "ឈ្មោះទំនិញ",
      dataIndex: "productName",
      render: (productName) => (
        <Tooltip placement="topLeft" title={productName}>
          {productName}
        </Tooltip>
      ),
    },
    {
      title: "ចំនួន",
      dataIndex: "qty",
      align: "right",
    },
    {
      title: "តម្លៃរាយ",
      dataIndex: "price",
      align: "right",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "តម្លៃសរុប",
      dataIndex: "amount",
      align: "right",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => onDeleteProduct(record)}
          />
        </Space>
      ),
    },
  ];

  // const isMobile = Object.entries(screens).find(screen => screen[0] === 'xs');
  useEffect(() => {
    async function fetchData() {
      Loading.setLoading(true);
      // onLoadProduct();
      // onLoadDelivery();
      if (roleAdmin == roleValue) {
        fetchSaleRep();
      }
      fetchCustomer();
      await onReportSetting();
      Loading.setLoading(false);
    }
    fetchData();
  }, []);

  const updateTotalAmount = useCallback(() => {
    let tmpDeliveryPrice = 0;
    if (discount == null) {
      formCustomer.setFieldsValue({
        discount: 0,
      });
    }
    if (deliveryPrice == null) {
      formCustomer.setFieldsValue({
        deliveryPrice: 0,
      });
    }
    const subTotal = saleDetail.reduce((p, x) => p + x.amount, 0);
    if (selectedDelivery) {
      tmpDeliveryPrice = selectedDelivery.price ? selectedDelivery.price : 0;
    }
    setSubTotal(subTotal);
    setTotalAmount(subTotal + deliveryPrice - discount);
  }, [saleDetail, selectedDelivery, discount, deliveryPrice]);

  useEffect(() => {
    updateTotalAmount();
  }, [saleDetail, selectedDelivery, discount, deliveryPrice]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        print();
      }, 300);
    }
  }, [isPrint]);

  const onReportSetting = async () => {
    const response = await axios.get(
      `api/users/find-organization-report/${UserReport.DELIVERY_REPORT.code}`
    );
    const report = response.data && response.data.result;
    const obj = {};
    report &&
      report.settings.forEach((setting) => {
        obj["setting_" + setting.position] = setting.predefined;
      });
    setSetting(obj);
  };

  // const onLoadDelivery = async () => {
  //   const response = await axios.get(`api/deliveries`);
  //   if (response.data.code === 200) {
  //     setDeliveries(response.data.result);
  //   }
  // };

  // const onLoadProduct = async () => {
  //   const response = await axios.get(`api/products`);
  //   if (response.data.code === 200) {
  //     setProducts(response.data.result);
  //   }
  // };

  const fetchSaleRep = async () => {
    const response = await axios.get(`api/sale-reps`);
    if (response.data.code === 200) {
      setSaleReps(response.data.result);
    }
  };

  const fetchCustomer = async () => {
    const response = await axios.get(`api/customers`);
    if (response.data.code === 200) {
      setCustomers(response.data.result);
    }
  };

  const prepareSale = async (isPrint) => {
    try {
      Loading.setLoading(true);
      const values = await formCustomer.validateFields();
      if (saleDetail.length > 0) {
        let printCount = 0;
        if (isPrint) {
          printCount = 1;
        }
        return {
          saleRepId: values.saleRepId,
          phoneNumber: values.phoneNumber,
          otherNumber: values.otherNumber,
          date: values.date,
          address: values.address,
          remark: values.remark,
          discount: values.discount,
          detail: saleDetail,
          subTotal: subTotal,
          totalAmount: totalAmount,
          printCount,
        };
      } else {
        message.error("មិនទាន់មានឥវ៉ាន.");
        return;
      }
    } catch (error) {
      console.log("Save Error", error);
      // message.error(error);
    } finally {
      Loading.setLoading(false);
    }
  };

  const onSubmitSale = async (isPrint) => {
    try {
      Loading.setLoading(true);
      const sale = await prepareSale(isPrint);
      if (Object.keys(sale).length > 0) {
        const response = await axios.post(`api/sales`, sale);
        if (response.data.code === 200) {
          setSales(response.data.result);
          setSavedSale(response.data.result);
          if (!isPrint) {
            setVisibleSavedModal(true);
          }
          onRefresh();
        } else if (response.data.code === 400) {
          setSales(response.data.result);
          setVisible(true);
        } else {
          message.error("This sale is not exist in the system");
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const onSubmitSaveAndPrint = async () => {
    try {
      Loading.setLoading(true);
      const sale = await prepareSale(true);
      if (Object.keys(sale).length > 0) {
        const response = await axios.post(`api/sales`, sale);
        if (response.data.code === 200) {
          setSales(response.data.result);
          setSavedSale(response.data.result);
          setIsPrint(true);
        } else if (response.data.code === 400) {
          setSales(response.data.result);
          setVisible(true);
        } else {
          message.error("This sale is not exist in the system");
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const componentRef = useRef(null);

  const print = useReactToPrint({
    content: () => componentRef.current,
    trigger: () => {
      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
      // to the root node of the returned component as it will be overwritten.
      return <a href="#">Print this out!</a>;
    },
    onAfterPrint: () => onRefresh(),
  });

  const onRefresh = () => {
    formCustomer.resetFields();
    formProduct.resetFields();
    setSaleDetail([]);
    setTotalAmount(0);
    setSavedSale({});
    setIsPrint(false);
  };

  const onCancel = (visible) => {
    setSales([]);
    setVisible(visible);
  };

  const onSaveCancel = (visibleSavedModal) => {
    setSales([]);
    setVisibleSavedModal(visibleSavedModal);
  };

  const onSelectedCustomer = async (value) => {
    setSelectedCustomer(value);
  };

  useEffect(() => {
    if (selectedCustomer) {
      const findCustomer = customers.find(
        (item) => item.id === selectedCustomer.key
      );
      if (findCustomer) {
        let deliveryId = findCustomer.deliveryId;
        if (deliveryId) {
          const findDelivery = deliveries.find(
            (item) => item.id === deliveryId.id
          );
          formCustomer.setFieldsValue({
            delivery: {
              key: deliveryId.id,
              label: deliveryId.name,
            },
            deliveryPrice: findDelivery.price ? findDelivery.price : 0,
            deliveryMobilePhone: findDelivery.phoneNumber,
          });
          setSelectedDelivery(findDelivery);
        } else {
          formCustomer.setFieldsValue({
            delivery: null,
            deliveryPrice: 0,
            deliveryMobilePhone: 0,
          });
          setSelectedDelivery({});
        }
        formCustomer.setFieldsValue({
          phoneNumber: findCustomer.phoneNumber ?? 0,
          address: findCustomer.address ?? "",
        });
      }
    }
  }, [selectedCustomer]);

  const onSelectedDelivery = async (value) => {
    const findDelivery = deliveries.find((item) => item.id === value.key);
    formCustomer.setFieldsValue({
      deliveryPrice: findDelivery.price ? findDelivery.price : 0,
      deliveryMobilePhone: findDelivery.phoneNumber,
    });
    setSelectedDelivery(findDelivery);
  };

  const onSelectedProduct = (value) => {
    const findProduct = products.find((item) => item.id === value.key);
    const values = formProduct.getFieldsValue();
    formProduct.setFieldsValue({
      price: findProduct.retailPrice,
      amount: values.qty * findProduct.retailPrice,
    });
    inputQtyRef.current.focus({
      cursor: "all",
    });
  };

  const onChangeProductQty = (value) => {
    if (value) {
      const values = formProduct.getFieldsValue();
      formProduct.setFieldsValue({
        qty: value,
        price: values.price,
        amount: value * values.price,
      });
    }
  };

  const onChangeProductPrice = (value) => {
    if (value) {
      const values = formProduct.getFieldsValue();
      formProduct.setFieldsValue({
        price: value,
        amount: value * values.qty,
      });
    }
  };

  const onKeyDown = async (e) => {
    if (e.key === "Enter") {
      await onCreateProduct();
    }
  };

  const onCreateProduct = async () => {
    try {
      const values = await formProduct.validateFields();
      if (values.qty === 0) {
        message.error("ចំនួនទំនិញមិនទាន់មានទេ.");
        return;
      }
      const findProduct = products.find(
        (item) => item.id === values.product.key
      );
      const findSaleDetail = saleDetail.find(
        (item) => item.productId === values.product.key
      );
      if (!findSaleDetail) {
        const selectedProduct = {
          productId: findProduct.id,
          productName: findProduct.name,
          price: values.price,
          qty: values.qty,
          amount: values.qty * values.price,
        };
        setSaleDetail((saleDetail) => [...saleDetail, selectedProduct]);
      }
      formProduct.resetFields();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onDeleteProduct = (value) => {
    const updateSaleDetail = saleDetail.filter(
      (item) => item.productId !== value.productId
    );
    setSaleDetail(updateSaleDetail);
  };

  const onAddCustomer = () => {
    setCustomerCreateEditModal(true);
  };

  const onCloseCustomerModal = () => {
    setCustomerCreateEditModal(false);
  };

  const onAddCustomerSuccess = async (res) => {
    if (res) {
      const customer = {
        ...formCustomer.getFieldValue("customer"),
        label: res.result && res.result.name,
        key: res.result && res.result.id,
        value: res.result && res.result.id,
      };
      await fetchCustomer();
      formCustomer.setFieldsValue({ customer });
      setSelectedCustomer(customer);
      setCustomerCreateEditModal(false);
    }
  };

  return (
    <>
      {process.env.REACT_APP_NAME === "Pizza" && (
        <PizzaInvoice ref={componentRef} item={savedSale} />
      )}
      {process.env.REACT_APP_NAME === "Online" && (
        <DeliveryReport ref={componentRef} item={savedSale} />
      )}
      <Row gutter={8}>
        {/* Product UI */}
        <Col xs={24} sm={12} md={12}>
          <Card title="ព័ត៌មានទំនិញ" bordered={false}>
            <Form
              {...formItemLayout}
              layout="vertical"
              form={formProduct}
              name="formProduct"
              initialValues={{
                qty: null,
              }}
              validateMessages={validateMessages}
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <Row gutter={8}>
                  <Col xs={24} sm={24} md={24} lg={7}>
                    <Form.Item
                      label="ទំនិញ"
                      name="product"
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        placeholder="Product"
                        labelInValue={true}
                        onChange={onSelectedProduct}
                        onKeyDown={onKeyDown}
                        fieldNames={{ label: "name", value: "id" }}
                        filterOption={(input, option) => {
                          return (option?.name ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        options={products}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={5} sm={5} md={5} lg={4}>
                    <Form.Item
                      label="ចំនួន"
                      name="qty"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        ref={inputQtyRef}
                        controls={false}
                        onChange={onChangeProductQty}
                        onKeyDown={onKeyDown}
                        placeholder="0"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={4}>
                    <Form.Item
                      label="តម្លៃរាយ"
                      name="price"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        onChange={onChangeProductPrice}
                        onKeyDown={onKeyDown}
                        placeholder="0"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={6}>
                    <Form.Item label="តម្លៃសរុប" name="amount">
                      <InputNumber
                        placeholder="0"
                        disabled
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={4} sm={3} md={3} lg={3}>
                    <Form.Item label=" ">
                      <Button
                        block
                        icon={<PlusOutlined />}
                        onClick={() => onCreateProduct()}
                        style={{
                          background: "#808080",
                          color: "white",
                        }}
                        shape="round"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    {/* { Object.entries(screens).filter(
                          (screen) => !!screen[1]
                        ).length } */}
                    <Table
                      rowKey="productId"
                      dataSource={saleDetail}
                      pagination={false}
                      bordered
                      columns={
                        Object.entries(screens).filter((screen) => !!screen[1])
                          .length > 2
                          ? columns
                          : columnsForMobile
                      }
                      // scroll={{ y: 240 }}
                      summary={() => {
                        const currentScreens = Object.entries(screens).filter(
                          (screen) => !!screen[1]
                        ).length;
                        return (
                          <>
                            <Table.Summary.Row>
                              {currentScreens > 2 ? (
                                <>
                                  <Table.Summary.Cell
                                    index={0}
                                    colSpan={currentScreens > 1 ? 3 : 1}
                                    align="right"
                                  >
                                    តម្លៃសរុប
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={1} align="right">
                                    <NumericFormat
                                      value={subTotal}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={" ៛"}
                                    />
                                  </Table.Summary.Cell>
                                </>
                              ) : (
                                <Table.Summary.Cell index={1} align="right">
                                  <Row gutter={8}>
                                    <Col xs={10}>តម្លៃសរុប</Col>
                                    <Col xs={14}>
                                      <NumericFormat
                                        value={subTotal}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        suffix={" ៛"}
                                      />
                                    </Col>
                                  </Row>
                                </Table.Summary.Cell>
                              )}
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                              {currentScreens > 2 ? (
                                <>
                                  <Table.Summary.Cell
                                    index={0}
                                    colSpan={currentScreens > 1 ? 3 : 1}
                                    align="right"
                                  >
                                    តម្លៃសរុបរួម
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell align="right">
                                    <NumericFormat
                                      value={totalAmount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={" ៛"}
                                    />
                                  </Table.Summary.Cell>
                                </>
                              ) : (
                                <Table.Summary.Cell align="right">
                                  <Row gutter={8}>
                                    <Col xs={10}>តម្លៃសរុបរួម</Col>
                                    <Col xs={14}>
                                      <NumericFormat
                                        value={totalAmount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        suffix={" ៛"}
                                      />
                                    </Col>
                                  </Row>
                                </Table.Summary.Cell>
                              )}
                            </Table.Summary.Row>
                          </>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        {/* Customer info */}
        <Col xs={24} sm={12} md={12}>
          <Card title={<div>ព័ត៌មានអតិថិជន</div>} bordered={false}>
            <Form
              {...formItemLayout}
              layout="vertical"
              form={formCustomer}
              name="formCustomer"
              // wrapperCol={{ span: 24 }}
              initialValues={{
                date: moment().format("YYYY-MM-DD HH:mm"),
                discount: null,
                deliveryPrice: null,
              }}
              validateMessages={validateMessages}
              scrollToFirstError
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <Row gutter={8}>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item label="កាលបរិច្ឆេទ" name="date">
                      <Input placeholder="Date" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      label="លេខអតិថិជន"
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9 ]*$/i),
                          message: "Please input only numbers",
                        },
                        {
                          required: true,
                          message: "Please input phone number!",
                        },
                      ]}
                    >
                      <Input placeholder="Phone Number" />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      label="ទីតាំង"
                      name="address"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Address" />
                      {/* <Input showCount maxLength={100} /> */}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item label="ចំណាំ" name="remark">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>

      <CustomerCreateEditModal
        value={customerCreateEditModal}
        onSuccess={(res) => onAddCustomerSuccess(res)}
        onCancel={() => onCloseCustomerModal()}
      />
    </>
  );
});

export default SaleFormPage;
