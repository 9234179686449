import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { message, Space, Table } from 'antd';
import Confirm from 'components/modals/Confirm';
import { Loading } from 'utils';
import CreateEditModal from './createEditModal';
import { DeleteButton, EditButton } from 'components/common/button/index';
import { useTranslation } from 'react-i18next';
import { joinString } from 'utils';

const TableForm = ({
  data,
  onLoadData
}) => {
  const { t, i18n } = useTranslation();
  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);
  const [editModal, setEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const headers = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      render: (_, record) => (
        <>{joinString([record.code, record.name])}</>
      )
    },
    {
      title: t('phoneNumber'),
      dataIndex: 'phoneNumber',
    },
    {
      title: t('label.price'),
      dataIndex: 'price',
    },
    roleAdmin == roleValue ?
    {
      title: 'Shared',
      dataIndex: 'isShared',
      render:(text, record) => (
        <>
          { record.isShared ? 'Yes' : 'No' }
        </>
      )
    } : null,
    {
      title: t('action.action'),
      dataIndex: 'action',
      key: 'action',
      fixed:"right",
      render:(text, record) => (
        <Space>
          {
            roleAdmin == roleValue ?
            <>
              <EditButton onClick={() => onEdit(record)} />
              <DeleteButton onClick={() => onDelete(record)} />
            </>
            :
            <>
              <EditButton onClick={() => onEdit(record)} disabled={record.isShared} />
            </>
          }
        </Space>
      )
    }
  ]

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
  }

  const onDelete = (item) => {
    Confirm({
      title: "Confirm Delete",
      okType: 'warning',
      async onOk () {
        try {
          Loading.setLoading(true);
          console.log('Yes')
          // TODO: Implement delete method
        } catch (error) {
          message.error(error.message);
        } finally {
          Loading.setLoading(false);
        }
      },
      onCancel () {}
    })
  }

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setEditModal(false);
  }

  const onCloseModal = () => {
    setCurrentItem(null);
    setEditModal(false);
  }

  return (
    <>
      <Table
        rowKey="id"
        size="small"
        dataSource={data}
        columns={headers.filter(x => x !== null)}
      />
      <CreateEditModal
        value={editModal}
        item={currentItem}
        isEdit
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  )
}

export default TableForm;