import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from 'components/modals/DisplayModal';
import SearchSaleForm from '../../components/SearchSale';

const AddSale = ({
  open,
  onSuccess,
  onCancel,
}) => {

  const { t, i18n } = useTranslation();
  const [sales, setSales] = useState([]);

  const onCancelModal = () => {
    onCancel();
  }

  const onConfirmMoveLine = () => {
    onSuccess(sales);
  }

  const onSelectedSales = async (values) => {
    console.log('Received values of form: ', values);
    setSales(values);
  };

  return (
    <>
      <CustomModal
        width={1200}
        visible={open}
        title={t('label.addSale')}
        saveButtonText='Confirm'
        onCancel={() => onCancelModal()}
        onOk={() => onConfirmMoveLine()}
        disableOk={sales.length > 0 ? false : true}
      >
        <SearchSaleForm
          isRowSelection={true}
          onSelectedSales={onSelectedSales}
          isClear={open}
        />
      </CustomModal>
    </>
  )
}

export default AddSale;