import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from "moment";
import { Riel } from 'components/special';
import { MoveSaleStatus } from 'pages/move-sale/components';
import { ADMIN_ROLE } from "constants/role.constant";

export function useColumns() {
  const roleValue = useSelector((state) => state.role.value);
  const { t, i18n } = useTranslation();
  return [
    {
      title: "№",
      dataIndex: "code",
    },
    {
      title: "កាលបរិច្ឆេទ",
      dataIndex: "date",
      responsive: ["xs", "sm"],
      render: (text, record) => (
        <div>{moment(record.date).format("YY-MM-DD")}</div>
      ),
    },
    ADMIN_ROLE.code == roleValue
      ? {
          title: "តំណាងចែងចាយ",
          dataIndex: "saleRepId",
          key: "saleRepId",
          render: (text, { saleRepId }) => (
            <div>{saleRepId && saleRepId.name}</div>
          ),
          responsive: ["sm"],
        }
      : null,
    {
      title: "លេខលំដាប់",
      dataIndex: "invoiceSequence",
    },
    {
      title: t("label.closingBalanceStatus"),
      dataIndex: "moveSaleStatus",
      responsive: ["sm"],
      render: (record) => (
        <>
          <MoveSaleStatus status={record} />
        </>
      ),
    },
    {
      title: t("label.printStatus"),
      dataIndex: "product",
      responsive: ["sm"],
      render: (sale, record) => (
        <div>
          {record.printCount > 0 ? (
            <Tag>{t("label.isPrinted")}</Tag>
          ) : (
            <Tag color="#f5222d">{t("label.isNotPrinted")}</Tag>
          )}
        </div>
      ),
    },
    {
      title: t("label.address"),
      dataIndex: "address",
      responsive: ["sm"],
    },
    {
      title: "តម្លៃក្រុមហ៊ុន",
      dataIndex: "totalWholesale",
      align: "right",
      responsive: ["xs", "sm"],
      render: (sale, record) => (
        <b>
          <Riel value={record.totalWholesale}/>
        </b>
      ),
    },
    {
      title: "តម្លៃសរុបរួម",
      dataIndex: "totalAmount",
      responsive: ["xs", "sm"],
      align: "right",
      render: (sale, record) => (
        <b>
          <Riel value={record.totalAmount}/>
        </b>
      ),
    },
    {
      title: t("action.operation"),
      key: "id",
      responsive: ["sm"],
      render: (sale, record) => (
        <>
  
        </>
      ),
    },
  ];
}
