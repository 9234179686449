import React, { useState, useEffect, useMemo } from "react";
import { Form, Row, Col, Select, Input, Table, message } from 'antd';
import CustomModal from 'components/modals/DisplayModal';
import { NumericFormat } from 'react-number-format';
import { Loading } from 'utils';
import { formatDate } from 'utils/index';
import axios from 'axios';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 23,
    },
  },
};

const ViewAndUpdatePayment = ({
  item = null,
  open = false,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentList, setPaymentList] = useState([]);

  const fetchPaymentMethod = async () => {
    const response = await axios.get(`api/payment-method`);
    if (response.data.code === 200) {
      setPaymentMethods(response.data.result);
    }
  };

  useEffect(() => {
    async function fetchData () {
      await fetchPaymentMethod();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        paymentAmount: item.totalAmount
      })
      setPaymentList(item.revenues || [])
    }
  }, [item]);

  const itemCode = useMemo(() => {
    if (item) {
      return item && item.code
    }
  }, [item]);

  const totalRevenue = useMemo(() => {
    if (item) {
      return item.totalRevenue;
    }
    return 0;
  }, [item]);

  const columnsPayment = [
    {
      title: 'Payment Method',
      width: 150,
      align: "center",
      dataIndex: 'paymentMethodName',
      key: 'paymentMethodName'
    },
    {
      title: 'Date',
      width: 150,
      align: "center",
      dataIndex: 'date',
      key: 'date',
      render: (value) => {
        return formatDate(value)
      }
    },
    {
      title: 'Note',
      width: 150,
      align: "center",
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Amount',
      width: 200,
      align: "right",
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => {
        return <b><NumericFormat value={value} displayType={'text'} thousandSeparator={true} suffix={' ៛'} /></b>;
      }
    }
  ]

  const submitPayment = async () => {
    try {
      Loading.setLoading(true);
      const formValues = await form.validateFields();
      const revenue = {
        paymentMethodId: formValues.paymentMethod.value,
        paymentMethodName: formValues.paymentMethod.label,
        paymentAmount: formValues.paymentAmount,
        paymentNote: formValues.paymentNote,
      }
      console.log('payment', revenue)
      const response = await axios.put(`api/sales/revenue/${item.id}`, revenue);
      if (response.data.code === 200) {
        message.info(`${response.data.message.message}`);
        onSuccess();
      }
      else {
        message.info('Failed to add payment.');
      }
      form.resetFields();
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  return (
    <CustomModal
      visible={open}
      onCancel={() => onCancel()}
      onOk={() => submitPayment()}
      title={`ការទូទាត់: ${itemCode}`}
    >
      <Form
        {...formItemLayout}
        form={form}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name='paymentMethod' 
              label='វិធី​សា​ស្រ្ត​ទូទាត់'
              rules={[{ required: true }]}
            >
              <Select
                labelInValue={true}
                showSearch
                placeholder='Select Payment Method'
                options={paymentMethods}
                fieldNames={{ label: 'name', value: 'id' }}
                filterOption={(input, option) => {
                  return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                }
              />
            </Form.Item>
            <Form.Item
              name='paymentAmount'
              label='ចំនួនទឹកប្រាក់'
              rules={[{ required: true }]}
            >
              <Input placeholder='Amount' />
            </Form.Item>
            <Form.Item
              name='paymentNote' 
              label='ចំណាំ'
            >
              <Input placeholder='Note' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Table
              size='middle'
              dataSource={paymentList}
              bordered
              // scroll={{ x: 700, y: 300 }}
              pagination={{ pageSize: 3 }}
              columns={columnsPayment}
              summary={() => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={3} align="right">សរុបដែលបានបង់</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1} align="right">
                      <b><NumericFormat value={totalRevenue} displayType={'text'} thousandSeparator={true} suffix={' ៛'} /></b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default ViewAndUpdatePayment;