import React, { useState, useEffect } from "react";
import axios from "axios";
import { Space, Button, Card, Row, Col, message, Form } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { StockAdjustmentMode } from 'constants/inventory/stock.constant';
import { Loading } from 'utils';
import StockAdjustmentModal from './stockAdjustmentModal';

import StockList from './list';
import { TablePagination, URL } from './stock.constant';
import { useTranslation } from 'react-i18next';

const StockPage = () => {
  const { t } = useTranslation();

  const [searchForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(TablePagination);
  const [stockAdjustmentMode, setStockAdjustmentMode] = useState(null);
  const [stockAdjustmentModal, setStockAdjustmentModal] = useState(false);

  const onCreateStockIn = () => {
    setStockAdjustmentMode(StockAdjustmentMode.ADD);
    setStockAdjustmentModal(true);
  }

  const onCreateStockOut = () => {
    setStockAdjustmentMode(StockAdjustmentMode.MINUS);
    setStockAdjustmentModal(true);
  }

  const onCloseModal = () => {
    setStockAdjustmentModal(false);
    setStockAdjustmentMode(null);
  }

  const onSuccess = async () => {
    Loading.setLoading(true);
    await fetchData(pagination);
    onCloseModal();
    Loading.setLoading(false);
  }

  const onTableChange = async (pagination) => {
    Loading.setLoading(true);
    await fetchData(pagination);
    Loading.setLoading(false);
  }

  useEffect(() => {
    async function fetch() {
      Loading.setLoading(true);
      await fetchData(pagination);
      Loading.setLoading(false);
    }
    fetch();
  }, []);

  const fetchData = async (paginationParm) => {
    try {
      const searchParam = {
        ...getSearchParam(),
        page: paginationParm && paginationParm.current,
        pageSize: paginationParm && paginationParm.pageSize
      };
      const response = await axios.post(URL + "/search", searchParam)
      if (response && response.data) {
        setData(response.data.result);
        setPagination({
          ...paginationParm,
          total: response.data.options.total
        });
      }
    } catch (error) {
      message.error(error.message);
      setData([]);
    }
  }
  const getSearchParam = () => {
    const data = searchForm.getFieldsValue(['name']);
    return {
      name: data.name ?? ''
    }
  }

  return (
    <>
      <PageHeader
        className='site-page-header'
        title={t('menu.stock')}
        extra={
          <Space>
            <Button
              onClick={() => onCreateStockIn()}
            >
              {t('label.stock_in')}
            </Button>
            <Button
              onClick={() => onCreateStockOut()}
            >
              {t('label.stock_out')}
            </Button>
          </Space>
        }
      />
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card size="small">
            <StockList
              data={data}
              pagination={pagination}
              onTableChange={onTableChange}
            />
          </Card>
        </Col>
      </Row>
      <StockAdjustmentModal
        value={stockAdjustmentModal}
        mode={stockAdjustmentMode}
        onCancel={() => onCloseModal()}
        onSuccess={() => onSuccess()}
      />
    </>
  )
}

export default StockPage;