import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Layout, Menu, Button, Drawer } from 'antd';
import { MobileMenu, MobileUserMenu, PrintOnlyMobileMenu } from 'layout/menu/index.constant.js';
import { UnorderedListOutlined,  } from '@ant-design/icons';
import { Roles, ADMIN_ROLE, USER_ROLE, PRINT_ROLE } from 'constants/role.constant';

const { Header } = Layout;

const MobileLayout = () => {

  const roleValue = useSelector((state) => state.role.value);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getMenu = () => {
    const findRole = Roles.find((t) => t.code === roleValue);
    switch(findRole.code) {
      case ADMIN_ROLE.code:
        return MobileMenu;
      case USER_ROLE.code:
        return MobileUserMenu;
      case PRINT_ROLE.code:
        return PrintOnlyMobileMenu;
      default:
        return [];
    }
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          height: 48,
          lineHeight: '32px',
          padding: 0
        }}
      >
        <Button
          style={{
            height: 48,
            width: 48
          }}
          size="large"
          onClick={showDrawer}
          icon={<UnorderedListOutlined />}
        />
      </Header>
      <Drawer
        placement='left'
        closable={false}
        onClose={onClose}
        open={open}
        width={200}
        bodyStyle={{
          padding: 0,
          backgroundColor: '#001529'
        }}
      >
        <Menu
          mode="inline"
          theme="dark"
          onClick={onClose}
          items={getMenu()}
        />
      </Drawer>
      <Layout style={{
        padding: 10,
        paddingTop: 15
      }}>
        <Outlet />
      </Layout>
    </Layout>
  )
}

export default MobileLayout;