import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";
import { Card, Form, Button, Row, Col, DatePicker, Select, Input, message, Checkbox } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';
import { FilterOutlined } from '@ant-design/icons';
import { exportSaleToExcel } from 'components/export/exportExcel';
import TableForm from './Table';
import PopupFilter from 'components/common/FilterPopup';
import ConfirmPostClosingBalance from './modal/ConfirmPostClosingBalance';
import { Loading } from 'utils';
import { API_URL, TablePagination } from './constant';
import './Sale.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const IndexForm = () => {

  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);
	const [searchForm] = Form.useForm();
  const { t, i18n } = useTranslation();

  const [confirmModel, setConfirmModel] = useState(false);
  const [confirmData, setConfirmData] = useState(null);
  const [data, setData] = useState([]);
  const [saleReps, setSaleReps] = useState([]);
  const [filterPopup, setFilterPopup] = useState(false);
  const [pagination, setPagination] = useState(TablePagination);
  const [options, setOptions] = useState({
    grandTotal: 0,
    grandTotalWholesale: 0,
    totalAmount: 0,
    totalWholesale: 0,
  });

  useEffect(() => {
    async function fetchData () {
      Loading.setLoading(true);
      await fetchSaleRep();
      await getSales();
      Loading.setLoading(false);
    }
    fetchData();
  }, []);

  const getSales = async (paginationParm) => {
    try {
      Loading.setLoading(true);
      const searchParam = {
        ...getSearchParam(),
        page: (paginationParm && paginationParm.current) || 1
      };
      const response = await axios.post(API_URL, searchParam);
      if (response && response.data) {
        setData(response.data && response.data.result);
        setOptions({
          grandTotal: response.data.options.grandTotal,
          grandTotalWholesale: response.data.options.grandTotalWholesale,
          totalAmount: response.data.options.totalAmount,
          totalWholesale: response.data.options.totalWholesale,
        })
        setPagination({
          ...paginationParm,
          total: response.data.options.total
        });
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      Loading.setLoading(false);
    }
  };

  const onReset = async () => {
    try {
      Loading.setLoading(true);
      searchForm.resetFields();
      await getSales();
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const getSearchParam = () => {
    const data = searchForm.getFieldsValue(['saleRepId', 'phoneNumber', 'code', 'startDate', 'endDate', 'isPrint', 'fromCode', 'toCode']);
    return {
      saleRepId: data.saleRepId ?? '',
      phoneNumber: data.phoneNumber ?? '',
      code: data.code ?? '',
      startDate: data.startDate ?? '',
      endDate: data.endDate ?? '',
      fromCode: data.fromCode ?? '',
      toCode: data.toCode ?? '',
      isPrint: data.isPrint,
    }
  }

  const fetchSaleRep = async () => {
    const response = await axios.get(`api/sale-reps`);
    if (response.data.code === 200) {
      setSaleReps(response.data.result);
    }
  };

  const onTableChange = async (pagination) => {
    await getSales(pagination);
  };

  const exportExcel = async () => {
    try {
      Loading.setLoading(true);
      await exportSaleToExcel();
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const onResetFilter = async () => {
    await getSales();
    setFilterPopup(false)
  }

  const onFilter = async () => {
    await getSales();
    setFilterPopup(false)
  }

  const onConfirm = async () => {
    const searchParam = getSearchParam();
    const response = await axios.post(API_URL, searchParam);
    if (response && response.data) {
      const obj = {
        data: {
          saleRepName: response.data.options.saleRepName,
          total: response.data.options.total,
          grandTotal :response.data.options.grandTotal,
          grandTotalWholesale: response.data.options.grandTotalWholesale,
        },
        searchData: searchParam
      }
      setConfirmModel(true);
      setConfirmData(obj);
    }
  }

  const onCancelConfirm = () => {
    setConfirmModel(false);
    setConfirmData(null);
  }

  const onUpdateSuccess = async () => {
    await getSales();
  }

  return (
    <>
      <PageHeader
        className='site-page-header'
        title={t('menu.saleList')}
      />
      <Card size="small">
        <Form
          {...formItemLayout}
          style={{ marginBottom: 0 }}
          form={searchForm}
        >
          <Row gutter={[8, 8]}>
            {
              roleAdmin == roleValue &&
              <Col xs={12} sm={3} md={3}>
                <Form.Item
                  name="saleRepId"
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    allowClear
                    placeholder="តំណាងចែងចាយ"
                  >
                    {saleReps.map((item, index) => (
                      <Option value={item.id} key={index}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            }
            <Col xs={12} sm={3} md={3}>
              <Form.Item
                name="code"
                style={{ marginBottom: 0 }}
              >
                <Input placeholder={t('invoiceSequence')} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item  style={{ marginBottom: 0 }} label="">
                <PopupFilter
                  visible={filterPopup}
                  placement="bottom"
                  resetText="ឡើងវិញ"
                  searchText={t('action.search')}
                  onClose={() => setFilterPopup(false)}
                  onReset={() => onResetFilter()}
                  onFilter={() => onFilter()}
                  button={
                    <Button
                      icon={<FilterOutlined />}
                      onClick={() => setFilterPopup(true)}
                    />
                  }
                  form={
                    <Form
                      style={{width: '250px'}}
                      form={searchForm}
                    >
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="phoneNumber"
                            style={{ marginBottom: 0 }}
                          >
                            <Input placeholder={t('phoneNumber')} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="startDate"
                            style={{ marginBottom: 0 }}
                          >
                            <DatePicker placeholder={t('label.fromDate')} style={{ display: 'block' }} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="endDate"
                            style={{ marginBottom: 0 }}
                          >
                            <DatePicker placeholder={t('label.toDate')} style={{ display: 'block' }} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="fromCode"
                            style={{ marginBottom: 0 }}
                          >
                            <Input placeholder='From Code' />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="toCode"
                            style={{ marginBottom: 0 }}
                          >
                            <Input placeholder='To Code' />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item style={{ marginBottom: 0 }} valuePropName="checked" name="isPrint">
                            <Checkbox>{t('label.isNotPrinted')}</Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  }
                >
                </PopupFilter>
                <Button
                  style={{ marginLeft: '4px' }}
                  type="primary"
                  onClick={() => getSales(pagination)}
                >
                  { t('action.search') }
                </Button>
                <Button
                  style={{ marginLeft: '4px' }}
                  onClick={() => onReset()}
                >
                  ឡើងវិញ
                </Button>
                {
                  roleAdmin == roleValue &&
                  <>
                    <Button
                      style={{
                        marginLeft: '4px'
                      }}
                      onClick={() => exportExcel()}
                    >
                      ទាញទិន្នន័យជាExcel
                    </Button>
                    <Button
                      style={{ marginLeft: '4px', color:"white", background: "#0a5d1e" }}
                      onClick={() => onConfirm()}
                    >
                      { t('action.postClosingBalance') }
                    </Button>
                  </>
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card size="small">
        <TableForm
          data={data}
          pagination={pagination}
          onTableChange={onTableChange}
          onLoadData={() => getSales(pagination)}
          options={options}
        />
      </Card>
      <ConfirmPostClosingBalance
          item={confirmData}
          open={confirmModel}
          onCancel={onCancelConfirm}
          onSuccess={() => onUpdateSuccess()}
        />
    </>
  );
}

export default IndexForm;