import React from "react";
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const IndexPage = ({ status }) => {

  const { t, i18n } = useTranslation();

  const renderSwitch = (param) => {
    switch(param) {
      case 'Approved':
        return <Tag color="#30CB83">{t('label.approved')}</Tag>;
      case 'Drafting':
        return <Tag color="#34495E">{t('label.drafting')}</Tag>;
      default:
        return <></>;
    }
  }

  return (
    <>
      { renderSwitch(status) }
    </>
  )
}

export default IndexPage;