import React from "react";
import { Layout } from "antd";
import FooterLayout from "./ui/footer";

import { Outlet } from "react-router-dom";

const { Content } = Layout;

const EmptyLayout = () => {
  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout>
          <Layout>
            <Content
              style={{
                padding: 16,
                margin: 0,
                // background: "blue",
                height: "calc(80vh - 55px)",
                minHeight: "80vh",
              }}
            >
              <Outlet />
            </Content>
            <FooterLayout />
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default EmptyLayout;
