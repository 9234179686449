export const DefaultSearchParam = Object.freeze({
  date: null,
  paymentMethodId: null,
  status: null
})

export const API_URL = 'api/sales/search'

export const TablePagination = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [10]
}
