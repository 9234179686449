import React, { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Form, message, Input, InputNumber, Checkbox } from "antd";
import { useTranslation } from 'react-i18next';
import { layoutConstant } from "constants/config/form-layout.constant";
import { Loading } from "utils";
import { URL } from "./constant";
import { CreateModal } from "components/modals/index";

const CreateEditModal = ({
  value = false,
  item = null,
  isEdit = false,
  onSuccess,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        name: formValue.name,
        code: formValue.code,
        phoneNumber: formValue.phoneNumber,
        price: formValue.price,
        isShared: formValue.isShared,
      };
      if (item && item.id) {
        await put(item.id, data);
      } else {
        await post(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const post = async (data) => {
    const response = await axios.post(URL, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success("This data added in System.");
    } else {
      message.info("Failed");
    }
  };

  const put = async (id, data) => {
    const response = await axios.put(`${URL}/${id}`, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success("This data added in System.");
    } else {
      message.info("Failed");
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        name: item.name,
        code: item.code,
        phoneNumber: item.phoneNumber,
        price: item.price,
        isShared: item.isShared,
      });
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={isEdit ? "កែប្រែ Delivery" : "បង្កើត Delivery"}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form form={form} {...layoutConstant}>
          <Form.Item name="code" label={ t('label.code') }>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label={ t('label.name') }
            rules={[
              {
                required: true,
                message: "Please input name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="phoneNumber" label={ t('label.phoneNumber') }>
            <Input />
          </Form.Item>
          <Form.Item name="price" label={ t('label.price') }>
            <InputNumber placeholder="0" style={{ width: "100%" }} />
          </Form.Item>
          {roleAdmin == roleValue && (
            <Form.Item
              name="isShared"
              valuePropName="checked"
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Checkbox>ប្រើរួមគ្នា</Checkbox>
            </Form.Item>
          )}
        </Form>
      </CreateModal>
    </>
  );
};

export default CreateEditModal;
