import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Layout, Menu } from "antd";
import {
  AdminMenu,
  StandardUserMenu,
  PrintOnlyUserMenu,
} from "layout/menu/index.constant.js";
import { Roles, ADMIN_ROLE, USER_ROLE, PRINT_ROLE } from "constants/role.constant";

const { Header } = Layout;

const HeaderLayout = () => {
  const roleValue = useSelector((state) => state.role.value);
  const [current, setCurrent] = useState("");

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const getMenu = () => {
    const findRole = Roles.find((t) => t.code === roleValue);
    switch(findRole.code) {
      case ADMIN_ROLE.code:
        return AdminMenu;
      case USER_ROLE.code:
        return StandardUserMenu;
      case PRINT_ROLE.code:
        return PrintOnlyUserMenu;
      default:
        return [];
    }
  };

  return (
    <>
      <Header>
        <div className="logo" />
        <Menu
          // style={{ backgroundColor: "#709597", color: "white" }}
          theme="dark"
          mode="horizontal"
          items={getMenu()}
          onClick={onClick}
          selectedKeys={[current]}
        />
      </Header>
    </>
  );
};

export default HeaderLayout;
