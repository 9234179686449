import React, { useState } from 'react';
import { Modal, Button, Spin } from 'antd';
import { useTranslation } from "react-i18next";

const DisplayModal = ({
  open,
  onOk,
  onCancel,
  children,
  isLoading = false,
  title = '',
  width = 700,
}) => {

  const { t, i18n } = useTranslation();

  return (
    <Modal
      open={open}
      width={width}
      title={title}
      okText={t('action.ok')}
      cancelText={t('action.cancel')}
      onCancel={onCancel}
      onOk={onOk}
      zIndex={2}
    >
      <Spin spinning={isLoading}>
        { children }
      </Spin>
    </Modal>
  );
}

export default DisplayModal;