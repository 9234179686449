import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";

// Company
import CompanyPage from "pages/company/index";
import CompanyDetailPage from "pages/company/detail/index";
import CompanyOrganizationPage from "pages/company/organization/index";

// Users
import UserPage from "pages/user/index";
import UserChangePasswordPage from "pages/user/changePassword";

// import IndexPage from 'pages/index';
import DashboardPage from "pages/dashboard/index";
import LoginPage from "pages/auth/login";
import SalePage from "pages/sales/index";
import SaleListPage from "pages/sales/list";
import DeliveryPage from "pages/setting/delivery";
import FacebookPages from "pages/setting/facebookPage";
import ExpenseTypePage from "pages/setting/expenseTypes";
import ExpenseIndexPage from "pages/expenses/index";
import RevenuePage from "pages/revenues/index.jsx";
import DailyClosingBalance from "pages/closingBalance/daily/index";
import MonthlyClosingBalance from "pages/closingBalance/monthly/index";
import ReportPage from "pages/report/index";
import OrganizationReportPage from "pages/organizationReport/index";
import MonthlyClosingBalanceEditPage from "pages/closingBalance/monthly/edit";
import AssetPage from "pages/asset";
import UiPage from "pages/test/ui";
import CustomLayoutPage from "pages/test/customLayout";

import StockPage from "pages/inventory/stock";
import StockDetailPage from "pages/inventory/stock/detail";
import StockAdjustmentPage from "pages/inventory/stockAdjustment";
import SupplierPage from "pages/inventory/supplier";

// Layout
import DefaultLayout from "layout/default";
import InventoryLayout from "layout/inventory";
import PurchaseOrderLayout from "layout/purchaseOrder";
import SettingLayout from "layout/setting";
import MobileLayout from "layout/mobile";
import EmptyLayout from "layout/empty";

// Accounting
import MoveSalePage from "pages/move-sale/index";
import MoveSaleDetailPage from "pages/move-sale/detail";
import MoveSaleClosingBalancePage from "pages/move-sale/closing-balance/Index";

// Inventory
import InventoryPage from "pages/inventory/index";
import SaleRepOrderPage from "pages/inventory/saleRepOrder";
import SaleRepOrderCreatePage from "pages/inventory/saleRepOrder/create";
import ProductPage from "pages/setting/products/index";
import CategoryPage from "pages/setting/category";
import CustomerPage from "pages/setting/customer";
import PaymentMethodPage from "pages/setting/paymentMethod";
import ProvincePage from "pages/setting/province";
import DistrictPage from "pages/setting/district";
import SaleRepPage from "pages/sale-rep";
import POSPage from "pages/pos";

// Purchase Order
import PurchaseOrderPage from "pages/purchase-order/index";
import CustomerPurchaseOrderPage from "pages/purchase-order/customer";
import CustomerPurchaseOrderCreatePage from "pages/purchase-order/customer/create";

import ErrorPage from "./ErrorPage";

export const Root = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route element={<EmptyLayout />}>
        <Route path="/pos" element={<POSPage />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/sale" element={<SalePage />} />
          <Route path="/sale-list" element={<SaleListPage />} />
          <Route path="/move-sale" element={<MoveSalePage />} />
          <Route
            path="/move-sale/detail/:id"
            element={<MoveSaleDetailPage />}
          />
          <Route
            path="/move-sale/closing-balance"
            element={<MoveSaleClosingBalancePage />}
          />
          <Route path="/sale-reps" element={<SaleRepPage />} />
          <Route path="/expense" element={<ExpenseIndexPage />} />
          <Route path="/revenue" element={<RevenuePage />} />
          <Route path="/customers" element={<CustomerPage />} />
          <Route path="/delivery" element={<DeliveryPage />} />
          <Route
            path="/closing-balance/daily"
            element={<DailyClosingBalance />}
          />
          <Route
            path="/closing-balance/monthly"
            element={<MonthlyClosingBalance />}
          />
          <Route
            path="/closing-balance/monthly/:id"
            element={<MonthlyClosingBalanceEditPage />}
          />
          <Route path="/user" element={<UserPage />} />
          <Route path="/change-password" element={<UserChangePasswordPage />} />
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/company/detail/:id" element={<CompanyDetailPage />} />
          <Route path="/company/organization/:id" element={<CompanyOrganizationPage />} />
          <Route path="/test/ui" element={<UiPage />} />
          <Route
            path="/organization-report"
            element={<OrganizationReportPage />}
          />
          <Route path="/report" element={<ReportPage />} />
        </Route>
        <Route element={<SettingLayout />}>
          <Route path="/settings/product" element={<ProductPage />} />
          <Route path="/settings/category" element={<CategoryPage />} />
          <Route path="/settings/customers" element={<CustomerPage />} />
          <Route path="/settings/delivery" element={<DeliveryPage />} />
          <Route path="/settings/facebookpages" element={<FacebookPages />} />
          <Route path="/settings/expensetype" element={<ExpenseTypePage />} />
          <Route
            path="/settings/payment-method"
            element={<PaymentMethodPage />}
          />
          <Route path="/settings/province" element={<ProvincePage />} />
          <Route path="/settings/district" element={<DistrictPage />} />
          <Route path="/settings/asset" element={<AssetPage />} />
        </Route>
        <Route element={<InventoryLayout />}>
          <Route path="/inventory" element={<InventoryPage />} />
          <Route
            path="/inventory/sale-rep-order"
            element={<SaleRepOrderPage />}
          />
          <Route
            path="/inventory/sale-rep-order/create"
            element={<SaleRepOrderCreatePage />}
          />
          <Route path="/inventory/stock" element={<StockPage />} />
          <Route
            path="/inventory/stock/detail/:id"
            element={<StockDetailPage />}
          />
          <Route
            path="/inventory/adjustment"
            element={<StockAdjustmentPage />}
          />
          <Route path="/inventory/supplier" element={<SupplierPage />} />
        </Route>
        <Route element={<PurchaseOrderLayout />}>
          <Route path="/purchase-order" element={<PurchaseOrderPage />} />
          <Route
            path="/purchase-order/customer"
            element={<CustomerPurchaseOrderPage />}
          />
          <Route
            path="/purchase-order/customer/create"
            element={<CustomerPurchaseOrderCreatePage />}
          />
        </Route>
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
