import React, { useEffect } from 'react';
import { Modal, Form, Input, message, Button, Row, Col, Select } from 'antd';
import axios from 'axios';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ReportSettingType } from '../../constants/report/report-setting-type.constant';

const { Option } = Select;

const CreateEditReportModal = ({ visible = false, onCancel, onOk, isEdit = false, item = undefined }) => {

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const [form] = Form.useForm();

  const create = async (data) => {
    const response = await axios.post('api/report', data)
    if (response && response.data && response.data.code && response.data.code === 200) {
      message.success('Added Successful')
      form.resetFields()
      onOk()
    } else {
      message.error(response.data.message.message)
    }
  }

  const update = async (data, id) => {
    const response = await axios.put(`api/report/${id}`, data)
    if (response && response.data && response.data.code && response.data.code === 200) {
      message.success('Update Successful')
      form.resetFields()
      onOk()
    } else {
      message.error(response.data.message.message)
    }
  }

  // reportSettingChange

  const submit = async () => {
    const data = {
      name: form.getFieldValue('name'),
      path: form.getFieldValue('path'),
      fileName: form.getFieldValue('file'),
      description: form.getFieldValue('description'),
      settings: form.getFieldValue('settings')
    }
    try {
      if (item && item.id) {
        update(data, item.id)
      } else {
        create(data)
      }
    } catch (error) {
      message.error(error)
    }
  }

  const close = () => {
    form.resetFields()
    onCancel()
  }

  useEffect(() => {
    if (isEdit && item) {
      form.setFieldsValue({
        name: item.name,
        path: item.path,
        file: item.fileName,
        description: item.description,
        settings: item.settings
      })
      console.log('form', form.getFieldsValue(["settings"]))
    }
  }, [isEdit, item, form])

  return (
    <>
      <Modal
        title={isEdit === true ? 'Edit' : 'Create'}
        visible={visible}
        width={1200}
        onCancel={close}
        onOk={submit}
      >
        <Form
          form={form}
          {...formItemLayout}
        >
          <Form.Item
            label="name"
            name="name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="path"
            name="path"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="fileName"
            name="file"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="description"
            name="description"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Setting"
          >
            <Form.List name="settings">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row gutter={[16, 16]}>
                      <Col span={22}>
                        <Row gutter={[16, 16]}>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              label="type"
                              name={[name, 'type']}
                            >
                              <Select
                                showSearch
                                value={form.getFieldValue('setting.type')}
                              >
                                {ReportSettingType.map((item) => (
                                  <Option value={item.value}>{item.text}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              label="position"
                              name={[name, 'position']}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              label="predefined"
                              name={[name, 'predefined']}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={2}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Setting
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateEditReportModal;