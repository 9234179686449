import React, { useState } from 'react';
import AppTable from 'components/common/table/AppTable'
import { Table, Space, Button, Modal, message } from 'antd';
import CreateExpenseModal from './createEdit';
// import NumberFormat from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';


import TableForm from 'pages/expenses/Table';

const { Column } = Table;
const { confirm } = Modal;

const MonthlyExpenseList = ({ data, onSuccess, monthlyClosingBalanceId = undefined, disableAction = false }) => {
  const [createModal, setCreateModal] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)
  const headers = [
    {
      title: 'Expense Type',
      dataIndex: 'expenseTypeName'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'right',
      render: (text, record) => (
        <b>
          <NumericFormat value={record.amount} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />               
        </b>
      )
    },
    { 
      title: 'Note',
      dataIndex: 'note'
    }
  ]

  const handleAddNewExpense = () => {
    setCreateModal(true)
  }

  const onEditExpense = (item) => {
    setCurrentItem(item)
    setCreateModal(true)
  }

  const onDeleteExpense = (item) => {
    confirm({
      title: 'Are you sure delete?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        const response = await axios.delete(`/api/closing-balance-monthly/expense/${monthlyClosingBalanceId}/${item._id}`)
        if (response.data.code === 200) {
          message.success('Delete Success')
        }
        else {     
          message.error('Delete Failed')
        }
        onSuccess();
      }
    });
  }

  const handleAddSuccess = () => {
    setCreateModal(false);
    setCurrentItem(null);
    onSuccess();
  }

  const handleClose = () => {
    setCreateModal(false);
    setCurrentItem(null);
  }

  return (
    <>
      {/* <Button
        type='primary'
        onClick={() => handleAddNewExpense()}
        disabled={disableAction}
        style={{
          marginBottom: 10
        }}
      >
        Add New
      </Button>
      <CreateExpenseModal
        visible={createModal}
        currentItem={currentItem}
        monthlyClosingBalanceId={monthlyClosingBalanceId}
        onSuccess={() => handleAddSuccess()}
        onCancel={() => handleClose()}
      /> */}
      <TableForm
        data={data}
      />
      {/* <AppTable
        items={data}
        headers={headers}
      >
        {{
          actions: 
          <Column
            title='Action'
            fixed="right"
            render={(item, record) => (
              <>
                <Space size="small">
                  <Button
                    disabled={disableAction}
                    onClick={() => onEditExpense(item)}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={disableAction}
                    onClick={() => onDeleteExpense(item)}
                  >
                    Delete
                  </Button>
                </Space>
              </>
            )}
          ></Column>
        }}
      </AppTable> */}
    </>
  )
}

export default MonthlyExpenseList;
