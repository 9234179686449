import React, { useState, useEffect } from 'react';
import { Space, Button, message, Table } from 'antd';
import axios from 'axios';
import { Loading } from 'utils';
import { SALE_REP_ORDER_URL, TablePagination } from './index.constant'

const SaleRepOrderList = () => {
  const [pagination, setPagination] = useState(TablePagination);
  const [saleRepOrderList, setSaleRepOrderList] = useState([])

  const headers = [
    {
      title: 'Code',
      dataIndex: 'code'
    },
    {
      title: 'Product',
      dataIndex: 'detail',
      render: (record) => {
        // return record.map(p => p.productName).join(', ')
      }
    },
    {
      title: 'Supplier',
      dataIndex: 'supplierId'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Delivery Status',
      dataIndex: 'deliveryStatus'
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (record) => {
        return (
          <>
            <Space>
              <Button
                size="small"
                onClick={() => onViewPurchaseOrder(record)}
              >
                View
              </Button>
              <Button
                size="small"
              >
                Receive
              </Button>
              <Button
                size="small"
              >
                Edit
              </Button>
              <Button
                danger
                size="small"
              >
                Delete
              </Button>
            </Space>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    async function fetch () {
      Loading.setLoading(true);
      await fetchSalesRepOrder(pagination);
      Loading.setLoading(false);
    };

    fetch();
  }, [])

  
  const onTableChange = async (pagination) => {
    Loading.setLoading(true);
    await fetchSalesRepOrder(pagination);
    Loading.setLoading(false);
  }

  const fetchSalesRepOrder = async (paginationParm) => {
    try {
      const searchParam = {
        ...getSearchParam(),
        page: paginationParm && paginationParm.current,
        pageSize: paginationParm && paginationParm.pageSize
      };
      const response = await axios.post(`${SALE_REP_ORDER_URL}/search`, searchParam)
      console.log('response', response)
      if (response && response.data) {
        setSaleRepOrderList(response.data.result);
        setPagination({
          ...paginationParm,
          total: response.data.options.total
        });
      }
    } catch (error) {
      message.error(error.message);
      setSaleRepOrderList([]);
    }
  }

  const getSearchParam = () => {
    // const data = searchForm.getFieldsValue(['name']);
    return {
      // name: data.name ?? ''
    }
  }

  const onViewPurchaseOrder = (item) => {
    navigate({
      pathname: `/inventory/sale-rep-order/view/${item.id}`
    });
  }

  return (
    <>
      <Table
        rowKey="id"
        size="small"
        dataSource={saleRepOrderList}
        columns={headers}
        pagination={pagination}
        onChange={onTableChange}
      />
    </>
  )
}

export default SaleRepOrderList;