import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import { Provider } from "react-redux";
import { store } from "./redux/index";
import "./i18n";
import AppPage from "App";

// import 'antd/dist/reset.css';

import reportWebVitals from "./reportWebVitals";

// Theme customization
import { ConfigProvider } from "antd";
import { theme } from "antd";
// const { defaultAlgorithm, defaultSeed } = theme;
// const mapToken = defaultAlgorithm(defaultSeed);

if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang", "en");
}

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.headers['x-access-token'] = token;
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;

axios.interceptors.request.use(
  (request) => {
    const deviceId = localStorage.getItem("deviceId");
    const token = localStorage.getItem("token");
    if (token) {
      request.headers["x-access-token"] = token;
    }

    if (deviceId) {
      request.headers["x-request-id"] = deviceId;
    }
    // Edit request config
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error && error.response && error.response.status === 401) {
      try {
        const refreshToken = localStorage.getItem("refresh_token");
        const response = await axios.post("api/auth/refreshtoken", {
          refreshToken,
        });
        const result = response.data.result;
        localStorage.setItem("token", result.accessToken);
        localStorage.setItem("refresh_token", result.refreshToken);
        return axios.request(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }

    if (error && error.response && error.response.status === 440) {
      try {
        console.log("response status 440");
        localStorage.setItem("token", "");
        window.location.href = "/login";
      } catch (error) {
        return Promise.reject(error);
      }
    }

    if (error && error.response && error.response.status === 403) {
      try {
        console.log("response status 403");
        localStorage.setItem("token", "");
        window.location.href = "/login";
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            // ...mapToken,
            // colorPrimary: '#709597',
            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', Helvetica, Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            'Siemreap', 'Roboto'`,
          },
        }}
      >
        <AppPage />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
