import React from 'react';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useNavigate } from 'react-router-dom';
import SaleRepOrderList from './list';

const SaleRepOrderPage = () => {
  const navigate = useNavigate();

  const onCreateSaleRepOrder = () => {
    navigate({
      pathname: `/purchase-order/customer/create`
    });
  }

  return (
    <>
      <PageHeader
        className='site-page-header'
        title='Sale Rep Order'
        extra={
          <Button
            onClick={() => {onCreateSaleRepOrder()}}
          >
            Create
          </Button>
        }
      />
      <SaleRepOrderList />
    </>
  )
}

export default SaleRepOrderPage;