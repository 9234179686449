import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Tabs, Select, Button, message, Form, Row, Col, Input, Card, InputNumber, Grid, Table, Tooltip, Space, Divider } from 'antd';
import { DeleteOutlined, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { NumericFormat } from 'react-number-format';
import CustomModal from 'components/modals/DisplayModal';
import { Loading } from 'utils';

const { useBreakpoint } = Grid;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const ViewAndUpdateProduct = ({
  item = null,
  open = false,
  onCancel,
  onSuccess
}) => {

  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);
  const [form] = Form.useForm();
  const [productForm] = Form.useForm();
  const discount = Form.useWatch('discount', form);
  const deliveryPrice = Form.useWatch('deliveryPrice', form);

  const [saleReps, setSaleReps] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentProducts, setCurrentProducts] = useState([]);

  const screens = useBreakpoint();

  const canEdit = useMemo(() => {
    return roleAdmin == roleValue ? true : false
  }, [])

  const title = useMemo(() => {
    if (item) {
      return item && item.code
    }
  }, [item]);

  const subTotal = useMemo(() => {
    return currentProducts.reduce((p, c) => p + c.amount, 0);
  }, [currentProducts])

  const totalAmount = useMemo(() => {
    return subTotal + deliveryPrice - discount
  }, [subTotal, deliveryPrice, discount])

  const productTableColumns = [
    {
      title: "ឈ្មោះទំនិញ",
      responsive: ["xs"],
      render: (record) => (
        <Row gutter={8} justify="space-around" align="middle">
          <Col span={12}>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteProduct(product)}
            />
          </Col>
          <Col span={12}>
            {record.productName}
          </Col>
        </Row>
      ),
    },
    {
      title: 'តម្លៃរាយ',
      // dataIndex: 'qty',
      align: "right",
      responsive: ['xs'],
      render: (record) => (
        <div>
          {record.qty} * {record.price}
        </div>
      ),
    },
    {
      title: 'តម្លៃសរុប',
      dataIndex: 'amount',
      align: "right",
      responsive: ['xs'],
      ellipsis: {
        showTitle: false,
      },
    },
    // Web
    {
      title: 'ឈ្មោះទំនិញ',
      dataIndex: 'productName',
      ellipsis: {
        showTitle: false,
      },
      responsive: ['sm'],
      render: (productName) => (
        <Tooltip placement="topLeft" title={productName}>
          {productName}
        </Tooltip>
      ),
    },
    {
      title: 'ចំនួន',
      dataIndex: 'qty',
      align: "right",
      responsive: ['sm'],
    },
    {
      title: 'តម្លៃរាយ',
      dataIndex: 'price',
      align: "right",
      responsive: ['sm'],
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'តម្លៃសរុប',
      dataIndex: 'amount',
      align: "right",
      responsive: ['sm'],
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: '',
      align: "center",
      responsive: ['sm'],
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Space size="middle">
          {
            roleAdmin == roleValue &&
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteProduct(record)}
            />
          }
        </Space>
      )
    }
  ];

  const fetchSaleRep = async () => {
    const response = await axios.get(`api/sale-reps`);
    if (response.data.code === 200) {
      setSaleReps(response.data.result);
    }
  };
  const fetchCustomer = async () => {
    const response = await axios.get(`api/customers`);
    if (response.data.code === 200) {
      setCustomers(response.data.result);
    }
  };
  const fetchDelivery = async () => {
    const response = await axios.get(`api/deliveries`);
    if (response.data.code === 200) {
      setDeliveries(response.data.result);
    }
  };
  const onLoadProduct = async () => {
    const response = await axios.get(`api/products`);
    if (response.data.code === 200) {
      setProducts(response.data.result);
    }
  };

  useEffect(() => {
    async function fetchData () {
      await onLoadProduct();
      await fetchSaleRep();
      await fetchCustomer();
      await fetchDelivery();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item)
      if (item.detail) {
        setCurrentProducts(item.detail)
      }
    }
  }, [item]);

  const onSelectedDelivery = async (value) => {
    const findDelivery = deliveries.find(item => item.id === value);
    form.setFieldsValue({
      deliveryPrice: findDelivery.price ? findDelivery.price : 0,
      deliveryMobilePhone: findDelivery.phoneNumber,
    });
  }

  const onSelectedProduct = async (value) => {
    const findProduct = products.find(item => item.id === value.key);
    productForm.setFieldsValue({
      price: findProduct.retailPrice,
    });
    const values = await productForm.validateFields();
    productForm.setFieldsValue({
      amount: values.qty * findProduct.retailPrice
    });
  }

  const onChangeProductQty = async (value) => {
    if (value) {
      const values = await productForm.validateFields();
      productForm.setFieldsValue({
        qty: value,
        price: values.price,
        amount: value * values.price
      });
    }
  }

  const onChangeProductPrice = async (value) => {
    if (value) {
      const values = await productForm.validateFields();
      productForm.setFieldsValue({
        price: value,
        amount: value * values.qty
      });
    }
  }

  const onAddProduct = async () => {
    try {
      const values = await productForm.validateFields();
      const findProduct = products.find(item => item.id === values.product.key);
      const findSaleDetail = currentProducts.find(item => item.productId === values.product.key);
      if (!findSaleDetail){
        const selectedProdct = {
          productId: findProduct.id,
          productName: findProduct.name,
          price: values.price,
          qty: values.qty,
          amount: values.qty * values.price,
        }
        setCurrentProducts(currentProducts => [...currentProducts, selectedProdct]);
      }
      productForm.resetFields();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onDeleteProduct = (value) => {
    const updateSaleDetail = currentProducts.filter(item => item.productId !== value.productId);
    setCurrentProducts(updateSaleDetail);
  };

  const onUpdateSale = async () => {
    try {
      Loading.setLoading(true);
      const info = await form.validateFields();
      const obj = {
        invoiceSequence: info.invoiceSequence,
        customerId: info.customerId,
        phoneNumber: info.phoneNumber,
        address: info.address,
        deliveryId: info.deliveryId,
        deliveryPrice: info.deliveryPrice,
        deliveryMobilePhone: info.deliveryMobilePhone,
        discount: info.discount,
        remark: info.remark,
        detail: currentProducts,
        saleRepId: info.saleRepId && info.saleRepId.id,
      }
      const response = await axios.put(`api/sales/${item.id}`, obj);
      if (response.data.code === 200) {
        message.success(`${response.data.message.message}`);
        onSuccess();
        onCancel();
      }
      else {
        message.info('Failed to update');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  return (
    <>
      <CustomModal
        width={1000}
        visible={open}
        title={`Detail: ${title}`}
        onCancel={() => onCancel()}
        onOk={() => onUpdateSale()}
        disableOk={!canEdit}
      >
        <Form
          {...formItemLayout}
          form={form}
        >
          <Row aria-label='Information'>
            <Col xs={24} sm={12} md={12}>
              {/* Invoice-number */}
              <Form.Item label="លេខ​វិ​ក័​យ​ប័ត្រ" name="invoiceSequence" rules={[{ required: true }]}>
                <InputNumber
                  style={{ width: "100%" }}
                />
              </Form.Item>

              {/* Sales-Rep */}
              <Form.Item
                label="តំណាងចែងចាយ"
                name={['saleRepId', 'id']}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder='Please select sale rep`s'
                  fieldNames={{ label: 'name', value: 'id'}}
                  filterOption={(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  }
                  options={saleReps}
                />
              </Form.Item>

              {/* Delivery */}
              <Form.Item
                label="ដឹកជញ្ជូន"
                name="deliveryId"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder='Delivery'
                  onChange={onSelectedDelivery}
                  fieldNames={{ label: 'name', value: 'id'}}
                  filterOption={(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  }
                  options={deliveries}
                />
              </Form.Item>

              {/* Delivery-Phonenumber */}
              <Form.Item
                label="លេខអ្នកដឹកជញ្ជូន"
                name="deliveryMobilePhone"
                rules={[
                  {
                    pattern: new RegExp(
                      /^[0-9 ]*$/i
                    ),
                    message: "Please input only numbers"
                  },
                ]}
              >
                <Input placeholder='010******' />
              </Form.Item>

              {/* Delivery-Price */}
              <Form.Item label="តម្លៃដឹកជញ្ជូន" name="deliveryPrice" rules={[{ required: true }]}>
                <InputNumber
                  // placeholder='0'
                  addonAfter="រៀល"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              {/* Customer */}
              <Form.Item
                label="អតិថិជន"
                name="customerId"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder='Please select customer'
                  fieldNames={{ label: 'name', value: 'id'}}
                  filterOption={(input, option) => {
                    return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  }
                  options={customers}
                />
              </Form.Item>

              {/* Customer-Phonenumber */}
              <Form.Item
                label="លេខអតិថិជន"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^[0-9 ]*$/i
                    ),
                    message: "Please input only numbers"
                  },
                  { required: true, message: 'Please input your username!' }
                ]}
              >
                <Input placeholder='Phone Number'/>
              </Form.Item>

              {/* Location */}
              <Form.Item label="ទីតាំង" name="address" rules={[{ required: true }]}>
                <Input placeholder='Address' />
              </Form.Item>

              {/* Remark */}
              <Form.Item label="ចំណាំ" name="remark">
                <Input placeholder=""/>
              </Form.Item>

              {/* Discount */}
              <Form.Item label="បញ្ចុះតម្លៃ" name="discount">
                <InputNumber
                  addonAfter="រៀល"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {/* Product */}
        <Row gutter={[16, 16]} aria-label='Product'>
          <Col span={24}>
            {
              roleAdmin == roleValue &&
              <Form
                layout='vertical'
                form={productForm}
                initialValues={{
                  qty: 1,
                }}
                validateMessages={validateMessages}
              >
                <Row gutter={8}>
                  <Col xs={10} sm={7} md={7}>
                    <Form.Item label="ទំនិញ" name="product" rules={[{ required: true }]}>
                      <Select
                        showSearch
                        placeholder='Product'
                        labelInValue={true}
                        fieldNames={{ label: 'name', value: 'id'}}
                        filterOption={(input, option) => {
                          return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        }
                        options={products}
                        onChange={onSelectedProduct}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={7} sm={4} md={4}>
                    <Form.Item label="ចំនួន" name="qty" rules={[{ required: true }]}>
                      <InputNumber
                        onChange={onChangeProductQty}
                        // onKeyDown={onKeyDown}
                        placeholder='1'
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={7} sm={4} md={4}>
                    <Form.Item label="តម្លៃរាយ" name="price" rules={[{ required: true }]}>
                      <InputNumber
                        onChange={onChangeProductPrice}
                        // onKeyDown={onKeyDown}
                        placeholder='0'
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={17} sm={6} md={6}>
                    <Form.Item label="តម្លៃសរុប" name="amount">
                      <InputNumber
                        placeholder='0'
                        disabled
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={7} sm={3} md={3}>
                    <Form.Item label=" ">
                      <Button
                        block
                        icon={<PlusOutlined/>}
                        onClick={() => onAddProduct()}
                        style={{
                          background: "#808080",
                          color: "white",
                        }}
                        shape="round"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            }
          </Col>
          <Col>
            <Table
                rowKey="productId"
                size='small'
                dataSource={currentProducts}
                pagination={false}
                bordered
                columns={productTableColumns}
                scroll={{ y: 200 }}
                summary={() => {
                  const currentScreens = Object.entries(screens).filter(screen => !!screen[1]).length;
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={currentScreens > 1 ? 3 : 2} align="right">តម្លៃសរុប</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} align="right">
                          <NumericFormat value={subTotal} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />   
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={currentScreens > 1 ? 3 : 2} align="right">តម្លៃដឹកជញ្ជូន</Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <NumericFormat value={deliveryPrice} displayType="text" thousandSeparator="," suffix={' ៛'} />
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={currentScreens > 1 ? 3 : 2} align="right">បញ្ចុះតម្លៃ</Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <NumericFormat value={discount} displayType="text" thousandSeparator="," suffix={' ៛'} />
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell  index={0} colSpan={currentScreens > 1 ? 3 : 2} align="right">តម្លៃសរុបរួម</Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <NumericFormat value={totalAmount} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
          </Col>
        </Row>
      </CustomModal>
    </>
  )
}

export default ViewAndUpdateProduct;