import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: '',
  values: [],
  superAdmin: 'ROLE_SUPER_ADMIN',
  admin: 'ROLE_ADMIN',
  user: 'ROLE_USER',
  printOnly: 'ROLE_PRINT_ONLY',
}

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.value = action.payload
    },
    setRoles: (state, action) => {
      state.values = action.payload
    },
    reset: () => initialState
  },
})

// Action creators are generated for each case reducer function
export const { setRole, setRoles, reset } = roleSlice.actions

export default roleSlice.reducer;
