import React, { useState, useEffect } from 'react';
import { Form, message, Input, Select, Checkbox } from 'antd';
import { layoutConstant } from 'constants/config/form-layout.constant';
import { Loading } from 'utils';
import { SALE_REP_URL, PROVINCE_URL, DISTRICT_URL } from 'constants/api';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { CreateModal } from "components/modals/index";

const CreateEditModal = ({
  value = false,
  item = null,
  isEdit = false,
  onSuccess,
  onCancel
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const fetchProvinces = async () => {
    const response = await axios.get(PROVINCE_URL);
    const result = (response.data && response.data.result) || []
    setProvinces(result);
  }

  const fetchDistricts = async () => {
    const response = await axios.get(DISTRICT_URL);
    const result = (response.data && response.data.result) || []
    setDistricts(result);
  }

  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      const formValue = await form.validateFields();
      const data = {
        provinceId: formValue.provinceId,
        districtId: formValue.districtId,
        name: formValue.name,
        shopName: formValue.shopName,
        phoneNumber: formValue.phoneNumber,
        otherNumber: formValue.otherNumber,
        isSeqByDay: formValue.isSeqByDay
      }
      if (item && item.id) {
        await put(item.id, data);
      } else {
        await post(data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const post = async (data) => {
    const response = await axios.post(SALE_REP_URL, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {
      message.info('Failed');
    }
  }

  const put = async (id, data) => {
    const response = await axios.put(`${URL}/${id}`, data);
    if (response.data.code === 200) {
      form.resetFields();
      onSuccess();
      message.success('This data added in System.');
    }
    else {
      message.info('Failed');
    }
  }

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  }

  useEffect(() => {
    async function fetch () {
      await fetchProvinces();
      await fetchDistricts();
    };
    fetch();
  }, []);

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        provinceId: item.provinceId,
        districtId: item.districtId,
        name: item.name,
        shopName: item.shopName,
        phoneNumber: item.phoneNumber,
        otherNumber: item.otherNumber,
        isSeqByDay: item.isSeqByDay
      })
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={isEdit ? `${t('action.edit')} ${t('menu.saleRep')}` : `${t('action.create')} ${t('menu.saleRep')}`}
        value={value}
        onOk={() => onSubmit()}
        onCancel={() => onCancelModal()}
      >
        <Form
          form={form}
          {...layoutConstant}
        >
          <Form.Item
            name="name"
            label='Name'
            rules={[
              {
                required: true,
                message: 'Please input name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="shopName"
            label='Shop Name'
            rules={[
              {
                required: true,
                message: 'Please input shop name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label='Phone Number'
            rules={[
              {
                required: true,
                message: 'Please input Phone Number!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="otherNumber"
            label='Other Number'
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="isSeqByDay"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Seq By Day</Checkbox>
          </Form.Item>
          <Form.Item
            name="provinceId"
            label="Province"
            disabled
          >
            <Select
              showSearch
              placeholder='Province'
            >
              {provinces.map((option, index)=> (
                <Option key={index} value={option.id}>{option.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="districtId"
            label="District"
            disabled
          >
            <Select
              showSearch
              placeholder='District'
            >
              {districts.map((option, index)=> (
                <Option key={index} value={option.id}>{option.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </CreateModal>
    </>
  )
};

export default CreateEditModal;