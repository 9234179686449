import React from "react";
import { NumericFormat } from 'react-number-format';

const IndexPage = ({ value }) => {

  return (
    <>
      <NumericFormat value={value} displayType={'text'} thousandSeparator={true} suffix={' ៛'} />
    </>
  )
}

export default IndexPage;