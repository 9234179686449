import React, { useState, useEffect } from 'react'
import { Tabs, message, Card } from 'antd';
import MonthlyClosingBalanceList from './list';
import axios from 'axios';
import { Typography } from 'antd';
import { Loading } from 'utils';

const { Title } = Typography;

const { TabPane } = Tabs;

const MonthlyClosingBalance = () => {
  const [closingBalanceList, setClosingBalanceList] = useState([])
  const [currentMonthlyClosingBalanceItem, setCurrentMonthlyClosingBalanceItem] = useState(null)
  const [tab, setTab] = useState('1')

  const handleMonthlyClosingBalanceView = (item) => {
    setCurrentMonthlyClosingBalanceItem(item);
    setTab('2');
  }
  const handleAfterCloseForm = () => {
    setCurrentMonthlyClosingBalanceItem(null);
    // setTab('1')
  }

  const handleRefreshList = async () => {
    await fetchData();
  }
  
  const fetchData = async () => {
    try {
      Loading.setLoading(true);
      const response = await axios.get('api/closing-balance-monthly')
      if (response) {
        setClosingBalanceList(response.data.result)
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <Card>
        <Title level={3}>Monthly Closing Balance</Title>
        <MonthlyClosingBalanceList
          data={closingBalanceList}
          onView={(item) => handleMonthlyClosingBalanceView(item)}
        />
      </Card>
    </>
  )
}

export default MonthlyClosingBalance;