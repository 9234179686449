import React, { useState } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Typography,
  Layout,
  message,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChangePasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const { Title } = Typography;
  const navigate = useNavigate();
  const username = JSON.parse(localStorage.getItem("user")).username;

  const { t } = useTranslation();

  const onFinish = async (values) => {
    if (username !== null && username !== undefined) {
      setLoading(true);
      try {
        const data = JSON.stringify({
          username: username,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        });
        const response = await axios.post(`api/users/change-password`, data);
        if (response.data.status === 200) {
          localStorage.setItem("token", "");
          localStorage.setItem("user", JSON.stringify({}));
        }
        message.success(response.data.message, 2);
        setLoading(false);
        navigate("/login");
      } catch {
        message.error("Incorrect Old Password.", 2);
        setLoading(false);
      }
    } else {
      message.error("User not Found.");
    }
  };

  return (
    <>
      <Layout>
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ minHeight: "50vh" }}
        >
          <Col xs={20} sm={16} md={12} lg={8} xl={6}>
            <Card>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Title level={4}>{username}</Title>
                <Form.Item
                  name="oldPassword"
                  rules={[
                    { required: true, message: "Please input your password" },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="New Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loading}
                  >
                    {t("menu.changePassword")}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default ChangePasswordPage;