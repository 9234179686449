import React, { useEffect, useState } from 'react';
import { Statistic, Descriptions, Button, message, Form, Row, Col, Input, Card, Tabs, Typography } from 'antd';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { formatDate } from 'utils/index';
import { ClosingBalanceStatus } from 'constants/status';
import MonthlyExpenseList from './expense/index';
import { useNavigate } from 'react-router-dom';
import MonthlyIncomePage from './monthlyIncome';

const { Title } = Typography;

const { TabPane } = Tabs;

const MonthlyClosingBalanceEditPage = ({  }) => {
  const [calculateForm] = Form.useForm();
  const { id } = useParams();
  const [closingBalanceData, setClosingBalanceData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getCurrentClosingBalanceData(id)
    }
  }, [id])

  const refreshData = async () => {
    await getCurrentClosingBalanceData(id)
  }

  const getCurrentClosingBalanceData = async (id) => {
    try {
      const response = await axios.get(`api/closing-balance-monthly/${id}`);
      const result = response.data.result;
      calculateForm.setFieldsValue({
        codeFrom: result.codeFrom,
        codeTo: result.codeTo
      });
      setClosingBalanceData(result);
    } catch (error) {
      // message.error(error.message);
      setClosingBalanceData(null);
    }
  }

  const postCalculateClosingBalanceMonthly = async () => {
    try {
      const data = {
        codeFrom: calculateForm.getFieldValue('codeFrom'),
        codeTo: calculateForm.getFieldValue('codeTo')
      };
      const response = await axios.put(`/api/closing-balance-monthly/${closingBalanceData.id}`, data);
      const responseData = response.data.result;
      if (response.data.code === 200) {
        setClosingBalanceData(responseData);
      } else {
        setClosingBalanceData(null)
        message.error('Failed to calculate');
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  const submitClosingBalanceMonthly = async () => {
    try {
      const response = await axios.put(`api/closing-balance-monthly/approve/${closingBalanceData.id}`)
      if (response.data.code === 200) {
        message.success('Save Success');
        navigate({
          pathname: `/closing-balance/monthly`
        })
      } else {
        message.error('Failed');
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  const RenderContent = ({ createdBy, createdDate, note, codeFrom = 0, codeTo = 0, isEditing = false }) => (
    <Descriptions size="small" column={1}>
      <Descriptions.Item>
        Code: {codeFrom} - {codeTo}
      </Descriptions.Item>
      <Descriptions.Item label="Date">
        {createdDate}
      </Descriptions.Item>
    </Descriptions>
  );

  const CalculateForm = () => {
    return (
      <Form
        layout="vertical"
        form={calculateForm}
      >
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item
              label='Code From'
              name="codeFrom"
            >
              <Input
                placeholder='Code From'
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label='Code To'
              name="codeTo"
            >
              <Input
                placeholder='Code To'
                type="number"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Action"
            >
              <Button
                style={{
                  margin: '0 8px 0 0',
                }}
                disabled={closingBalanceData && closingBalanceData.status === ClosingBalanceStatus.APPROVED}
                onClick={() => postCalculateClosingBalanceMonthly()}
              >
                Re-Calculate
              </Button>
              <Button
                style={{
                  margin: '0 8px 0 0',
                }}
                disabled={closingBalanceData && closingBalanceData.status === ClosingBalanceStatus.APPROVED}
                onClick={() => submitClosingBalanceMonthly()}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  const ExtraContent = ({ status, totalExpense, totalRevenue, income }) => (
    <div
      style={{
        display: 'flex',
        width: 'max-content',
        justifyContent: 'flex-end',
      }}
    >
      <Statistic
        title="Status"
        value={status}
        style={{
          marginRight: 32,
        }}
      />
      <Statistic
        title="Revenue"
        prefix="៛"
        value={totalRevenue}
        valueStyle={{ color: '#3f8600' }}
        style={{
          marginRight: 32,
        }}
      />
      <Statistic
        title="Expense"
        prefix="៛"
        value={totalExpense}
        valueStyle={{ color: '#cf1322' }}
        style={{
          marginRight: 32,
        }}
      />
      <Statistic
        title="Income"
        prefix="៛"
        value={income}
      />
    </div>
  );

  return (
    <>
      <Card>
        <Title level={3}>Closing Balance</Title>
        <CalculateForm />
        { closingBalanceData ? 
          <>
            <RenderContent
              codeFrom={closingBalanceData.codeFrom}
              codeTo={closingBalanceData.codeTo}
              createdBy=""
              createdDate={formatDate(closingBalanceData.createdAt)}
              note=""
            />
            <ExtraContent
              status={closingBalanceData.status}
              totalExpense={closingBalanceData.totalExpense}
              totalRevenue={closingBalanceData.totalRevenue}
              income={closingBalanceData.income}
            />
            <Tabs defaultActiveKey="1">
              <TabPane tab="Monthly Income" key="1">
                <MonthlyIncomePage
                  data={closingBalanceData.revenues}
                />
              </TabPane>
              <TabPane tab="Monthly Expense" key="2">
                <MonthlyExpenseList
                  data={closingBalanceData.expenses}
                  monthlyClosingBalanceId={closingBalanceData.id}
                  disableAction={closingBalanceData && closingBalanceData.status === ClosingBalanceStatus.APPROVED}
                  onSuccess={() => refreshData()}
                />
              </TabPane>
            </Tabs>
          </>
          :
          <></>
        }
      </Card>
    </>
  )
}

export default MonthlyClosingBalanceEditPage;