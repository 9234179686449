import React, { useState, useEffect, useMemo } from "react";
import { Form, Row, Col, Select, Input, Table, message } from 'antd';
import CustomModal from 'components/modals/DisplayModal';
import { NumericFormat } from 'react-number-format';
import { Loading } from 'utils';
import { formatDate } from 'utils/index';
import axios from 'axios';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 23,
    },
  },
};

const ViewAndUpdateExpense = ({
  item = null,
  open = false,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [expenseList, setExpenseList] = useState([]);

  const fetchExpenseType = async () => {
    const response = await axios.get(`api/expense-type?type=Sale`);
    if (response.data.code === 200) {
      setExpenseTypes(response.data.result);
    }
  }

  useEffect(() => {
    async function fetchData () {
      await fetchExpenseType();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item)
      setExpenseList(item.expenses || [])
    }
  }, [item]);
  
  const itemCode = useMemo(() => {
    if (item) {
      return item && item.code
    }
  }, [item]);

  const totalExpense = useMemo(() => {
    if (item) {
      return item.totalExpense;
    }
    return 0;
  }, [item]);

  const columnsExpense = [
    {
      title: 'Expense Type',
      width: 150,
      align: "center",
      dataIndex: 'expenseTypeName',
      key: 'expenseTypeName',
    },
    {
      title: 'Date',
      width: 150,
      align: "center",
      dataIndex: 'date',
      key: 'date',
      render: (value) => {
        return formatDate(value)
      }
    },
    {
      title: 'Note',
      width: 150,
      align: "center",
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Amount',
      width: 200,
      dataIndex: 'amount',
      align: "right",
      key: 'amount',
      render: (value) => {
        return <b><NumericFormat value={value} displayType={'text'} thousandSeparator={true} suffix={' ៛'} /></b>;
      }
    },
  ];
  
  const submitExpense = async () => {
    try {
      Loading.setLoading(true);
      const formValues = await form.validateFields();
      const expense = {
        expenseTypeId: formValues.expenseType.value,
        expenseTypeName: formValues.expenseType.label,
        amount: formValues.expenseAmount,
        note: formValues.expenseNote,
      }
      const response = await axios.post(`api/sales/expense/${item.id}`, expense);
      if (response.data.code === 200) {
        message.info(`${response.data.message.message}`);
        onSuccess();
      }
      else {     
        message.info('Failed to add expense.');
      }
      form.resetFields(); 
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  return (
    <CustomModal
      visible={open}
      onCancel={() => onCancel()}
      onOk={() => submitExpense()}
      title={`ការចំណាយ: ${itemCode}`}
    >
      <Form
        {...formItemLayout}
        form={form}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name='expenseType' 
              label='ប្រភេទចំណាយ'
              rules={[{ required: true }]}
            >
              <Select
                labelInValue={true}
                showSearch
                placeholder='Select Expense Type'
                options={expenseTypes}
                fieldNames={{ label: 'name', value: 'id' }}
                filterOption={(input, option) => {
                  return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                }
              />
            </Form.Item>
            <Form.Item
              name='expenseAmount'
              label='ចំនួនទឹកប្រាក់'
              rules={[{ required: true }]}
            >
              <Input placeholder='Amount'/>
            </Form.Item>
            <Form.Item
              name='expenseNote' 
              label='ចំណាំ'
            >
              <Input placeholder='Note' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Table
              size='middle'
              dataSource={expenseList}
              bordered
              pagination={{ pageSize: 3 }}
              columns={columnsExpense}
              summary={() => (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={3} align="right">ការចំណាយសរុប</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1} align="right">
                      <b><NumericFormat value={totalExpense} displayType={'text'} thousandSeparator={true} suffix={' ៛'} /></b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default ViewAndUpdateExpense;