import React, { useState } from 'react';
import { Table, Tag, Button } from 'antd';
// import NumberFormat from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import * as utils from 'utils/index';
import ConfirmationModal from './confirmationModal';
// import UpdateForm from './Update';
import './index.css';

const TableForm = ({ data, totalRevenue, pagination, handleTableChange, onLoadData }) => {

  const { t, i18n } = useTranslation();
  const [isConfirm, setIsConfirm] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const columns = [
    {
      title: "№",
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('label.date'),
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <div>{utils.formatDate(record.date)}</div>
      )
    },
    {
      title: 'តំណាងចែងចាយ',
      dataIndex: 'saleRepId',
      key: 'saleRepId',
      render: (text, { saleRepId }) => (
        <div>{ saleRepId && saleRepId.name }</div>
      ),
      responsive: ['sm'],
    },
    {
      title: t('label.paymentMethod'),
      dataIndex: 'paymentMethodName',
      key: 'paymentMethodName',
    },
    {
      title: t('label.amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'right'
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record) => (
        <Tag color={ record.status === 'Pending' && 'gold' } key={record._id}>
          {record.status }
        </Tag>
      )
    },
    {
      title: t('label.note'),
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: t('label.referenceCode'),
      dataIndex: 'referenceCode',
      key: 'referenceCode',
    },
    {
      title: '',
      fixed:"right",
      render:(text, record) => (
        <>
          <Button
            onClick={() => onConfirm(record)}
          >
            { t('action.confirm') }
          </Button>
        </>
      )
    }
  ];

  const onConfirm = (item) => {
    setCurrentItem(item);
    setIsConfirm(true);
  }

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setIsConfirm(false);
  }

  const onCloseModal = () => {
    setCurrentItem(null);
    setIsConfirm(false);
  }

  return (
    <>
      <ConfirmationModal
        value={isConfirm}
        item={currentItem}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
      <Table
        rowKey='id'
        size="middle"
        pagination={pagination}
        onChange={handleTableChange}
        dataSource={data}
        columns={columns}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3} align="right">Total Amount</Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1} align="right">
                <b><NumericFormat value={totalRevenue} displayType={'text'} thousandSeparator={true} suffix={' ៛'} /></b>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={6} align="right"></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </>
  );

}

export default TableForm;