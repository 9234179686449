export const ClosingBalanceStatus = {
  DRAFTING: 'Drafting',
  APPROVED: 'Approved'
}

const PENDING = 'Pending';
const APPROVED = 'Approved';
const REJECTED = 'Rejected'

export const expenseStatus = {
  PENDING,
  APPROVED,
  REJECTED
}

export const expenseStatusList = [
  PENDING,
  APPROVED,
  REJECTED
]

export const paymentStatusList = [
  PENDING,
  APPROVED,
  REJECTED
]