import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Tabs,
  Form,
  message,
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
} from "antd";
import { Loading } from "utils";
import { URL } from "../constant";
import { useTranslation } from "react-i18next";
import { CreateModal } from "components/modals/index";
import ProductVariantList from "./list";

const { confirm } = Modal;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const ProductVariantModal = ({
  value = false,
  item = null,
  onSuccess,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const [tab, setTab] = useState(1);
  const [parentId, setParentId] = useState(null);
  const [variants, setVariants] = useState([]);

  const onChange = (key) => {
    setTab(key);
  };

  const items = [
    {
      key: 1,
      label: t("action.create"),
      children: (
        <Form {...layout} form={form} validateMessages={validateMessages}>
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name="code" label={t("label.code")}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="name"
                label={t("label.name")}
                rules={[
                  {
                    required: true,
                    message: "Please input name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="wholesalePrice"
                label={t("label.wholesalePrice")}
                rules={[
                  {
                    required: true,
                    message: "Please input wholesale price!",
                  },
                ]}
              >
                <InputNumber placeholder="0" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="retailPrice"
                label={t("label.retailPrice")}
                rules={[
                  {
                    required: true,
                    message: "Please input retail price!",
                  },
                ]}
              >
                <InputNumber placeholder="0" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="items"
                label={t("label.items")}
                rules={[
                  {
                    required: true,
                    message: "Please input items!",
                  },
                ]}
              >
                <InputNumber placeholder="0" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      key: 2,
      label: t("action.list"),
      children: <ProductVariantList parentId={parentId} values={variants} />,
    },
  ];

  const onCreate = async () => {
    try {
      Loading.setLoading(true);
      if (tab === 2) {
        onSuccess();
        return;
      }
      const formValues = await form.validateFields();
        const data = {
          productId: item.id,
          code: formValues.code,
          name: formValues.name,
          categoryId: item.categoryId.id,
          wholesalePrice: formValues.wholesalePrice,
          retailPrice: formValues.retailPrice,
          type: item.type,
          items: formValues.items,
        };
        const response = await axios.post(`${URL}/product-variant`, data);
        if (response.data.code === 200) {
          form.resetFields();
          setVariants(response.data.result.variants);
          message.success("This data added in System.");
        } else {
          message.info("Failed");
        }
    } catch (error) {
      console.log("Failed:", error);
    } finally {
      Loading.setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (item) {
      setVariants([]);
      setParentId(item.id);
      if (item.variants.length > 0) {
        setVariants(item.variants);
      }
    }
  }, [item]);

  return (
    <>
      <CreateModal
        title={t("menu.productVariant")}
        value={value}
        width={980}
        onOk={() => onCreate()}
        onCancel={() => onCancelModal()}
      >
        <Tabs defaultActiveKey={tab} type="card" items={items} onChange={onChange} />
      </CreateModal>
    </>
  );
};

export default ProductVariantModal;
