import React, { useState } from 'react';
import { Button, Table } from 'antd';
// import NumberFormat from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import * as utils from 'utils/index';
import CreateEditExpenseModal from './createEdit';
// import UpdateForm from './Update';

const TableForm = ({ data, totalExpense, pagination, handleTableChange, onLoadData }) => {

  const [editModal, setEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
  }

  const onCloseModal = () => {
    setCurrentItem(null);
    setEditModal(false);
  }

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <div>{utils.formatDate(record.date)}</div>
      )
    },
    {
      title: 'Expense Type',
      dataIndex: 'expenseTypeName',
      key: 'expenseTypeName',
      // filters: expenseTypes.map((item) => { return { text: item.description, value: item.id } }),
      // filterSearch: true,
      // onFilter: (value, record) => record.id === value
    },
    {
      title: 'Expense Period',
      dataIndex: 'expensePeriod',
      key: 'expensePeriod'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    // {
    //   title: i18n.t('label.condition'),
    //   dataIndex: 'approval',
    //   key: 'approval',
    //   render: (expense, record) => (
    //     <div>
    //     {
    //       record.approval.map((value, index) => {
    //         return <Tag color="#275d84" key={value._id}>
    //           {record.hasOwnProperty('product') ? '' : value.user.username }
    //         </Tag>
    //       })
    //     }
    //     </div>
    //   )
    // },
    // {
    //   title: i18n.t('label.closingBalanceDate'),
    //   dataIndex: 'closingBalanceDate',
    //   key: 'closingBalanceDate'
    // },
    // {
    //   title: i18n.t('label.closingBalanceCode'),
    //   dataIndex: 'closingBalanceCode',
    //   key: 'closingBalanceCode'
    // },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Reference Code',
      dataIndex: 'referenceCode',
      key: 'referenceCode',
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      fixed:"right",
      render:(text, record) => (
        <>
          <Button
            disabled={record.closingBalanceCode !== 0}
            onClick={() => onEdit(record)}
          >
            Edit
          </Button>
          {/* Modal should not contain open button in it, in this case we cannot disable the open button */}
          {/* {record.closingBalanceCode === 0 ? <UpdateForm selectedObject={record} onLoadData={onLoadData}/> : <></>} */}
        </>
      )
    }
  ];
  return (
    <>
      <Table
        rowKey='id'
        size="middle"
        pagination={pagination}     
        onChange={handleTableChange}
        dataSource={data}
        rowClassName={
          (record) => {
            return record.closingBalanceCode === 0 ? 'closedBalance' : 'notClosedBalance';
          }
        }
        columns={columns}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3} align="right">Total Amount</Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1} align="right">
                <b><NumericFormat value={totalExpense} displayType={'text'} thousandSeparator={true} suffix={' ៛'} /></b>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={6} align="right"></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <CreateEditExpenseModal
        value={editModal}
        item={currentItem}
        isEdit
        onLoadData={() => onLoadData()}
        onCancel={() => onCloseModal()}
      />
    </>
  );
  
}

export default TableForm;