import React, { useState } from 'react';
import axios from 'axios';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, message, Select } from 'antd';
import { ExpenseType } from 'constants/expense-type.constant';
import { Loading } from 'utils';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = ({ data = [], onSuccess }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      id: '',
      name: '',
      nameEn: '',
      phoneNumber: '',
      price: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (id) => {
    try {
      Loading.setLoading(true);
      const row = await form.validateFields();
      const product = {
        name: row.name,
        nameEn: row.nameEn,
        price: row.price,
        type: row.type
      }
      const response = await axios.put(`api/expense-type/${id}`, product)
      if (response.data.code === 200) {
        onSuccess();
      }
      else {     
        message.info('This product not yet have in System.');
      }
      setEditingKey('');
      
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    } finally {
      Loading.setLoading(false);
    }
  };

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      editable: true,
    },
    {
      title: t('label.nameEn'),
      dataIndex: 'nameEn',
      editable: true,
    },
    {
      title: t('label.priceKhr'),
      dataIndex: 'price',
      editable: true,
    },
    {
      title: t('label.type'),
      dataIndex: 'type',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Form.Item
              style={{
                marginBottom: 0
              }}
              name="type"
            >
              <Select>
                {ExpenseType.map((item) => (
                  <Option value={item.value}>{item.text}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        ) : (
          <div>{record.type}</div>
        );
      }
    },
    {
      title: t('action.action'),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title='Sure want to cancel?' okText='Ok' cancelText='Cancel' onConfirm={cancel}>
              <a href>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'price' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};

export default EditableTable;