import React from 'react';

const StockAdjustmentPage = () => {
  return (
    <>
      <h1>Stock Adjustment</h1>
    </>
  )
}

export default StockAdjustmentPage;