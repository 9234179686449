export const ADMIN_ROLE = Object.freeze({
  name: "Admin",
  value: "Admin",
  code: "ROLE_ADMIN",
});

export const USER_ROLE = Object.freeze({
  name: "User",
  value: "User",
  code: "ROLE_USER",
});

export const PRINT_ROLE = Object.freeze({
  name: "Print Only",
  value: "Print Only",
  code: "ROLE_PRINT_ONLY",
});

export const Roles = Object.freeze([ADMIN_ROLE, USER_ROLE, PRINT_ROLE]);
