import React, { useEffect } from "react";
import axios from 'axios';
import { Form, Modal, Row, Col, Input, message } from 'antd';

const EditOrganizationReport = ({ visible = false, onCancel, onOk, item = undefined }) => {

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  const [form] = Form.useForm();

  const close = () => {
    form.resetFields()
    onCancel()
  }

  const submit = async () => {
    try {
      const data = {
        ...item,
        settings: form.getFieldValue('settings')
      }
      const id = item.id
      await axios.put(`api/organization-report/${id}`, data)
      message.success('Added Successful')
      onOk()
      form.resetFields()
    } catch (error) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    if (visible && item) {
      form.setFieldsValue({
        settings: item.settings,
        id: item.id
      })
    }
  })
  return (
    <>
      <Modal
        title='Edit Report'
        visible={visible}
        width={600}
        onCancel={close}
        onOk={submit}
      >
        <Form
          form={form}
          {...formItemLayout}
        >
          <Form.List name="settings">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={[16, 16]}>
                    <Col span={22}>
                      <Form.Item
                        {...restField}
                        label={(name !== null && name > 0) ? name : '0'}
                        name={[name, 'predefined']}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

export default EditOrganizationReport;