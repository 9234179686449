import React, { useState } from "react";
import { Layout, Menu } from "antd";

const { Sider } = Layout;

const MenuSider = ({ items = [] }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState("mail");

  const onClick = (e) => {
    // console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={200}
    >
      <Menu
        mode="inline"
        theme="dark"
        // style={{ backgroundColor: "#709597", height: "100%", borderRight: 0 }}
        items={items}
        onClick={onClick}
        selectedKeys={[current]}
      ></Menu>
    </Sider>
  );
};

export default MenuSider;
