import React from "react";
import { Form, Table, Button, Space, Modal, Tag } from 'antd';
import {
  FacebookOutlined,
} from '@ant-design/icons';
import moment from 'moment';

const { Column } = Table;

const ModalSaleExist = ({ sales, visible, onCancel }) => {

  return (
    <ul className="sale-list">
      <Modal
        open={visible}
        width={1200}
        title="Sale Exist"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={onCancel}
      >
        <Form
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: 'public',
          }}
        >
          <Table rowKey="id" dataSource={sales} pagination={{ pageSize: 50 }} scroll={{ y: 700 }}>
            <Column title="Invoice" dataIndex="code" key="code" />
            <Column
                title="Current Date"
                key="date"
                render={(text, record) => (
                  <div>{moment(record.date).format("YYYY-MM-DD HH:mm")}</div>
                )}/>
            <Column title="Facebook" dataIndex="facebook" key="facebook" />
            <Column
                title="Facebook"
                key="facebookLink"
                render={(text, record) => (
                  <Space size="middle">
                    <Button type="link" href={record.facebookLink} target="_blank">
                      <Tag icon={<FacebookOutlined />} color="#3b5999">
                        {record.facebook}
                      </Tag>
                    </Button>
                  </Space>
                )}
              />
            <Column title="Address" dataIndex="address" key="address" />
            <Column title="Phone Number" dataIndex="phoneNumber" key="phoneNumber" />
            <Column
              title="Product"
              dataIndex="product"
              render={(sale, record) => (
                <div>
                {
                  record.detail.map((value, index) => {
                    return <Tag color="#108ee9">
                      {record.hasOwnProperty('product') ? '' : value.product }
                    </Tag>
                  })
                }
                </div>
              )}
            />
            <Column title="Amount" dataIndex="totalAmount" key="totalAmount" />
          </Table>
        </Form>
      </Modal>
    </ul>
  );
}

export default ModalSaleExist;
