import axios from "axios";
import { useEffect, useState } from "react";
import { DELIVERY_URL } from "constants/api";

export const useDeliveries = () => {
  const [deliveries, setDeliveries] = useState([]);

  const getDeliveries = async () => {
    const response = await axios.get(DELIVERY_URL);
    if (response.data.code === 200) {
      setDeliveries(response.data.result);
    }
  };

  useEffect(() => {
    getDeliveries();
  }, []);

  return { deliveries };
};