import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";
import moment from "moment";
import { Divider, Tag, Button, Form, Input, Row, Col, Select, DatePicker, Checkbox, Card, Table, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import { FilterOutlined } from '@ant-design/icons';
import PopupFilter from 'components/common/FilterPopup';
import { Riel } from 'components/special';
import { MoveSaleStatus } from 'pages/move-sale/components';
import { ADMIN_ROLE } from "constants/role.constant";
import { Loading } from 'utils';
import { useColumns } from './hook/useColumns';
import { useSaleReps } from './hook/useSaleRep';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const { useBreakpoint } = Grid;

const SearchForm = ({ isRowSelection, onSelectedSales, isClear }) => {
  const { t, i18n } = useTranslation();
  const screens = useBreakpoint();
  const [searchForm] = Form.useForm();
  const columns = useColumns();
  const { saleReps } = useSaleReps();

  const roleValue = useSelector((state) => state.role.value);

  const [sales, setSales] = useState([]);
  const [options, setOptions] = useState({
    grandTotal: 0,
    grandTotalWholesale: 0,
    totalAmount: 0,
    totalWholesale: 0,
  });
  const [filterPopup, setFilterPopup] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [10]
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    console.log('Is clear', isClear);
    if (isClear) {
      onReset();
    }
  }, [isClear]);

  const onSearch = async () => {
    await getSales();
  };

  const onReset = () => {
    try {
      Loading.setLoading(true);
      searchForm.resetFields();
      setSales([]);
      setOptions({
        grandTotal: 0,
        grandTotalWholesale: 0,
        totalAmount: 0,
        totalWholesale: 0,
      });
      setFilterPopup(false);
      setPagination({
        current: 1,
        pageSize: 10,
        pageSizeOptions: [10]
      });
      setSelectedRowKeys([]);
    } catch (error) {
      console.log('On Reset', error);
      // message.error(error);
    } finally {
      Loading.setLoading(false);
    }
  }

  const getSearchParam = () => {
    const data = searchForm.getFieldsValue(['saleRepId', 'phoneNumber', 'code', 'startDate', 'endDate', 'isPrint', 'fromCode', 'toCode']);
    return {
      saleRepId: data.saleRepId ?? '',
      phoneNumber: data.phoneNumber ?? '',
      code: data.code ?? '',
      startDate: data.startDate ?? '',
      endDate: data.endDate ?? '',
      fromCode: data.fromCode ?? '',
      toCode: data.toCode ?? '',
      isPrint: data.isPrint,
      page: 0,
    }
  }

  const getSales = async (paginationParm) => {
    try {
      Loading.setLoading(true);
      const searchParam = {
        ...getSearchParam(),
        page: (paginationParm && paginationParm.current) || 1
      };
      const response = await axios.post('api/sales/search', searchParam);
      if (response && response.data) {
        setSales(response.data && response.data.result);
        setOptions({
          grandTotal: response.data.options.grandTotal,
          grandTotalWholesale: response.data.options.grandTotalWholesale,
          totalAmount: response.data.options.totalAmount,
          totalWholesale: response.data.options.totalWholesale,
        });
        setPagination({
          ...paginationParm,
          total: response.data.options.total
        });
      }
    } catch (error) {
      // message.error(error.message)
    } finally {
      Loading.setLoading(false);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    onSelectedSales(newSelectedRowKeys);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };

  const onTableChange = async (pagination) => {
    await getSales(pagination);
  };

  return (
    <>
      <Card size="small" bordered={false}>
        <Form
          {...formItemLayout}
          style={{ marginBottom: 0 }}
          form={searchForm}
        >
          <Row gutter={[8, 8]}>
            <Col xs={12} sm={3} md={3}>
              <Form.Item
                name="saleRepId"
                style={{ marginBottom: 0 }}
              >
                <Select
                  allowClear
                  placeholder="តំណាងចែងចាយ"
                  options={
                    saleReps.map((saleRep) => ({
                      label: saleRep.name,
                      value: saleRep.id,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={3} md={3}>
              <Form.Item
                name="code"
                style={{ marginBottom: 0 }}
              >
                <Input placeholder={t('invoiceSequence')} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 0 }} label="">
                <PopupFilter
                  visible={filterPopup}
                  placement="bottom"
                  resetText="ឡើងវិញ"
                  searchText={t('action.search')}
                  onClose={() => setFilterPopup(false)}
                  onReset={() => onResetFilter()}
                  onFilter={() => onFilter()}
                  button={
                    <Button
                      icon={<FilterOutlined />}
                      onClick={() => setFilterPopup(true)}
                    />
                  }
                  form={
                    <Form
                      style={{width: '250px'}}
                      form={searchForm}
                    >
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="phoneNumber"
                            style={{ marginBottom: 0 }}
                          >
                            <Input placeholder={t('phoneNumber')} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="startDate"
                            style={{ marginBottom: 0 }}
                          >
                            <DatePicker placeholder={t('label.fromDate')} style={{ display: 'block' }} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="endDate"
                            style={{ marginBottom: 0 }}
                          >
                            <DatePicker placeholder={t('label.toDate')} style={{ display: 'block' }} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="fromCode"
                            style={{ marginBottom: 0 }}
                          >
                            <Input placeholder='From Code' />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="toCode"
                            style={{ marginBottom: 0 }}
                          >
                            <Input placeholder='To Code' />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item style={{ marginBottom: 0 }} valuePropName="checked" name="isPrint">
                            <Checkbox>{t('label.isNotPrinted')}</Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  }
                >
                </PopupFilter>
                <Button
                  style={{ marginLeft: '4px' }}
                  type="primary"
                  onClick={() => onSearch()}
                >
                  { t('action.search') }
                </Button>
                <Button
                  style={{ marginLeft: '4px' }}
                  onClick={() => onReset()}
                >
                  ឡើងវិញ
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Divider />

        <Table
          rowKey="id"
          size="small"
          columns={columns.filter((x) => x !== null)}
          dataSource={sales}
          pagination={pagination}
          onChange={onTableChange}
          rowSelection={isRowSelection ? rowSelection : null}
          summary={() => {
            const currentScreens = Object.entries(screens).filter(
              (screen) => !!screen[1]
            ).length;
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    colSpan={
                      ADMIN_ROLE.code === roleValue
                        ? currentScreens > 1
                          ? 8
                          : 2
                        : currentScreens > 1
                        ? 7
                        : 2
                    }
                    align="right"
                  >
                    សរុប
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <b>
                      <Riel value={options.totalWholesale} />
                    </b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <b>
                      <Riel value={options.totalAmount} />
                    </b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    colSpan={
                      ADMIN_ROLE.code === roleValue
                        ? currentScreens > 1
                          ? 8
                          : 2
                        : currentScreens > 1
                        ? 7
                        : 2
                    }
                    align="right"
                  >
                    តំលៃ​បូក​សរុប
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <b>
                      <Riel value={options.grandTotal} />
                    </b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <b>
                      <Riel value={options.grandTotalWholesale} />
                    </b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Card>

    </>
  );
};

export default SearchForm;
