import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Input, Button, Row, Col, Card, message, Table, Space } from 'antd';
import axios from 'axios'
import AppTable from 'components/common/table/AppTable'

const { Column } = Table;

const ModalOrganization = ({ visible = false, company, onCancel }) => {
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 12,
      },
    },
  };
  const [form] = Form.useForm();
  const defaultPagination = {
    current: 1,
    pageSize: 10,
  }
  const tableHeaders = [
    { title: 'Code', dataIndex: 'code' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Address', dataIndex: 'address' }
  ]
  const [enableAddForm, setEnableAddForm] = useState(false)
  const [enableEditForm, setEnableEditForm] = useState(false)
  const [pagination, setPagination] = useState(defaultPagination)
  const [organizations, setOrganization] = useState([])

  const tableChange = (value) => {
    const pagination = {
      current: value.current,
      pageSize: value.pageSize
    }
    setPagination(pagination)
  }

  const create = async (data) => {
    try {
      const response = await axios.post('api/organization', data)
      if (response.data.code === 200) {
        message.success('Added Successful')
        await getOrganization()
        handleCancel()
      } else {
        message.error(response.data.message.message)
      }
    } catch (error) {
      message.error('Error')
    }
  }

  const update = async (data) => {
    try {
      const response = await axios.put(`api/organization/${data.id}`, data)
      if (response.data.code === 200) {
        message.success('Update Successful')
        await getOrganization()
        handleCancel()
      } else {
        message.error(response.data.message.message)
      }
    } catch (error) {
      message.error('Error')
    }
  }

  const handleSubmitOrganization = async () => {
    const data = {
      id: form.getFieldValue('id'),
      code: form.getFieldValue('code'),
      name: form.getFieldValue('name'),
      address: form.getFieldValue('address'),
      company: company.id
    }
    if (data.id) {
      update(data)
    } else {
      create(data)
    }
  }

  const onEditOrganization = (item) => {
    form.setFieldsValue({
      id: item.id,
      code: item.code,
      name: item.name,
      address: item.address
    })
    setEnableEditForm(true)
  }

  const handleCancel = () => {
    form.resetFields()
    setEnableAddForm(false)
    setEnableEditForm(false)
  }

  const getOrganization = useCallback(async () => {
    if (company.id) {
      const response = (await axios.get(`api/organization/by-company/${company.id}`)).data
      if (response.result) {
        // avoid console props key error
        const result = response.result.map((item, index) => {
          item.key = index
          return item
        })
        setOrganization(result)
      }
    }
  }, [company])

  useEffect(() => {
    if (visible && company) {
      getOrganization()
      setEnableAddForm(false)
      setEnableEditForm(false)
    }
  }, [visible, company, getOrganization])
  return (
    <>
      <Modal
        title='Organization'
        visible={visible}
        width={1200}
        onCancel={onCancel}
        onOk={onCancel}
        okButtonProps={{disabled: true}}
        cancelButtonProps={{disabled: true}}
      >
        <Row>
          {(enableAddForm === true || enableEditForm === true) && 
            <Col span={24}>
              <Form
                form={form}
                {...formItemLayout}
              >
                <Form.Item
                  label='Code'
                  name="code"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='Name'
                  name="name"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='Address'
                  name="address"
                >
                  <Input />
                </Form.Item>
              </Form>
            </Col>
          }
          <Col span={24}>
            <Card
              extra={
                <>
                  {(enableAddForm === true || enableEditForm === true) ? (
                    <>
                      <Space size="small">
                        <Button onClick={() => handleCancel()}>Cancel</Button>
                        <Button type="primary" onClick={() => handleSubmitOrganization()}>Save</Button>
                      </Space>
                    </>
                  ) : (
                    <Button onClick={() => setEnableAddForm(true)}>Create</Button>
                  )}
                </>
              }
            >
              <AppTable
                bordered="true"
                scroll={{ x: 1000, y: 400 }}
                headers={tableHeaders}
                items={organizations}
                pagination={pagination}
                onChange={tableChange}
              >
                {{
                  actions: 
                  <Column
                    title='Action'
                    fixed="right"
                    render={(item, record) => (
                      <>
                        <Space size="small">
                          <Button onClick={() => onEditOrganization(item)}>Edit</Button>
                        </Space>
                      </>
                    )}
                  ></Column>
                }}
              </AppTable>
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default ModalOrganization;