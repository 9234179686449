import React from "react";
import { Grid } from "antd";
import DesktopLayout from "./desktop";
import MobileLayout from "./mobile";

import { useSelector } from "react-redux";
import Loading from "components/loading/index";

const { useBreakpoint } = Grid;

const DefaultLayout = () => {
  const screens = useBreakpoint();
  const loading = useSelector((state) => state.loading);

  return (
    <>
      {loading ? <Loading /> : <></>}
      {screens.xs ? <MobileLayout /> : <DesktopLayout />}
    </>
  );
};

export default DefaultLayout;
