import React, { useState, useEffect } from 'react';
import { Card, Button, message, Table, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import AppTable from 'components/common/table/AppTable';
import axios from 'axios';
import CreateEditPaymentMethod from './createEdit';
import { Loading } from 'utils';
import { useTranslation } from 'react-i18next';

const PaymentMethodPage = () => {
  
  const { t } = useTranslation();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [createEditModal, setCreateEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const tableHeaders = [
    { title: t('label.description'), dataIndex: 'name', key: 'name' },
    { title: t('label.descriptionEn'), dataIndex: 'nameEn', key: 'nameEn' },
    {
      title: t('action.action'),
      dataIndex: 'action',
      key: 'action',
      fixed: "right",
      render: (item, record) => (
        <>
          <Space size="small">
            <Button
              onClick={() => editPaymentMethod(record)}
            >
              Edit
            </Button>
          </Space>
        </>
      )
    }
  ]

  const getPaymentMethods = async () => {
    try {
      const response = await axios.get('api/payment-method')
      if (response.data && response.data.result) {
        setPaymentMethods(response.data.result);
      } else {
        message.error('Failed to get data');
      }
    } catch (error) {
      message.error(error.message);
    }
  }
  
  const createPaymentMethod = () => {
    setCreateEditModal(true);
  }

  const editPaymentMethod = (item) => {
    setCurrentItem(item);
    setCreateEditModal(true);
  }

  const handleSuccess = async () => {
    setCreateEditModal(false);
    setCurrentItem(null);
    await getPaymentMethods();
  }

  const handleClose = () => {
    setCreateEditModal(false);
    setCurrentItem(null);
  }

  useEffect(() => {
    async function fetch () {
      Loading.setLoading(true);
      await getPaymentMethods();
      Loading.setLoading(false);
    }

    fetch();
  }, [])
  return (
    <>
      <PageHeader
        className='site-page-header'
        title={t('menu.expenseType')}
        extra={
          <Button
            onClick={() => createPaymentMethod()}
          >
            {t('action.create')}
          </Button>
        }
      />
      <Card size='small'>
        <AppTable
          items={paymentMethods}
          headers={tableHeaders}
          disableAction
        />

        <CreateEditPaymentMethod
          visible={createEditModal}
          currentItem={currentItem}
          onSuccess={() => handleSuccess()}
          onCancel={() => handleClose()}
        />
      </Card>
    </>
  )
}

export default PaymentMethodPage;