import React from 'react';
import { Modal, Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const ModalSaleSaved = ({sales, visible, onCancel}) => {

  return (
    <ul className="sale-list">
      <Modal
        open={visible}
        width={400}
        title="Sale Invoice Number"
        okText="Ok"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={onCancel}
      >
        <Result
          icon={<SmileOutlined />}
          title={sales.code}
          subTitle="Sale Invoice Number"
        />,
      </Modal>
    </ul>
  );
}

export default ModalSaleSaved;
