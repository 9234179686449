import React from "react";
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const IndexPage = ({ status }) => {

  const { t, i18n } = useTranslation();

  const renderSwitch = (param) => {
    switch(param) {
      case 'Paid':
        return <Tag color="#527F5E">{t('label.paid')}</Tag>;
      case 'Partly Paid':
        return <Tag
          color="#FBD254"
          style={{
            color: "#000000",
          }}>
            {t('label.partlyPaid')}
          </Tag>;
      case 'Unpaid':
        return <Tag color="#DD3437">{t('label.unpaid')}</Tag>;
      default:
        return <></>;
    }
  }

  return (
    <>
      { renderSwitch(status) }
    </>
  )
}

export default IndexPage;