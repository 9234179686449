import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { message, Form, Card, Grid, Row, Statistic, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import CountUp from 'react-countup';
import CustomModal from 'components/modals/DisplayModal';
import { Loading } from 'utils';

const { Meta } = Card;

const formatter = (value) => <CountUp end={value} separator="," />;

const confirmPostClosingBalance = ({
  item = null,
  open = false,
  onCancel,
  onSuccess
}) => {

  const { t, i18n } = useTranslation();
  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);

  const [info, setInfo] = useState({
    saleRepName: '',
    total: 0,
    totalAmount: 0,
    totalWholesaleAmount: 0,
  });

  useEffect(() => {
    if (item) {
      setInfo(item.data)
    }
  }, [item]);

  const canEdit = useMemo(() => {
    return roleAdmin == roleValue ? true : false
  }, []);

  const onConfirmMoveLine = async () => {
    try {
      Loading.setLoading(true);
      const response = await axios.post(`api/sales/move-sale`, item.searchData);
      if (response.data.code === 200) {
        message.success(`${response.data.message.message}`);
        onSuccess();
        onCancel();
      }
      else {
        message.info('Failed to update');
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  return (
    <>
      <CustomModal
        width={600}
        visible={open}
        title={`Confirm Post-Closing Balance`}
        saveButtonText='Confirm'
        onCancel={() => onCancel()}
        onOk={() => onConfirmMoveLine()}
        disableOk={!canEdit}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Statistic title="Sale Rep" value={info.saleRepName === '' ? t('actions.all') : info.saleRepName} />
          </Col>
          <Col span={24}>
            <Statistic title="Total Sale" value={info.total} precision={2} formatter={formatter} />
          </Col>
          <Col span={12}>
            <Statistic title="Total Wholesale Amount" value={info.grandTotalWholesale} precision={2} formatter={formatter} />
          </Col>
          <Col span={12}>
            <Statistic title="Total Sale Amount" value={info.grandTotal} formatter={formatter} />
          </Col>
        </Row>
      </CustomModal>
    </>
  )
}

export default confirmPostClosingBalance;