import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { message, Space, Table, Tooltip, Button } from 'antd';
import axios from 'axios';
import Confirm from 'components/modals/Confirm';
import { UserAddOutlined } from '@ant-design/icons';
import { CreateUserModal } from 'components/special';
import { Loading } from 'utils';
import { DeleteButton, EditButton } from 'components/common/button/index';
import CreateEditModal from './createEditModal';
import { useTranslation } from 'react-i18next';
import { joinString } from 'utils';
import { CUSTOMER_URL } from 'constants/api';

const TableForm = ({
  pagination,
  data,
  onLoadData,
  onTableChange
}) => {
  const { t, i18n } = useTranslation();
  const roleValue = useSelector((state) => state.role.value);
  const roleAdmin = useSelector((state) => state.role.admin);
  const [editModal, setEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isCreateUser, setIsCreateUser] = useState(false);

  const headers = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      render: (_, record) => (
        <>{joinString([record.code, record.name])}</>
      )
    },
    {
      title: t('label.phoneNumber'),
      dataIndex: 'phoneNumber',
    },
    {
      title: t('label.otherNumber'),
      dataIndex: 'otherNumber',
    },
    {
      title: t('label.address'),
      dataIndex: 'address',
    },
    {
      title: 'Facebook Link',
      dataIndex: 'facebookLink',
    },
    {
      title: t('label.delivery'),
      render:(text, record) => (
        <div>
          { record.deliveryId != null ? record.deliveryId.name : '' }
        </div>
      )
    },
    roleAdmin == roleValue ?
      {
        title: 'Shared',
        dataIndex: 'isShared',
        render:(text, record) => (
          <>
            { record.isShared ? 'Yes' : 'No' }
          </>
        )
      } : null,
    roleAdmin == roleValue ?
      {
        title: t('label.createdBy'),
        dataIndex: 'createdBy',
      } : null,
    {
      title: '',
      key: 'action',
      render:(record) => (
        <Space>
          {
            roleAdmin == roleValue ?
            <>
              <Tooltip placement="topLeft" color='geekblue' title={ t('action.createUser') }>
                <Button
                  icon={<UserAddOutlined />}
                  onClick={() => onOpenCreateUser(record)}
                  disabled={ record.userId ? true : false}
                />
              </Tooltip>
              <EditButton onClick={() => onEdit(record)} />
              <DeleteButton onClick={() => onDelete(record)} />
            </>
            :
            <>
              <EditButton onClick={() => onEdit(record)} disabled={record.isShared} />
            </>
          }
        </ Space>
      )
    }
  ]

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
  }

  const onDelete = (item) => {
    Confirm({
      title: "បញ្ជាក់ការលុប",
      okType: 'warning',
      async onOk () {
        try {
          Loading.setLoading(true);
          findOneAndDelete(item.id);
        } catch (error) {
          message.error(error.message);
        } finally {
          Loading.setLoading(false);
        }
      },
      onCancel () {}
    })
  }

  const findOneAndDelete = async (id) => {
    const response = await axios.delete(`${CUSTOMER_URL}/${id}`);
    if (response.data.code === 200) {
      onSuccess();
      message.success('This data added in System.');
    }
    else {
      message.info('Failed');
    }
  }

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setEditModal(false);
  }

  const onCloseModal = () => {
    setCurrentItem(null);
    setEditModal(false);
  }

  const onOpenCreateUser = (item) => {
    setCurrentItem(item);
    setIsCreateUser(true);
  }

  const onCreateUser = async (data) => {
    Loading.setLoading(true);
    const obj = {
      ...data,
      customerId: data.id
    }
    const response = await axios.post(`${CUSTOMER_URL}/user`, obj);
    if (response.data.code === 200) {
      onLoadData();
      setCurrentItem(null);
      setIsCreateUser(false);
      Loading.setLoading(false);
      message.success('This data added in System.');
    }
    else {
      Loading.setLoading(false);
      message.info('Failed');
    }
  }

  return (
    <>
      <Table
        rowKey="id"
        size="small"
        dataSource={data}
        columns={headers.filter(x => x !== null)}
        pagination={pagination}
        onChange={onTableChange}
      />
      <CreateUserModal
        value={isCreateUser}
        item={currentItem}
        isEdit
        onCreate={onCreateUser}
        onCancel={() => onCloseModal()}
      />
      <CreateEditModal
        value={editModal}
        item={currentItem}
        isEdit
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  )
}

export default TableForm;