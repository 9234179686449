import React, { useEffect, useState } from "react";
import { Button, Table, Space, message, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { PaymentStatus, Riel } from 'components/special';
import { ClosingBalanceStatus } from 'constants/status';
import { Loading, formatDate, tablePagination } from 'utils';
import ConfirmationModal from './modal/Confirmation';
import ViewHistoryModal from './modal/ViewHistory';
import MoveStatus from './components/MoveStatus';

const IndexPage = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [pagination, setPagination] = useState(tablePagination);
  const [options, setOptions] = useState({
    total: 0,
    grandTotal: 0,
    grandTotalWholesale: 0,
    totalAmount: 0,
    totalWholesaleAmount: 0,
  });
  const [data, setData] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isViewPayment, setIsViewPayment] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const headers = [
    {
      title: "№",
      dataIndex: 'code'
    },
    {
      title: t('label.datePosted'),
      dataIndex: 'datePosted',
      responsive: ['xs', 'sm'],
      render: (text, record) => (
        <div>{formatDate(record.datePosted)}</div>
      )
    },
    {
      title: t('label.description'),
      dataIndex: 'description',
    },
    {
      title: t('label.status'),
      render: (text, record) => (
        <>
          <MoveStatus status={record.status} />
        </>
      )
    },
    {
      title: t('label.paymentStatus'),
      render: (text, record) => (
        <>
          <PaymentStatus status={record.paymentStatus} />
        </>
      )
    },
    {
      title: t('label.totalSales'),
      dataIndex: 'totalSales',
      align: 'right',
    },
    {
      title: t('label.totalRetailAmount'),
      dataIndex: 'totalSaleAmount',
      align: 'right',
      render: (text, record) => (
        <>
          <Riel value={record.totalSaleAmount} />
        </>
      )
    },
    {
      title: t('label.totalWholesaleAmount'),
      dataIndex: 'totalWholesaleAmount',
      align: 'right',
      render: (text, record) => (
        <>
          <Riel value={record.totalWholesaleAmount} />
        </>
      )
    },
    {
      title: "សរុប",
      dataIndex: 'totalWholesaleAmount',
      align: 'right',
      render: (text, record) => (
        <>
          <Riel value={record.grandTotal} />
        </>
      )
    },
    {
      title: t('label.action'),
      align: 'right',
      render: (text, record) => (
        <>
          <Space size="small">
            <Button
              onClick={() => onConfirm(record)}
            >
              { t('action.receiveMoney') }
            </Button>
            <Button
              type={ record.status === ClosingBalanceStatus.DRAFTING && 'primary'}
              onClick={() => onViewMoveSaleDetail(record)}
            >
              { record.status === ClosingBalanceStatus.APPROVED ? t('label.view') : t('label.drafting')}
            </Button>
            <Button
              onClick={() => onViewHistory(record)}
            >
              { t('label.viewHistory') }
            </Button>
          </Space>
        </>
      )
    }
  ];

  const onLoadData = async (paginationParm) => {
    try {
      Loading.setLoading(true);
      const searchData = {
        saleRepId: '',
        phoneNumber: '',
        code: '',
        startDate: '',
        endDate: '',
        page: paginationParm && paginationParm.current,
        pageSize: paginationParm && paginationParm.pageSize
      };
      const response = await axios.post('api/sales/move-sale/find', searchData);
      if (response) {
        setData(response.data.result);
        setOptions({
          total: response.data.options.total,
          grandTotal: response.data.options.grandTotal,
          grandTotalWholesale: response.data.options.grandTotalWholesale,
          totalAmount: response.data.options.totalAmount,
          totalWholesaleAmount: response.data.options.totalWholesaleAmount,
        });
        setPagination({
          ...paginationParm,
          total: response.data.options.total
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }

  const onTableChange = async (pagination) => {
    Loading.setLoading(true);
    await onLoadData(pagination);
    Loading.setLoading(false);
  }

  useEffect(() => {
    onLoadData()
  }, []);

  const onViewMoveSaleDetail = (item) => {
    navigate({
      pathname: `/move-sale/detail/${item.id}`
    });
  }

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setIsConfirm(false);
  }

  const onCloseModal = () => {
    setCurrentItem(null);
    setIsConfirm(false);
    setIsViewPayment(false);
  }

  const onConfirm = (item) => {
    setCurrentItem(item);
    setIsConfirm(true);
  }

  const onViewHistory = (item) => {
    setCurrentItem(item);
    setIsViewPayment(true);
  }

  return (
    <>
      <ConfirmationModal
        value={isConfirm}
        item={currentItem}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
      <ViewHistoryModal
        value={isViewPayment}
        item={currentItem}
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
      <Table
        rowKey="id"
        size="small"
        dataSource={data}
        columns={headers}
        pagination={pagination}
        onChange={onTableChange}
        summary={() => {
         return (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell
                colSpan={6}
                align="right"
              >
                សរុប
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <b><Riel value={options.totalAmount} /></b>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <b><Riel value={options.totalWholesaleAmount} /></b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell
                colSpan={6}
                align="right"
              >
                តំលៃ​បូក​សរុប
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <b><Riel value={options.grandTotal} /></b>
               </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <b><Riel value={options.grandTotalWholesale} /></b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
          )
        }}
      />
    </>
  )
}

export default IndexPage;