export const defaultSearchParam = Object.freeze({
  date: null,
  paymentMethodId: null,
  status: null
});

export const STATUSES = [
  { key: 0, value: 'Review', },
  { key: 1, value: 'Pending', },
  { key: 2, value: 'Approved', },
  { key: 4, value: 'Rejected', }
];

export const REVENUE_API = 'api/revenue';

export const revenueTablePagination = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [10]
};
