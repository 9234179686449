import { Form, Input, message, Select } from 'antd';
import { assetTypes } from 'constants/asset-type.constant';
import { AssetApi } from './asset.constant';
import axios from "axios";
import { useEffect } from "react";
import { layoutConstant } from 'constants/config/form-layout.constant';
import { Loading } from "utils";
import { CreateModal } from "components/modals/index";

const { Option } = Select;

const CreateEdit = ({
  visible = false,
  item = null,
  onCancel,
  onSuccess
}) => {

  // Data
  const [form] = Form.useForm();

  // Method
  const onSubmit = async () => {
    try {
      Loading.setLoading(true);
      if (item && item.id) {
        await put(item.id);
      } else {
        await post();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  }
  const post = async () => {
    const formValues = await form.validateFields();
    const response = await axios.post(AssetApi, formValues);
    if (response.data.code === 200) {
      message.success(response.data.message.message);
      clearForm();
      onSuccess();
    } else {
      message.success(response.data.message.message)
    }
  }
  const put = async (id) => {
    const formValues = await form.validateFields();
    const response = await axios.put(`${AssetApi}/${id}`, formValues);
    if (response.data.code === 200) {
      message.success(response.data.message.message);
      clearForm();
      onSuccess();
    } else {
      message.success(response.data.message.message)
    }
  }
  const cancel = () => {
    clearForm();
    onCancel();
  }
  const clearForm = () => {
    form.resetFields();
  }

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        description: item.description,
        assetType: item.assetType,
        amount: item.amount
      })
    }
  }, [item]);


  return (
    <CreateModal
      value={visible}
      title="Asset"
      onOk={() => onSubmit()}
      onCancel={() => cancel()}
    >
      <Form
        form={form}
        {...layoutConstant}
      >
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Please input description!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="assetType"
          label="Asset Type"
          rules={[
            {
              required: true,
              message: 'Please input asset type!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Asset Type"
          >
            {assetTypes.map(option=> (
              <Option key={option.value}>{option.text}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="amount"
          label="Amount"
          rules={[
            {
              required: true,
              message: 'Please input amount!',
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
      </Form>
    </CreateModal>
  )
}

export default CreateEdit;