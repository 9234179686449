
import moment from 'moment';
import axios from 'axios';
import { saveAs }  from 'file-saver';
import { utils, write } from 'xlsx';

const exportToExcel = (exportData, fileName) => {
	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	
	const ws = utils.json_to_sheet(exportData);
	const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
	const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
	const data = new Blob([excelBuffer], { type: fileType });
	saveAs(data, fileName + fileExtension);
}

const exportSaleToExcel = async () => {
	const exportData = []
	const response = await axios.get(`api/sales/export`)
	if (response.data.code === 200) {   
		const sales = response.data.result;
		sales.forEach((element) => {
			exportData.push({
				'ល.រ': element.code,
				'កាលបរិច្ឆេទ': moment(element.date).format("YY-MM-DD"),
				'ឈ្មោះ ហ្វេសប៊ូក': element.facebookName,
				'ហ្វេសប៊ូក' : element.facebookPageLink,
				'លេខទូរស័ព្ទ': element.phoneNumber,
				'អ្នកដឹកជញ្ជូន': element.deliveryName,
				'អាស័យដ្ឋាន': element.address,
				'ឈ្មោះទំនិញ': element.detail.length > 0 ? element.detail[0].productName : '',
				'ចំនួន': element.detail.length > 0 ? element.detail[0].qty : '',
				'តម្លៃរាយ': element.detail.length > 0 ? element.detail[0].price : '',
				'តម្លៃផលិតផល': element.detail.length > 0 ? element.detail[0].qty * element.detail[0].price : '',
				'តម្លៃដឹក': element.deliveryPrice,
				'តម្លៃសរុប': element.saleAmount,
				'ប្រាក់(រួច)': element.totalRevenue,
				// 'ចំណាយ': element.totalExpense,
				'លុយនៅសល់': element.income
			})
		});
		exportToExcel(exportData, `sale-${moment().format("YY-MM-DD-hh-mm-ss")}`)
	}
}

export {
	exportSaleToExcel
}