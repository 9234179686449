import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  message,
  Button,
  Table,
  Checkbox,
  Space,
  Modal,
} from "antd";
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Loading } from "utils";
import { URL } from "../constant";
import { useTranslation } from "react-i18next";
import { EditButton } from "components/common/button/index";
import UpdateModal from "./modal/update";

const { confirm } = Modal;

const ProductVariantList = ({ parentId = null, values = [] }) => {
  const { t, i18n } = useTranslation();

  const [currentItem, setCurrentItem] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [variants, setVariants] = useState([]);

  const showPromiseConfirm = (value) => {
    confirm({
      title: "Are you sure delete this variants?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          return await new Promise((resolve, reject) => {
            axios
              .delete(`${URL}/product-variant/${value.productId}`)
              .then((response) => {
                if (response.data.code === 200) {
                  setVariants(response.data.result.variants);
                  message.success("This data added in System.");
                  resolve(response.data);
                } else {
                  message.info("Failed");
                  reject(response.data.message);
                }
              });
          });
        } catch {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };

  const productTableColumns = [
    {
      title: t("label.name"),
      dataIndex: "name",
    },
    {
      title: t("label.items"),
      align: "center",
      dataIndex: "items",
    },
    {
      title: t("label.wholesalePrice"),
      align: "right",
      dataIndex: "wholesalePrice",
    },
    {
      title: t("label.retailPrice"),
      align: "right",
      dataIndex: "retailPrice",
    },
    {
      title: t("label.hideInSell"),
      align: "center",
      dataIndex: "status",
      render: (text, record) => (
        <>
          <Checkbox checked={record.isHide}></Checkbox>
        </>
      ),
    },
    {
      dataIndex: "action",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Space size="middle">
          <EditButton onClick={() => onEdit(record)} />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => showPromiseConfirm(record)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (values.length > 0) {
      setVariants(values);
    }
  }, [values]);

  const onEdit = (item) => {
    setCurrentItem(item);
    setEditModal(true);
  };

  const onLoadData = async () => {
    try {
      Loading.setLoading(true);
      if (parentId) {
        const response = await axios.get(URL + `/product-variant/${parentId}`);
        if (response && response.data) {
          setVariants(response.data.result);
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      Loading.setLoading(false);
    }
  };

  const onSuccess = () => {
    onLoadData();
    setCurrentItem(null);
    setEditModal(false);
  };

  const onCloseModal = () => {
    setCurrentItem(null);
    setEditModal(false);
  };

  return (
    <>
      <Table
        rowKey="productId"
        size="small"
        dataSource={variants}
        pagination={false}
        bordered
        columns={productTableColumns}
        scroll={{ y: 350 }}
      />
      <UpdateModal
        value={editModal}
        item={currentItem}
        isEdit
        onSuccess={() => onSuccess()}
        onCancel={() => onCloseModal()}
      />
    </>
  );
};

export default ProductVariantList;
