import React from "react";
import { Table, Space, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { joinString, formatDate } from 'utils';
import { useTranslation } from 'react-i18next';

const StockList = ({
  data,
  pagination,
  onTableChange
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const headers = [
    {
      title: t('label.product'),
      dataIndex: 'productId',
      render: (item, record) => (
        <>{joinString([item.code, item.name])}</>
      )
    },
    {
      title: t('label.last_update'),
      render: (text, record) => (
        <div>{formatDate(record.updatedAt)}</div>
      )
    },
    {
      title: t('label.qty'),
      dataIndex: 'qty'
    },
    {
      title: 'Action',
      align: 'right',
      render: (item, record) => (
        <>
          <Space size="small">
            <Button onClick={() => onViewStockDetail(item)}>
              View
            </Button>
          </Space>
        </>
      )
    }
  ];

  const onViewStockDetail = (item) => {
    navigate({
      pathname: `/inventory/stock/detail/${item.productId.id}`
    });
  }

  return (
    <Table
      rowKey="id"
      size="small"
      dataSource={data}
      columns={headers}
      pagination={pagination}
      onChange={onTableChange}
    />
  )
}

export default StockList;